import React from 'react';
import { IStateProps } from '../components/App';

interface SceneProps extends IStateProps {
  isHtml: boolean;
}

export const createScene = <T extends IStateProps>(
  HtmlComponent: React.ComponentType<T> | null,
  ThreeDComponent: React.ComponentType<T> | null,
) => {
  const EmptyComponent = () => <></>;
  const SafeHtmlComponent = HtmlComponent || EmptyComponent;
  const SafeThreeDComponent = ThreeDComponent || EmptyComponent;

  return React.memo(({ isHtml, ...props }: SceneProps) =>
    isHtml ? (
      <SafeHtmlComponent {...(props as T)} />
    ) : (
      <SafeThreeDComponent {...(props as T)} />
    ),
  );
};
