import React from 'react';
import { FONTS } from '../../../../enums/fonts';

export const JackpotContainer: React.CSSProperties = {
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontWeight: 'semibold',
  position: 'absolute',
  alignItems: 'end',
  display: 'flex',
  left: '128px',
  top: '159px',
};

export const JackpotSubContainer: React.CSSProperties = {
  position: 'absolute',
  left: '390px',
  top: '250px',
  display: 'flex',
  flexDirection: 'column',
};

export const gameLogo: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '400px',
  padding: '25px 40px',
};
