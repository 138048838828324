import { T3D } from '..';

const GRID_WIDTH = 10;
const GRID_HEIGHT = 8;
const BALL_DIAMETER = 0.9;
const GRID_OFFSET_X = ((GRID_WIDTH - 1) * BALL_DIAMETER) / 2 - 0.06;
const GRID_OFFSET_Y = ((GRID_HEIGHT - 1) * BALL_DIAMETER) / 2;

export function ballNumberToGridPosition(ballNumber: number): T3D {
  const row = Math.floor((ballNumber - 1) / GRID_WIDTH);
  const col = (ballNumber - 1) % GRID_WIDTH;

  return [
    col * BALL_DIAMETER - GRID_OFFSET_X,
    -row * BALL_DIAMETER + GRID_OFFSET_Y,
    -0.5,
  ];
}
