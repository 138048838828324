import React from 'react';
import { T3D } from '.';
import { NumberText } from './NumberText';
import { getSharedMaterials } from './getSharedMaterials';
import * as THREE from 'three';
export interface BallProps {
  number: number;
  position?: T3D;
  scale?: number;
  transition?: boolean;
  staticPos?: boolean;
}

export const BallWithNumber: React.FC<BallProps> = ({
  number,
  position = [0, 0, 0],
  scale = 1,
  transition = false,
  staticPos = false,
}) => {
  const { yellowWhiteMaterial } = getSharedMaterials();

  return (
    <group position={position} scale={scale} matrixAutoUpdate={!staticPos}>
      <mesh matrixAutoUpdate={!staticPos} material={yellowWhiteMaterial}>
        <planeGeometry args={[0.8, 0.8]} />
      </mesh>
      <NumberText number={number} transition={transition} />
    </group>
  );
};

export const BallFullYellow: React.FC<{
  front?: boolean;
  meshRef: React.RefObject<THREE.Mesh>;
}> = ({ front = true, meshRef }) => {
  const { fullYellowMaterial } = getSharedMaterials();

  return (
    <mesh
      ref={meshRef}
      renderOrder={front ? 2 : 0}
      material={fullYellowMaterial}
    >
      <planeGeometry args={[0.8, 0.8]} />
    </mesh>
  );
};
