export enum FONTS {
  SansSerif = 'sans-serif',
  AristaProRegular = 'AristaProRegular',
  AristaProBold = 'AristaProBold',
  GothamRoundedLight = 'GothamRoundedLight',
  GothamRoundedMedium = 'GothamRoundedMedium',
  GothamRoundedBold = 'GothamRoundedBold',
  HelveticaRoundedBoldCondensed = 'HelveticaRoundedBoldCondensed',
  HelveticaBoldCondensed = '"HelveticaNeue-CondensedBold", "Helvetica Neue"',
  BarlowCondensed = 'Barlow Condensed',
  BarlowCondensedBold = 'Barlow Condensed',
  BarlowCondensedItalic = 'Barlow Condensed',
  BarlowCondensedBoldItalic = 'Barlow Condensed',
  HelveticaNeueLTStdBdIt = 'HelveticaNeueLTStdBdIt',
  HelveticaNeue = 'Helvetica Neue',
  BarlowCondensedSemiBold = 'Barlow Condensed',

  FuturaBookItalic = 'FuturaBookItalic',

  NotoSans = 'Noto Sans',
  NotoSansBold = 'Noto Sans',
  NotoSansItalic = 'Noto Sans',
  NotoSansBoldItalic = 'Noto Sans',

  Nunito = 'Nunito',
  NunitoBold = 'Nunito',
  NunitoExtraBold = 'Nunito-ExtraBold',
  NunitoItalic = 'Nunito',
  NunitoBoldItalic = 'Nunito',
  NunitoExtraBoldItalic = 'Nunito-ExtraBold',
}

export enum COMBINED_FONT {
  BarlowCondensedOrDefault = '"Barlow Condensed", sans-serif',
}
