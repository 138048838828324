import { motion } from 'framer-motion';
import { IStateProps } from '../..';
import { IBaseMeta, Texts } from '../../../../interfaces/sceneState';
import { container } from './styles';
import { getMeta } from '../../../../helpers/getMeta';
import { createScene } from '../../../../helpers/createScene';

const default_delay = 1.5;
const logo_appear_duration = 1.7;

interface IBContent extends IBaseMeta {
  logoImage?: string;
  topText?: Texts;
  bottomText?: Texts;
  sceneDuration: number;
}

const LogoHTML = (props: IStateProps): React.ReactElement => {
  const meta = getMeta(props);
  return <Container sceneDuration={props.currentState.duration} {...meta} />;
};

export const LogoScene = createScene(LogoHTML, null);

const Container = ({ ...meta }: IBContent): React.ReactElement => {
  return <Logo logo={meta.logoImage} />;
};

interface ILogoProps {
  logo: string;
}

const Logo = ({ logo }: ILogoProps): React.ReactElement => {
  const pixelRatio = 10;
  const width = 335;
  const height = 105;
  const initWidth = width * pixelRatio;
  const initHeight = height * pixelRatio;
  const scaleUp = 110;
  return (
    <div style={container}>
      <motion.img
        style={{
          position: 'relative',
          willChange: 'auto',
        }}
        initial={{
          scale: 1 / pixelRatio,
          y: 0,
          x: 0,
        }}
        animate={{
          scale: scaleUp / pixelRatio,
          y: '-185%',
          x: '5.5%',
        }}
        transition={{
          delay: default_delay,
          duration: logo_appear_duration,
          ease: 'easeInOut',
        }}
        width={initWidth}
        height={initHeight}
        src={logo}
        alt="Loto Express logo"
      />
    </div>
  );
};
