import { COLORS } from '../../../../constants/colours';

export const backgroundImage: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};
export const contentContainer: React.CSSProperties = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  gap: 20,
};

export const textLineContainer: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
};

export const countdownContainer: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  gap: 10,
};

export const text: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
};

export const separator: React.CSSProperties = {
  ...text,
  paddingBottom: 10,
};

export const box: React.CSSProperties = {
  ...text,
  padding: 12,
  width: 46,
  height: 61,
  borderRadius: 15,
  color: COLORS.night2024,
};
