import { DrawInProgress } from '../../../Common/DrawInProgress';
import { LotoExpressLogo } from '../../../Common/LotoExpressLogo';
import { BoostSplash } from '../../../Common/BoostSplash';
import { IStateProps } from '../..';
import { createScene } from '../../../../helpers/createScene';

const ReorderHTML = (props: IStateProps) => {
  return (
    <>
      <DrawInProgress scene={props.displayState} {...props} />
      <LotoExpressLogo {...props} />
      <BoostSplash scene={props.displayState} {...props} />
    </>
  );
};

export const ReorderScene = createScene(ReorderHTML, null);
