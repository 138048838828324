import {
  type LocationWeatherData,
  type WeatherDisplayData,
} from '../interfaces/sceneState';

const iconIndices = [
  { code: 1, index: 0 }, // Object
  { code: 2, index: 1 }, // Object, little white cloud
  { code: 3, index: 2 }, // Object big light gray cloud
  { code: 4, index: 3 }, // Big light gray cloud
  { code: 5, index: 4 }, // Big dark, heavy rain
  { code: 6, index: 5 }, // Big dark cloud, sleet
  { code: 7, index: 6 }, // Big dark cloud, snow
  { code: 8, index: 7 }, // Object, big light gray cloud of rain showers
  { code: 9, index: 8 }, // Object big light gray cloud, snow showers
  { code: 10, index: 9 }, // Object, big light gray cloud, sleet showers
  { code: 11, index: 10 }, // Object, fog
  { code: 12, index: 11 }, // fog
  { code: 13, index: 12 }, // Big dark cloud, rain
  { code: 14, index: 13 }, // Object, big light gray cloud, rain showers, lightning
  { code: 15, index: 14 }, // Big dark cloud, rain showers
  { code: 16, index: 15 }, // Big dark cloud
  { code: 17, index: 16 },
  { code: 18, index: 17 },
  { code: 19, index: 18 },
  { code: 20, index: 19 },
  { code: 21, index: 20 },
  { code: 22, index: 21 },
  { code: 23, index: 22 },
  { code: 24, index: 23 },
  { code: 25, index: 24 },
  { code: 26, index: 25 },
  { code: 27, index: 26 },
  { code: 28, index: 27 },
  { code: 29, index: 28 },
  { code: 30, index: 29 },
];

export function parseWeather(
  location?: LocationWeatherData,
): WeatherDisplayData | undefined {
  if (location) {
    const { temperature = 0, weatherCode = 0 } = location;

    return {
      temperature,
      night: weatherCode < 0,
      iconIndex: (
        iconIndices.find((i) => i.code === Math.abs(weatherCode)) || {
          index: -1,
        }
      ).index,
    };
  } else {
    return undefined;
  }
}
