import * as THREE from 'three';
import ballYellowWhite from '../../../../assets/yellow_ball.svg';
import ballRedWhite from '../../../../assets/red_ball.svg';
import ballFullYellow from '../../../../assets/full_ball.svg';
import ballAsset from '../../../../assets/grid_ball.svg';
import { createBillboardMaterial } from './shaders/billboardMaterial';
import { createInstancedBillboardMaterial } from './shaders/instancedBillboardMaterial';

let yellowWhiteMaterial: THREE.Material | null = null;
let rotatableExtraMaterial: THREE.Material | null = null;
let fullYellowMaterial: THREE.Material | null = null;
let instancedGridBillboardMaterial: THREE.Material | null = null;
let basicBillboardMaterial: THREE.Material | null = null;
let rotatableBillboardMaterial: THREE.Material | null = null;
let rotatableExtraBillboardMaterial: THREE.Material | null = null;
let basicInstancedBillboardMaterial: THREE.Material | null = null;
let legacyBallShadedMaterial: THREE.MeshBasicMaterial | null = null;
let instancedGridFinalMaterial: THREE.Material | null = null;
export const getSharedMaterials = () => {
  if (
    !yellowWhiteMaterial ||
    !fullYellowMaterial ||
    !instancedGridBillboardMaterial ||
    !basicBillboardMaterial ||
    !rotatableBillboardMaterial ||
    !rotatableExtraBillboardMaterial ||
    !basicInstancedBillboardMaterial ||
    !legacyBallShadedMaterial ||
    !instancedGridFinalMaterial
  ) {
    const yellowWhiteTexture = new THREE.TextureLoader().load(ballYellowWhite);
    const redWhiteTexture = new THREE.TextureLoader().load(ballRedWhite);
    const fullYellowTexture = new THREE.TextureLoader().load(ballFullYellow);
    const instancedGridTexture = new THREE.TextureLoader().load(ballAsset);

    basicBillboardMaterial = createBillboardMaterial(
      new THREE.MeshBasicMaterial({ side: THREE.FrontSide }),
    );

    rotatableBillboardMaterial = new THREE.MeshBasicMaterial({
      side: THREE.FrontSide,
    });

    yellowWhiteMaterial = createBillboardMaterial(
      new THREE.MeshBasicMaterial({
        transparent: true,
        map: yellowWhiteTexture,
        side: THREE.FrontSide,
      }),
    );

    fullYellowMaterial = createBillboardMaterial(
      new THREE.MeshBasicMaterial({
        transparent: true,
        map: fullYellowTexture,
        side: THREE.FrontSide,
      }),
    );

    rotatableExtraMaterial = new THREE.MeshBasicMaterial({
      transparent: true,
      map: redWhiteTexture,
      side: THREE.FrontSide,
    });

    rotatableExtraBillboardMaterial = new THREE.MeshBasicMaterial({
      side: THREE.FrontSide,
    });

    legacyBallShadedMaterial = createBillboardMaterial(
      new THREE.MeshBasicMaterial({
        transparent: true,
        side: THREE.FrontSide,
      }),
    );

    // billboard it if possible
    instancedGridFinalMaterial = new THREE.MeshBasicMaterial({
      transparent: true,
      map: yellowWhiteTexture as THREE.Texture,
      side: THREE.FrontSide,
    });

    // INSTANCED BILLBOARD MATERIALS
    basicInstancedBillboardMaterial = createInstancedBillboardMaterial(
      new THREE.MeshBasicMaterial({ side: THREE.FrontSide }),
    );

    instancedGridBillboardMaterial = createInstancedBillboardMaterial(
      new THREE.MeshBasicMaterial({
        transparent: true,
        map: instancedGridTexture as THREE.Texture,
        side: THREE.FrontSide,
      }),
    );
  }

  return {
    yellowWhiteMaterial,
    rotatableExtraMaterial,
    fullYellowMaterial,
    instancedGridBillboardMaterial,
    instancedGridFinalMaterial,
    basicBillboardMaterial,
    rotatableBillboardMaterial,
    rotatableExtraBillboardMaterial,
    basicInstancedBillboardMaterial,
    legacyBallShadedMaterial,
  } as {
    yellowWhiteMaterial: THREE.Material;
    rotatableExtraMaterial: THREE.Material;
    fullYellowMaterial: THREE.Material;
    instancedGridBillboardMaterial: THREE.Material;
    instancedGridFinalMaterial: THREE.Material;
    basicBillboardMaterial: THREE.Material;
    rotatableBillboardMaterial: THREE.Material;
    rotatableExtraBillboardMaterial: THREE.Material;
    basicInstancedBillboardMaterial: THREE.Material;
    legacyBallShadedMaterial: THREE.Material;
  };
};
