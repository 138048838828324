import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { COLORS } from '../../../constants/colours';
import {
  adjustArrayValues,
  createArrayOfLength,
  getSortData,
  type ISortable,
} from '../../../helpers/arrays';
import { wait } from '../../../helpers/functions';
import { templateTexts } from '../../../helpers/strings';
import { ISettings } from '../../../interfaces/app';
import { type ILoExScene } from '../../../interfaces/scene';
import { type ExtraStateScene } from '../../../interfaces/sceneState';
import { AnimationPlane } from '../../../models/animationPlane';
import { BallShaded } from '../../../models/ballShaded';
import { ColorPlane } from '../../../models/colorPlane';
import { ImagePlane } from '../../../models/imagePlane';
import { Scene, type ISceneMeshesBase } from '../../../models/scene';
import { Text } from '../../../models/text';
import LayoutService from '../../../services/layout';
import { sendMessage } from '../../../helpers/message';
import dotImage from './../../../assets/images/dot.png';
import explosionRedImage from './../../../assets/images/animations/explosion-red-animation.png';
import { FeatureFlag } from '../../../enums/featureFlag';

interface ISceneMeshes extends ISceneMeshesBase {
  balls: BallShaded[];
  cover?: ColorPlane;
  drawInProgress?: Text;
  extraBall?: BallShaded;
  extraLogo?: ImagePlane;
  explosion?: AnimationPlane;
  dot?: AnimationPlane;
}

export default class ExtraScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: ExtraStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {
    balls: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: ExtraStateScene,
    settings: ISettings,
  ) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: { bottomText, id, balls, extra, extraImage },
          },
        },
      } = this;

      const currentResults = getSortData(balls ?? [], (a, b) => a - b);

      const ballLayout = LayoutService.ballGridLayout();

      scene.add((meshes.cover = new ColorPlane()));
      scene.add((meshes.dot = new AnimationPlane()));
      scene.add((meshes.drawInProgress = new Text()));
      scene.add((meshes.extraBall = new BallShaded()));
      scene.add((meshes.extraLogo = new ImagePlane()));
      scene.add((meshes.explosion = new AnimationPlane()));

      return await Promise.all<any>([
        ...createArrayOfLength(20).map(async (n, i) => {
          if (currentResults[i] && !meshes.balls[i]) {
            scene.add((meshes.balls[i] = new BallShaded()));

            return await meshes.balls[i].configure({
              value: currentResults[i].value,
              state: {
                position: ballLayout.positions[currentResults[i].sortedIndex],
                rotation: [0, 0, -Math.PI * 2],
                scale: ballLayout.scale,
                opacity: 1,
              },
              featureFlag: FeatureFlag.Default,
            });
          } else {
            return await Promise.resolve();
          }
        }),
        meshes.dot.configure({
          images: [
            {
              arrangement: [2, 2],
              totalFrames: 2,
              url: dotImage,
            },
          ],
          state: {
            position: [-4.025, -2.2, -3.5],
            scale: [0.11, 0.11, 0.11],
            rotation: [0, 0, 0],
          },
        }),
        meshes.explosion.configure({
          images: [
            {
              arrangement: [8, 8],
              totalFrames: 64,
              url: explosionRedImage,
            },
          ],
          state: {
            position: [0, 0, -3],
            scale: [4, 4, 4],
            rotation: [0, 0, 0],
          },
        }),
        meshes.extraBall.configure({
          value: extra,
          isExtraBall: true,
          state: {
            position: [0, 0, -2],
            rotation: [0, 0, Math.PI * 8],
            scale: ballLayout.scale,
            opacity: 0,
          },
          featureFlag: FeatureFlag.Default,
        }),
        meshes.cover.configure({
          color: COLORS.white,
          state: {
            position: [0, 0, -3.4],
            rotation: [0, 0, 0],
            scale: [8.92, 5.0175, 1],
            opacity: 1,
          },
        }),
        meshes.drawInProgress.configure({
          text: templateTexts(bottomText, { id }),
          state: {
            position: [-3.9, -2.2, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 1,
          },
        }),
        meshes.extraLogo.configure({
          dimensions: { height: 256, width: 512 },
          urls: [extraImage],
          state: {
            position: [0, 0, -1.5],
            scale: [1.85, 0.575, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const {
        _meshes: meshes,
        _state: {
          meta: {
            [this._language]: { balls, extra },
          },
        },
      } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      const currentResults = getSortData(balls ?? [], (a, b) => a - b);

      const ballLayout = LayoutService.ballGridLayout();

      const currentResult = currentResults.find(
        (result: ISortable<number>) => result.value === extra,
      ) || { sortedIndex: 0, originalIndex: 0 };
      const position = ballLayout.positions[currentResult.sortedIndex];

      return await meshes.extraLogo
        .tweenTo(
          {
            position: [0, 0, -2],
            opacity: 1,
          },
          1500,
          0,
          TWEEN.Easing.Elastic.Out,
        )
        .then(
          async () =>
            await Promise.all([
              meshes.explosion.animateOnce(3000),
              meshes.dot.beginAnimation(1000),
              wait(500)
                .then(
                  async () =>
                    await meshes.extraLogo.tweenOut(
                      {
                        opacity: 0,
                      },
                      500,
                    ),
                )
                .then(
                  async () =>
                    await meshes.extraLogo.changeTo({
                      position: [-3.25, 2, -3.5],
                    }),
                )
                .then(
                  async () =>
                    await meshes.extraLogo.tweenTo(
                      {
                        opacity: 1,
                      },
                      500,
                    ),
                ),
              wait(500).then(
                async () =>
                  await meshes.cover.tweenOut(
                    {
                      opacity: 0,
                    },
                    1000,
                  ),
              ),
              wait(1000)
                .then(async () => {
                  sendMessage('draw:extra', extra);
                  return await meshes.extraBall.tweenTo(
                    {
                      rotation: [0, 0, 0],
                      opacity: 1,
                    },
                    2500,
                    0,
                    TWEEN.Easing.Quadratic.Out,
                  );
                })
                .then(async () => await wait(1500))
                .then(
                  async () =>
                    await meshes.extraBall.tweenTo(
                      {
                        position: adjustArrayValues<[number, number, number]>(
                          position,
                          [0, 0, 0.005],
                        ),
                        opacity: 1,
                      },
                      1000,
                    ),
                )
                .then(
                  async () =>
                    await meshes.balls[currentResult.originalIndex].tweenOut({
                      opacity: 0,
                    }),
                ),
            ]),
        )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy());
      }

      return await Promise.all<any>([
        meshes.extraBall
          ? meshes.extraBall.tweenOut({
              opacity: 0,
            })
          : Promise.resolve(),
        ...meshes.balls.map(
          async (ball) =>
            await ball.tweenOut({
              opacity: 0,
            }),
        ),
        meshes.drawInProgress.tweenOut({ opacity: 0 }),
        meshes.extraLogo.tweenOut({ opacity: 0 }),
        meshes.dot.tweenOut({ opacity: 0 }),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: ExtraStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
