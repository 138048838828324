import * as THREE from 'three';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { Text } from '../../../models/text';
import { COLORS } from '../../../constants/colours';
import { FONTS } from '../../../enums/fonts';
import { wait } from '../../../helpers/functions';
import { type ILoExScene } from '../../../interfaces/scene';
import { type LoadingStateScene } from '../../../interfaces/sceneState';
import { ISettings } from '../../../interfaces/app';

interface ISceneMeshes extends ISceneMeshesBase {
  placeholders: Text[];
}

export default class LoadingScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: LoadingStateScene;

  protected _meshes: ISceneMeshes = {
    placeholders: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: LoadingStateScene,
    settings: ISettings,
  ) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;

      const { _meshes: meshes } = this;

      return await Promise.all(
        Object.keys(FONTS).map(async (font, i) => {
          this._scene.add((meshes.placeholders[i] = new Text()));

          return await meshes.placeholders[i].configure({
            state: {
              position: [0, 0, 0],
              scale: [1, 1, 1],
              rotation: [0, 0, 0],
              opacity: 1,
            },
            text: [
              {
                text: '_',
                font,
                color: COLORS.white,
                size: 16,
                // fillStyle: COLORS.white,
                // strokeStyle: COLORS.black,
                // strokeWidth: "0",
              },
            ],
          });
        }),
      )
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(
      async (resolve, reject) => await wait(1000).then(() => resolve()),
    );

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      return await Promise.resolve()
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: LoadingStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
