import { useCallback, useEffect, useRef } from 'react';

export const useVisibilityChange = (scene: string) => {
  const lastSceneRef = useRef(scene);
  useEffect(() => {
    lastSceneRef.current = scene;
  }, [scene]);

  const skipAnim = useRef(false);

  const handleExitComplete = useCallback(() => {
    skipAnim.current = false;
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden && scene !== lastSceneRef.current) {
      skipAnim.current = true;
      requestAnimationFrame(() => {
        skipAnim.current = false;
      });
    }
  }, [scene]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [handleVisibilityChange]);

  return {
    skipAnim,
    handleExitComplete,
  };
};
