import TWEEN from '@tweenjs/tween.js';
import { animate } from '../helpers/animate';
import { getRandom } from '../helpers/arrays';
import { type IStaggeredTextDrawingInstruction } from '../helpers/canvas';
import { wait } from '../helpers/functions';
import { clamp } from '../helpers/maths';
import { type Texts } from '../interfaces/sceneState';
import { Text } from './text';

export class ShuffleText extends Text {
  public animateIn = async (
    duration: number = 1000,
    delay: number = 0,
    pool: string[] = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '#',
      '+',
      '*',
      '@',
      '.',
      '-',
    ],
  ) => {
    return await this.drawText(undefined, [0, 0])
      .then(async () => await wait(delay))
      .then(async () => await this.changeTo({ opacity: 1 }))
      .then(async () => await this.animate(this.getText(), pool, duration));
  };

  private readonly animate = async (
    text: Texts = this.getText(),
    pool: string[] = [],
    duration: number = 1000,
    preamble: number = 30,
  ) =>
    await new Promise<void>((resolve) => {
      const sText: IStaggeredTextDrawingInstruction[] = text.map((t) => ({
        ...t,
        text: [...getRandom(pool, preamble), t.text],
      }));

      const { from, to } = text.reduce(
        (out, sT, i, a) => ({
          from: [...out.from, 0 - i],
          to: [...out.to, a.length - i + preamble],
        }),
        { from: [], to: [] },
      );

      animate(from, to, {
        complete: () => resolve(),
        duration,
        easing: TWEEN.Easing.Linear.None,
        framerate: 30,
        update: async (values) =>
          await this.drawText(
            sText.map((t, i) => {
              return {
                ...t,
                text:
                  t.text[Math.round(clamp(values[i], -1, t.text.length - 1))] ||
                  ' ',
              };
            }),
          ),
      });
    });
}
