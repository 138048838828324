import { type saveFeatureFlags } from '../actions/featureFlag';
import { FEATURES_FLAG_ACTIONS } from '../enums/actions';
import { type IAppState } from '../interfaces/app';
import { type ActionOutput } from '../types';

const saveFeatureFlagsReducer = (
  state: IAppState,
  { featureFlags }: ActionOutput<typeof saveFeatureFlags>,
): IAppState => {
  return {
    ...state,
    settings: {
      ...state.settings,
      featureFlags,
      isFeatureFlagsReady: true,
    },
  };
};

const saveFeature = {
  [FEATURES_FLAG_ACTIONS.SAVE_FEATURE_FLAGS]: saveFeatureFlagsReducer,
};
export default saveFeature;
