/* eslint-disable @typescript-eslint/prefer-reduce-type-parameter */
import { css, type StyleDeclaration } from 'aphrodite/no-important';

export const aspectRatio = (width: number, height: number): any => ({
  position: 'relative',
  overflow: 'hidden',
  ':before': {
    display: 'block',
    content: '""',
    width: '100%',
    paddingTop: `calc((${height} / ${width}) * 100%)`,
  },
  ':not(:empty) > *': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

export const getClassNames = <T extends string = string>(
  styles: Record<T, StyleDeclaration>,
): Record<T, string> => {
  return Object.keys(styles).reduce<Record<T, string>>(
    (out, style) => ({
      ...out,
      [style]: css(styles[style]),
    }),
    {} as Record<T, string>,
  );
};
