import { Text } from '@react-three/drei';
import { T3D } from '.';
import {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import font3D from '../../../../assets/fonts/BarlowCondensed/BarlowCondensed-SemiBold.ttf';
import { getSharedMaterials } from './getSharedMaterials';

const TextZOffset = 0.0005;
interface INumberTextProps {
  position?: T3D;
  rotation?: T3D;
  scale?: number;
  number: number | string;
  transition?: boolean;
  rotatable?: boolean;
  material?: THREE.Material;
}

export interface INumberTextHandle {
  updateMatrix: (position?: T3D, rotation?: T3D) => void;
}

export const NumberText = forwardRef<INumberTextHandle, INumberTextProps>(
  (
    {
      position = [0, 0, 0],
      rotation = [0, 0, 0],
      scale = 1,
      number,
      transition = true,
      rotatable = false,
      material,
    }: INumberTextProps,
    ref,
  ) => {
    const textPos = [
      position[0],
      position[1],
      position[2] + TextZOffset,
    ] as T3D;
    const textRotations = [rotation[0], rotation[1], rotation[2]] as T3D;
    const textRef = useRef<THREE.Mesh>(null!);
    useFadeTransition(transition, textRef);

    const updateMatrix = useCallback(
      (postions?: T3D, rotations?: T3D, scales?: T3D) => {
        if (textRef.current) {
          textRef.current.matrixAutoUpdate = false;
          if (!!postions) {
            textRef.current.position.set(
              postions[0],
              postions[1],
              postions[2] + TextZOffset,
            );
          }
          if (!!rotations) {
            textRef.current.rotation.set(
              rotations[0],
              rotations[1],
              rotations[2],
            );
          }
          if (!!scales) {
            textRef.current.scale.set(scales[0], scales[1], scales[2]);
          }

          textRef.current.updateMatrix();
        }
      },
      [textRef],
    );

    useEffect(() => {
      if (textRef.current) {
        updateMatrix(position, rotation, [scale, scale, scale]);
      }
    }, [textRef, position, rotation, scale, updateMatrix]);

    const { basicBillboardMaterial, rotatableBillboardMaterial } =
      getSharedMaterials();

    const textMaterial =
      material ??
      (rotatable ? rotatableBillboardMaterial : basicBillboardMaterial);

    useImperativeHandle(ref, () => ({
      updateMatrix: (position?: T3D, rotation?: T3D) =>
        updateMatrix(position, rotation, [scale, scale, scale]),
    }));

    return (
      <Text
        renderOrder={1}
        ref={textRef}
        position={textPos}
        scale={scale}
        rotation={textRotations}
        fontSize={0.38}
        color="black"
        anchorX="center"
        anchorY="middle"
        font={font3D}
        material={textMaterial}
        characters={number.toString()}
      >
        {number}
      </Text>
    );
  },
);

export function useFadeTransition(
  transition: boolean,
  ref: MutableRefObject<any>,
) {
  useEffect(() => {
    if (transition && ref.current && ref.current.material) {
      ref.current.material.transparent = true;
      ref.current.material.opacity = 0;
    }
  }, [transition, ref]);

  useFrame((_state, delta) => {
    if (transition && ref.current && ref.current.material) {
      ref.current.material.opacity = Math.min(
        ref.current.material.opacity + delta,
        1,
      );
    }
  });
}
