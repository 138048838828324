import { IStateProps } from '../..';
import { DrawInProgress } from '../../../Common/DrawInProgress';
import { NextDraw } from '../../../Common/NextDraw';
import { LotoExpressLogo } from '../../../Common/LotoExpressLogo';
import {
  BangoItem,
  IBangoMeta,
  IBaseMeta,
  Texts,
} from '../../../../interfaces/sceneState';
import { MetaText } from '../../../Common/MetaText';
import React from 'react';
import { motion } from 'framer-motion';
import { getMeta } from '../../../../helpers/getMeta';
import { createScene } from '../../../../helpers/createScene';
import { FONTS } from '../../../../enums/fonts';
import ResultBanner from '../../../Common/ResultBanner';

interface IBContent extends IBaseMeta {
  bango?: BangoItem[];
  boost?: number;
  extra?: number;
  topText?: Texts;
  mainImage?: string;
  primaryText?: Texts;
  secondaryText?: Texts;
}

const DidYouKnowHTML = (props: IStateProps) => {
  const meta = getMeta(props) as IBangoMeta;

  const DidYouKnowSceneStyle = () => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      gap: '1.5em',
      flexDirection: 'column',
      margin: '0 14em',
    } as React.CSSProperties;
  };

  const formatCHF = (amount: number) => {
    const formattedAmount = amount.toLocaleString('en-US');
    return `CHF ${formattedAmount.replace(/,/g, "'")}.-`;
  };
  function replaceAll(str: string, find: string, replace: string) {
    const escapedFind = find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedFind, 'g');
    return str.replace(regex, replace);
  }

  const replacePlaceHolder = (text: string, meta: IBContent) => {
    if (!text) return '';

    let result = text;

    // Replace [bango10]
    const bango10Amount =
      Number(meta?.bango?.find((value) => value.id === 10)?.amount ?? 0) / 100;
    result = replaceAll(result, '[bango10]', formatCHF(bango10Amount));

    // Replace [extra]
    result = replaceAll(result, '[extra]', String(meta?.extra));

    // Replace [boost]
    result = replaceAll(result, '[boost]', String(meta?.boost));

    return result;
  };
  const DidYouKnowSceneContent = React.memo(({ ...meta }: IBContent) => {
    return (
      <div style={DidYouKnowSceneStyle()}>
        <MetaText
          customFont={FONTS.BarlowCondensedBold}
          align={'center'}
          content={meta?.topText?.[0]}
        />
        <div style={{ height: '180px' }}>
          <motion.img
            width={300}
            src={meta?.mainImage}
            initial={{
              scale: 0.001,
            }}
            animate={{ scale: [0.001, 1] }}
            transition={{
              delay: 0.9,
              type: 'spring',
              duration: 1.2,
              bounce: 0.3,
            }}
          />
        </div>
        <MetaText
          customFont={FONTS.BarlowCondensedBold}
          align={'center'}
          content={
            meta?.primaryText?.[0]
              ? {
                  ...meta?.primaryText?.[0],
                  text: replacePlaceHolder(meta?.primaryText?.[0].text, meta),
                }
              : undefined
          }
        />
        <MetaText
          customFont={FONTS.BarlowCondensedBold}
          align={'center'}
          content={
            meta?.secondaryText?.[0]
              ? {
                  ...meta?.secondaryText?.[0],
                  text: replacePlaceHolder(meta?.secondaryText?.[0].text, meta),
                }
              : undefined
          }
        />
      </div>
    );
  });
  return (
    <>
      <LotoExpressLogo {...props} />
      <DrawInProgress scene={props.displayState} {...props} />
      <NextDraw {...meta} />
      <DidYouKnowSceneContent {...meta} />
      <ResultBanner
        ballValues={meta.balls}
        extraValue={meta.extra}
        {...props}
      />
    </>
  );
};

export const DidYouKnowScene = createScene(DidYouKnowHTML, null);
