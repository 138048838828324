import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { ImagePlane } from '../../../models/imagePlane';
import { COLORS } from '../../../constants/colours';
import { FONTS } from '../../../enums/fonts';
import { createArrayOfLength } from '../../../helpers/arrays';
import { wait } from '../../../helpers/functions';
import { Text } from '../../../models/text';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { type CountdownStateScene } from '../../../interfaces/sceneState';
import { type ILoExScene } from '../../../interfaces/scene';

interface ISceneMeshes extends ISceneMeshesBase {
  numbers: Text[];
  circle?: ImagePlane;
}

export default class CountdownScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: CountdownStateScene;

  protected _meshes: ISceneMeshes = {
    numbers: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (language: string, state: CountdownStateScene) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: { countdownImage },
          },
        },
      } = this;

      scene.add(
        ...(meshes.numbers = createArrayOfLength(5).map(() => new Text())),
      );
      scene.add((meshes.circle = new ImagePlane()));

      return await Promise.all([
        ...meshes.numbers.map(
          async (m, i) =>
            await m.configure({
              state: {
                position: [0.025, -0.245, -5],
                scale: [0, 2.2, 1],
                rotation: [0, 0, 0],
                opacity: 0,
              },
              text: [
                {
                  text: `${5 - i}`,
                  font: FONTS.HelveticaNeueLTStdBdIt,
                  color: COLORS.white,
                  size: 512,
                  // fillStyle: COLORS.white,
                  // strokeStyle: COLORS.transparent,
                  // strokeWidth: '0',
                },
              ],
              textAlign: 'center',
            }),
        ),
        meshes.circle.configure({
          urls: [countdownImage],
          state: {
            position: [0, 0, -3.6],
            scale: [4.5, 4.5, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
      ])
        .then(async () => await wait(125))
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      return await meshes.circle
        .tweenTo({ opacity: 1 }, 250)
        .then(
          async () =>
            await Promise.all([
              ...meshes.numbers.map(
                async (m, i) =>
                  await m
                    .tweenTo(
                      {
                        position: [0.025, -0.245, -3.5],
                        opacity: 1,
                      },
                      500,
                      i * 1000,
                    )
                    .then(
                      async () =>
                        await m.tweenTo({
                          // position: [i % 2 ? 3 : -3, -1, -2],
                          // rotation: [0, i % 2 ? 0.5 : -0.5, 0],
                          opacity: 0,
                        }),
                    ),
              ),
              meshes.circle
                .tweenTo(
                  {
                    rotation: [0, 0, -Math.PI * 2.5],
                  },
                  5000,
                  0,
                  TWEEN.Easing.Linear.None,
                )
                .then(
                  async () =>
                    await meshes.circle.tweenTo(
                      {
                        opacity: 0,
                        rotation: [0, 0, -Math.PI * 2.625],
                      },
                      250,
                      0,
                      TWEEN.Easing.Linear.None,
                    ),
                ),
            ]),
        )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      return await Promise.all([
        ...meshes.numbers.map(
          async (m) => await m.tweenOut({ opacity: 0 }, 250),
        ),
        meshes.circle.tweenOut({ opacity: 0 }, 250),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: CountdownStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
