import { UIState } from '../enums/app';
import { getQueryStringParameter } from '../helpers/browser';
import { type IAppState } from '../interfaces/app';

const {
  language = 'fr-ch',
  base = '/api',
  debug = 'false',
  channel = 'ONLINE',
  scale = '1',
  loop = undefined,
} = getQueryStringParameter();

const parseBoolean = (value: string): boolean => {
  return value === 'true';
};

const parseLanguage = (value: string): string => {
  return String(value).toLowerCase();
};

const parseNUmber = (value: string): number => {
  return Number(value) || 1;
};

const parseChanel = (value: string): 'ONLINE' | 'TV' => {
  if (value.toUpperCase() === 'ONLINE') return 'ONLINE';
  if (value.toUpperCase() === 'TV') return 'TV';

  console.error(
    `Failed to parse channel=${channel}, fallback to value 'ONLINE'`,
  );
  return 'ONLINE';
};

export const DEFAULT_STATE: IAppState = {
  language: parseLanguage(language),
  settings: {
    base,
    debug: parseBoolean(debug),
    scale: parseNUmber(scale),
    loop,
    channel: parseChanel(channel),
    featureFlags: [],
    isFeatureFlagsReady: false,
  },
  uiState: {
    readyState: UIState.Unevaluated,
    lastUpdate: new Date(0),
    loadTimestamp: new Date(),
  },
};

export const INITIAL_STATE: IAppState = DEFAULT_STATE;
