import { type Texts } from '../interfaces/sceneState';
import { COLORS } from './colours';

export const timeEmpty: Texts = [
  {
    text: '00:00',
    color: COLORS.white,
    font: 'GothamRoundedBold',
    size: 32,
  },
];

export const timeFormat: Texts = [
  {
    text: 'mm:ss',
    color: COLORS.white,
    font: 'GothamRoundedBold',
    size: 32,
  },
];

export const timeInvalid: Texts = [
  {
    text: '--:--',
    color: COLORS.white,
    font: 'GothamRoundedBold',
    size: 32,
  },
];
