export const COLORS = {
  transparent: 'rgba(0, 0, 0, 0)',
  white: 'rgb(255,255,255)',
  yellow: 'rgb(255, 221, 0)',
  yellow2024: '#FCEE4F',
  green: 'rgb(175, 198, 0)',
  red: 'rgb(194, 14, 26)',
  red2024: '#ff0000',
  brightRed: 'rgb(225, 28, 23)',
  black: 'rgb(0, 0, 0)',
  blue: 'rgb(52, 165, 221)',
  teal: 'rgb(29, 135, 191)',
  orange: '#dc931a',
  night2024: '#002652',

  bgGradientTop: 'rgb(52, 165, 221)',
  bgGradientBottom: 'rgb(25, 83, 120)',
};
