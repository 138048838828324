import * as THREE from 'three';
import { getCanvasTextureFromUrls } from '../helpers/canvas';
import { type IDimensions, type IState } from '../interfaces/scene';
import { Mesh } from './mesh';

export class ImagePlane extends Mesh {
  public configure = async ({
    dimensions: { height, width } = {},
    state,
    urls,
  }: {
    dimensions?: Partial<IDimensions>;
    state: IState;
    urls: string[];
  }): Promise<void> => {
    this._previous = this._current = state;

    this.name = `ImagePlane`;

    return await getCanvasTextureFromUrls(urls, width, height)
      .then((texture) => {
        texture.generateMipmaps = false;

        this.geometry = new THREE.BoxGeometry(1, 1, 1e-10, 1, 1, 1);
        this.material = new THREE.MeshBasicMaterial({
          color: 'white',
          map: texture,
          transparent: true,
        });
      })
      .then(() => this.updateMesh(state));
  };

  public changeImages = async ({
    dimensions: { height, width } = {},
    urls,
  }: {
    dimensions?: Partial<IDimensions>;
    urls: string[];
  }): Promise<void> => {
    return await getCanvasTextureFromUrls(urls, width, height).then(
      (texture) => {
        texture.generateMipmaps = false;

        if (
          (this.material as THREE.MeshBasicMaterial).map instanceof
          THREE.Texture
        ) {
          (this.material as THREE.MeshBasicMaterial).map.dispose();
        }

        (this.material as THREE.MeshBasicMaterial).map = texture;
      },
    );
  };
}
