import { Scene } from 'three';
import { ILoExScene } from '../../../../interfaces/scene';
import * as THREE from 'three';

export default class EmptyScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  public getScene = () => {
    return this._scene;
  };
  public onChange = async (_props: any) =>
    await new Promise<void>((resolve) => {
      resolve();
    });
  public setup = async (_props: any) =>
    await new Promise<void>((resolve) => {
      resolve();
    });
  public play = async () =>
    await new Promise<void>((resolve) => {
      resolve();
    });
  public teardown = async () =>
    await new Promise<void>((resolve) => {
      resolve();
    });
}
