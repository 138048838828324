import moment from 'moment';
import * as THREE from 'three';
import {
  calculateTextOffsets,
  drawMonoSpaceMultiPartText,
  drawMultiPartText,
} from '../helpers/canvas';
import { toCeilPower } from '../helpers/maths';
import { type IDimensions, type IState } from '../interfaces/scene';
import { type Texts } from '../interfaces/sceneState';
import { CanvasTexture } from './canvasTexture';
import { Mesh } from './mesh';

export class Timer extends Mesh {
  protected _canvas: HTMLCanvasElement = document.createElement('canvas');
  protected _canvasContext: CanvasRenderingContext2D =
    this._canvas.getContext('2d')!;

  protected _format: Texts;
  protected _empty: Texts;
  protected _invalid: Texts;
  protected _textAlign: CanvasTextAlign;
  protected _textBaseline: CanvasTextBaseline;
  protected _glyphWidth?: number;

  private _interval: any;
  private _date: Date;

  public configure = async ({
    state,
    format,
    empty,
    invalid,
    textAlign = 'left',
    textBaseline = 'middle',
    glyphWidth,
    dimensions,
  }: {
    dimensions?: IDimensions;
    glyphWidth?: number;
    state: IState;
    format: Texts;
    empty: Texts;
    invalid: Texts;
    textAlign?: CanvasTextAlign;
    textBaseline?: CanvasTextBaseline;
  }): Promise<void> => {
    this._previous = this._current = state;

    this.name = `Text_Timer`;

    return await this.setText(
      format,
      empty,
      invalid,
      textAlign,
      textBaseline,
      glyphWidth,
      dimensions,
    )
      .then(({ scaled: { width, height } }) => {
        const aspectRatio = width / height;
        const [, sY, sZ] = state.scale;

        return (this._previous = this._current =
          {
            ...state,
            scale: [sY * aspectRatio, sY, sZ],
          } as IState);
      })
      .then(() => {
        this.geometry = new THREE.PlaneGeometry(1, 1);
        this.material = new THREE.MeshBasicMaterial({
          color: 'white',
          map: new CanvasTexture(this._canvas),
          transparent: true,
        });
      })
      .then(() => this.updateMesh(this._current))
      .then(async () => await this.drawText());
  };

  public setDate = async (date: Date): Promise<void> => {
    this._date = date;

    return await this.drawText();
  };

  public stopAutoRedraw = async (): Promise<void> =>
    await new Promise((resolve) => {
      this._interval = clearInterval(this._interval);

      return resolve();
    });

  public beginAutoRedraw = async (): Promise<void> =>
    await this.stopAutoRedraw()
      .then(() => (this._interval = setInterval(this.drawText, 1000)))
      .then(() => {
        /* NO-OP */
      });

  public tweenOut = async (
    state: Partial<IState>,
    duration?: number,
    delay: number = 0,
    easing?: (k: number) => number,
  ): Promise<void> =>
    await this.tweenTo(state, duration, delay, easing).then(
      async () => await this.stopAutoRedraw(),
    );

  public setText = async (
    format: Texts = this._format,
    empty: Texts = this._empty,
    invalid: Texts = this._invalid,
    textAlign: CanvasTextAlign = this._textAlign,
    textBaseline: CanvasTextBaseline = this._textBaseline,
    glyphWidth: number | undefined = this._glyphWidth,
    dimensions?: IDimensions,
  ): Promise<{ original: IDimensions; scaled: IDimensions }> => {
    this._format = format;
    this._empty = empty;
    this._invalid = invalid;
    this._textAlign = textAlign;
    this._textBaseline = textBaseline;
    this._glyphWidth = glyphWidth;

    return await Promise.resolve(
      this.resizeCanvas(
        this._canvas,
        this._canvasContext,
        textAlign,
        textBaseline,
        glyphWidth,
        dimensions,
      ),
    );
  };

  protected resizeCanvas = (
    canvas: HTMLCanvasElement = this._canvas,
    canvasContext: CanvasRenderingContext2D = this._canvasContext,
    textAlign: CanvasTextAlign,
    textBaseline: CanvasTextBaseline,
    glyphWidth?: number,
    dimensions?: IDimensions,
  ) => {
    const text = this.getText();

    const { height, width } = dimensions || {
      height: text.reduce((out, curr) => {
        return Math.max(out, Number(curr.size));
      }, 0),
      width: (glyphWidth
        ? text.map(() => glyphWidth)
        : calculateTextOffsets(canvasContext, text, textAlign, textBaseline)
      ).reduce((out, curr) => out + curr, 0),
    };

    canvas.width = toCeilPower(width);
    canvas.height = toCeilPower(height);

    return {
      original: { width, height },
      scaled: { width: canvas.width, height: canvas.height },
    };
  };

  protected getText = (to: Date = this._date): Texts => {
    const valid = moment().isValid();
    const diff = -moment().diff(to);

    if (valid && diff >= 0) {
      return this._format.map((s) => ({
        ...s,
        text: moment.utc(diff).format(s.text),
      }));
    } else if (valid) {
      return this._empty;
    } else {
      return this._invalid;
    }
  };

  protected drawText = async (
    instructions: Texts = this.getText(),
    range: [number, number] = [0, instructions.length],
    glyphWidth: number | undefined = this._glyphWidth,
  ) =>
    await new Promise<void>((resolve) => {
      if (
        this.material instanceof THREE.MeshBasicMaterial &&
        this.material.map instanceof THREE.Texture
      ) {
        this._canvasContext.clearRect(
          0,
          0,
          this._canvas.width,
          this._canvas.height,
        );

        // DEBUG
        // this._canvasContext.strokeStyle = 'lime';
        // this._canvasContext.strokeRect(0, 0, this._canvas.width, this._canvas.height);
        // DEBUG

        if (typeof glyphWidth === 'number') {
          drawMonoSpaceMultiPartText(
            this._canvasContext,
            instructions,
            this._textAlign,
            this._textBaseline,
            range,
            undefined,
            glyphWidth,
          );
        } else {
          drawMultiPartText(
            this._canvasContext,
            instructions,
            this._textAlign,
            this._textBaseline,
            range,
          );
        }

        this.material.map.needsUpdate = true;
        this.material.needsUpdate = true;
      }

      resolve();
    });

  protected updateMesh = (state: IState) => {
    this._current = state;

    const {
      position: [pX, pY, pZ],
      rotation: [rX, rY, rZ],
      scale: [sX, sY, sZ],
      opacity: o,
    } = state;

    let _pX = pX;
    let _pY = pY;

    switch (this._textAlign) {
      case 'left':
      case 'start':
        _pX = pX + sX / 2;
        break;
      case 'right':
      case 'end':
        _pX = pX - sX / 2;
        break;
      case 'center':
      default:
    }

    switch (this._textBaseline) {
      case 'top':
        _pY = pY + sY / 2;
        break;
      case 'bottom':
        _pY = pY - sY / 2;
        break;
      case 'hanging':
      case 'middle':
      case 'alphabetic':
      case 'ideographic':
      default:
    }

    this.position.set(_pX, _pY, pZ);
    this.rotation.set(rX, rY, rZ);
    this.scale.set(sX, sY, sZ);
    (this.material as THREE.Material).opacity = o;
  };
}
