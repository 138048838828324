import * as THREE from 'three';
import {
  calculateTextOffsets,
  drawMonoSpaceMultiPartText,
  drawMultiPartText,
} from '../helpers/canvas';
import { toCeilPower } from '../helpers/maths';
import { type IDimensions, type IState } from '../interfaces/scene';
import { type Texts } from '../interfaces/sceneState';
import { CanvasTexture } from './canvasTexture';
import { Mesh } from './mesh';

export class Text extends Mesh {
  protected _canvas: HTMLCanvasElement = document.createElement('canvas');
  protected _canvasContext: CanvasRenderingContext2D =
    this._canvas.getContext('2d')!;

  protected _text: Texts;
  protected _textAlign: CanvasTextAlign;
  protected _textBaseline: CanvasTextBaseline;
  protected _glyphWidth?: number;

  public configure = async ({
    state,
    text,
    textAlign = 'left',
    textBaseline = 'middle',
    glyphWidth,
    dimensions,
  }: {
    dimensions?: IDimensions;
    glyphWidth?: number;
    state: IState;
    text: Texts;
    textAlign?: CanvasTextAlign;
    textBaseline?: CanvasTextBaseline;
  }): Promise<void> => {
    this._previous = this._current = state;

    this.name = `Text_${text.map((t) => t.text).join('_')}`;

    return await this.setText(
      text,
      textAlign,
      textBaseline,
      glyphWidth,
      dimensions,
    )
      .then(({ scaled: { width, height } }) => {
        const aspectRatio = width / height;
        const [, sY, sZ] = state.scale;

        return (this._previous = this._current =
          {
            ...state,
            scale: [sY * aspectRatio, sY, sZ],
          } as IState);
      })
      .then(() => {
        this.geometry = new THREE.PlaneGeometry(1, 1);
        this.material = new THREE.MeshBasicMaterial({
          color: 'white',
          map: new CanvasTexture(this._canvas),
          transparent: true,
        });
      })
      .then(() => this.updateMesh(this._current))
      .then(async () => await this.drawText());
  };

  public setText = async (
    text: Texts = this._text,
    textAlign: CanvasTextAlign = this._textAlign,
    textBaseline: CanvasTextBaseline = this._textBaseline,
    glyphWidth: number | undefined = this._glyphWidth,
    dimensions?: IDimensions,
  ): Promise<{ original: IDimensions; scaled: IDimensions }> => {
    this._text = text;
    this._textAlign = textAlign;
    this._textBaseline = textBaseline;
    this._glyphWidth = glyphWidth;

    return await Promise.resolve(
      this.resizeCanvas(
        this._canvas,
        this._canvasContext,
        textAlign,
        textBaseline,
        glyphWidth,
        dimensions,
      ),
    );
  };

  protected updateMesh = (state: IState) => {
    this._current = state;

    const {
      position: [pX, pY, pZ],
      rotation: [rX, rY, rZ],
      scale: [sX, sY, sZ],
      opacity: o,
    } = state;

    let _pX = pX;
    let _pY = pY;

    switch (this._textAlign) {
      case 'left':
      case 'start':
        _pX = pX + sX / 2;
        break;
      case 'right':
      case 'end':
        _pX = pX - sX / 2;
        break;
      case 'center':
      default:
    }

    switch (this._textBaseline) {
      case 'top':
        _pY = pY + sY / 2;
        break;
      case 'bottom':
        _pY = pY - sY / 2;
        break;
      case 'hanging':
      case 'middle':
      case 'alphabetic':
      case 'ideographic':
      default:
    }

    this.position.set(_pX, _pY, pZ);
    this.rotation.set(rX, rY, rZ);
    this.scale.set(sX, sY, sZ);
    (this.material as THREE.Material).opacity = o;
  };

  protected resizeCanvas = (
    canvas: HTMLCanvasElement = this._canvas,
    canvasContext: CanvasRenderingContext2D = this._canvasContext,
    textAlign: CanvasTextAlign,
    textBaseline: CanvasTextBaseline,
    glyphWidth?: number,
    dimensions?: IDimensions,
  ) => {
    const text = this.getText();

    const { height, width } = dimensions || {
      height: text.reduce((out, curr) => {
        return Math.max(out, curr.size);
      }, 0),
      width: (glyphWidth
        ? text.map(() => glyphWidth)
        : calculateTextOffsets(canvasContext, text, textAlign, textBaseline)
      ).reduce((out, curr) => out + curr, 0),
    };

    canvas.width = toCeilPower(width);
    canvas.height = toCeilPower(height);

    return {
      original: { width, height },
      scaled: { width: canvas.width, height: canvas.height },
    };
  };

  protected getText = (): Texts => {
    return this._text.reduce(
      (_out, curr) => [
        ..._out,
        ...curr.text.split('').map((l) => ({ ...curr, text: l })),
      ],
      [],
    );
  };

  protected drawText = async (
    instructions: Texts = this.getText(),
    range: [number, number] = [0, instructions.length],
    glyphWidth: number | undefined = this._glyphWidth,
  ) =>
    await new Promise<void>((resolve) => {
      if (
        this.material instanceof THREE.MeshBasicMaterial &&
        this.material.map instanceof THREE.Texture
      ) {
        this._canvasContext.clearRect(
          0,
          0,
          this._canvas.width,
          this._canvas.height,
        );

        // DEBUG
        // this._canvasContext.strokeStyle = 'lime';
        // this._canvasContext.strokeRect(0, 0, this._canvas.width, this._canvas.height);
        // DEBUG

        if (typeof glyphWidth === 'number') {
          drawMonoSpaceMultiPartText(
            this._canvasContext,
            instructions,
            this._textAlign,
            this._textBaseline,
            range,
            undefined,
            glyphWidth,
          );
        } else {
          drawMultiPartText(
            this._canvasContext,
            instructions,
            this._textAlign,
            this._textBaseline,
            range,
          );
        }

        this.material.map.needsUpdate = true;
        this.material.needsUpdate = true;
      }

      resolve();
    });
}
