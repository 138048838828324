import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { COLORS } from '../../../constants/colours';
import { timeEmpty, timeFormat, timeInvalid } from '../../../constants/texts';
import { FONTS } from '../../../enums/fonts';
import {
  adjustArrayValues,
  getSortData,
  type ISortable,
} from '../../../helpers/arrays';
import { templateTexts } from '../../../helpers/strings';
import { ISettings } from '../../../interfaces/app';
import { type ILoExScene } from '../../../interfaces/scene';
import { type ResultsStateScene } from '../../../interfaces/sceneState';
import { BallShaded } from '../../../models/ballShaded';
import { BoostText } from '../../../models/boostText';
import { ImagePlane } from '../../../models/imagePlane';
import { Scene, type ISceneMeshesBase } from '../../../models/scene';
import { Text } from '../../../models/text';
import { Timer } from '../../../models/timer';
import LayoutService from '../../../services/layout';
import { FeatureFlag } from '../../../enums/featureFlag';

interface ISceneMeshes extends ISceneMeshesBase {
  balls: BallShaded[];
  lastDraw?: Text;
  logo?: ImagePlane;
  extraBall?: BallShaded;
  nextDraw?: Text;
  nextDrawTimer?: Timer;
  boost?: ImagePlane;
  boostValue?: BoostText;
}

export default class ResultsScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: ResultsStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {
    balls: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: ResultsStateScene,
    settings: ISettings,
  ) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: {
              balls = [],
              extra,
              boost,
              nextDrawTime,
              lastDrawText,
              nextDrawText,
              id,
              logoImage,
              boostImage,
            },
          },
        },
      } = this;

      const currentResults = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballGridLayout();
      await new Promise((resolve) => setTimeout(resolve, 200));

      scene.add((meshes.extraBall = new BallShaded()));
      scene.add((meshes.nextDraw = new Text()));
      scene.add((meshes.nextDrawTimer = new Timer()));
      scene.add((meshes.lastDraw = new Text()));
      scene.add((meshes.logo = new ImagePlane()));
      scene.add((meshes.boost = new ImagePlane()));
      scene.add((meshes.boostValue = new BoostText()));

      const extraBallData = currentResults.find(
        (result: ISortable<number>) => result.value === extra,
      ) || { sortedIndex: 0, originalIndex: 0 };
      const extraBallPosition = ballLayout.positions[extraBallData.sortedIndex];

      return await Promise.all<any>([
        ...currentResults.map(async ({ value, sortedIndex }, i) => {
          scene.add((meshes.balls[i] = new BallShaded()));

          return await meshes.balls[i].configure({
            value,
            state: {
              position: adjustArrayValues(
                ballLayout.positions[sortedIndex],
                [0, 0, -1],
              ),
              rotation: [0, 0, 0],
              scale: ballLayout.scale,
              opacity: 0,
            },
            featureFlag: FeatureFlag.Default,
          });
        }),
        meshes.extraBall.configure({
          value: extra,
          isExtraBall: true,
          state: {
            position: adjustArrayValues(extraBallPosition, [0, 0, 0.005]),
            rotation: [0, 0, Math.PI * 8],
            scale: ballLayout.scale,
            opacity: 0,
          },
          featureFlag: FeatureFlag.Default,
        }),
        meshes.lastDraw.configure({
          text: templateTexts(lastDrawText, { id }),
          state: {
            position: [-4.1, -2.2, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.logo.configure({
          dimensions: { height: 256, width: 512 },
          urls: [logoImage],
          state: {
            position: [-3.25, 2, -3.5],
            scale: [2, 0.575, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.nextDraw.configure({
          text: nextDrawText,
          state: {
            position: [4.1, 2.2, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
        }),
        meshes.nextDrawTimer
          .configure({
            format: timeFormat,
            empty: timeEmpty,
            invalid: timeInvalid,
            state: {
              position: [4.1, 1.9, -3.5],
              scale: [0, 0.2, 1],
              rotation: [0, 0, 0],
              opacity: 0,
            },
            textAlign: 'right',
            // glyphWidth: 22 // NOTE: This can be enabled to force a non-jiggly timer
          })
          .then(
            async () =>
              await meshes.nextDrawTimer.setDate(new Date(nextDrawTime)),
          ),
        meshes.boost.configure({
          dimensions: { height: 128, width: 256 },
          urls: [boostImage],
          state: {
            position: [2.75, -2, -3.5],
            scale: [1.5, 0.5, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.boostValue.configure({
          dimensions: { height: 64, width: 128 },
          text: [
            {
              text: 'x',
              font: FONTS.AristaProBold,
              size: 50,
              color: COLORS.white,
              // fillStyle: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '2',
            },
            {
              text: `${boost}`,
              font: FONTS.AristaProBold,
              size: 64,
              color: COLORS.white,
              // fillStyle: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '2',
            },
          ],
          state: {
            position: [4.2, -1.975, -3.5],
            scale: [0, 0.4, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
          textBaseline: 'alphabetic',
        }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const {
        _meshes: meshes,
        _state: {
          meta: {
            [this._language]: { balls = [] },
          },
        },
      } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      const currentResults = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballGridLayout();

      return await Promise.all([
        meshes.nextDraw.tweenTo({ opacity: 1 }),
        meshes.nextDrawTimer
          .beginAutoRedraw()
          .then(async () => await meshes.nextDrawTimer.tweenTo({ opacity: 1 })),
        meshes.lastDraw.tweenTo({ opacity: 1 }),
        meshes.logo.tweenTo({ opacity: 1 }),
        meshes.boostValue.tweenTo({ opacity: 1 }),
        meshes.boost.tweenTo({ opacity: 1 }),
      ])
        .then(
          async () =>
            await Promise.all(
              currentResults.map(
                async (currentResult, i) =>
                  await meshes.balls[i].tweenTo(
                    {
                      position: ballLayout.positions[currentResult.sortedIndex],
                      opacity: 1,
                    },
                    1000,
                    currentResult.sortedIndex * 100,
                    TWEEN.Easing.Elastic.Out,
                  ),
              ),
            ),
        )
        .then(
          async () =>
            await meshes.extraBall.tweenTo(
              { rotation: [0, 0, 0], opacity: 1 },
              2000,
              0,
              TWEEN.Easing.Quadratic.Out,
            ),
        )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy())
          .catch(reject);
      }

      return await Promise.all<any>([
        meshes.nextDraw.tweenOut({ opacity: 0 }),
        meshes.nextDrawTimer.tweenOut({ opacity: 0 }),
        meshes.lastDraw.tweenOut({ opacity: 0 }),
        meshes.logo.tweenOut({ opacity: 0 }),
        meshes.extraBall
          ? meshes.extraBall.tweenOut({
              opacity: 0,
            })
          : Promise.resolve(),
        ...meshes.balls.map(
          async (ball) =>
            await ball.tweenOut({
              opacity: 0,
            }),
        ),
        meshes.boost.tweenOut({ opacity: 0 }),
        meshes.boostValue.tweenOut({ opacity: 0 }),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: ResultsStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
