import { FeatureFlag } from '../enums/featureFlag';
import ballFlatExtra2024 from './../assets/images/balls/2024/ball-flat-extra.svg';
import ballFlatNormal2024 from './../assets/images/balls/2024/ball-flat-normal.svg';
import ballShadedBackground2024 from './../assets/images/balls/2024/ball-shaded-background.png';
import ballShadedExtra2024 from './../assets/images/balls/2024/ball-shaded-extra.svg';
import ballShadedNormal2024 from './../assets/images/balls/2024/ball-shaded-normal.svg';

import ballFlatExtra from './../assets/images/balls/ball-flat-extra.png';
import ballFlatNormal from './../assets/images/balls/ball-flat-normal.png';
import ballShadedBackground from './../assets/images/balls/ball-shaded-background.png';
import ballShadedExtra from './../assets/images/balls/ball-shaded-extra.png';
import ballShadedNormal from './../assets/images/balls/ball-shaded-normal.png';

interface BallsUri {
  flatExtra: string;
  flatNormal: string;
  shadedBackground: string;
  shadedExtra: string;
  shadedNormal: string;
}

export const ballImageUri: Record<FeatureFlag, BallsUri> = {
  [FeatureFlag.Loex2024]: {
    flatExtra: ballFlatExtra2024,
    flatNormal: ballFlatNormal2024,
    shadedBackground: ballShadedBackground2024,
    shadedExtra: ballShadedExtra2024,
    shadedNormal: ballShadedNormal2024,
  },
  [FeatureFlag.Default]: {
    flatExtra: ballFlatExtra,
    flatNormal: ballFlatNormal,
    shadedBackground: ballShadedBackground,
    shadedExtra: ballShadedExtra,
    shadedNormal: ballShadedNormal,
  },
};
