import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { COLORS } from '../../../constants/colours';
import { timeEmpty, timeFormat, timeInvalid } from '../../../constants/texts';
import { FONTS } from '../../../enums/fonts';
import {
  adjustArrayValues,
  createArrayOfLength,
  getSortData,
} from '../../../helpers/arrays';
import {
  extractBallsFeatureFlag,
  getIsFeatureFlag2024,
} from '../../../helpers/featureFlag';
import { clamp } from '../../../helpers/maths';
import { templateTexts } from '../../../helpers/strings';
import { ISettings } from '../../../interfaces/app';
import { type ILoExScene } from '../../../interfaces/scene';
import { type BoostAdvertStateScene } from '../../../interfaces/sceneState';
import { BallFlat } from '../../../models/ballFlat';
import { BatteryPlane } from '../../../models/battery';
import { BoostText } from '../../../models/boostText';
import { GradientPlane } from '../../../models/gradientPlane';
import { ImagePlane } from '../../../models/imagePlane';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { Text } from '../../../models/text';
import { Timer } from '../../../models/timer';
import { TypewriterText } from '../../../models/typewriterText';
import LayoutService from '../../../services/layout';
import { getBannerColor } from '../../../helpers/bannerColor';

interface ISceneMeshes extends ISceneMeshesBase {
  balls: BallFlat[];
  battery?: BatteryPlane;
  boostLogoText?: Text;
  boostLogo?: ImagePlane;
  foreground?: GradientPlane;
  lastDraw?: Text;
  messages: TypewriterText[];
  nextDraw?: Text;
  nextDrawTimer?: Timer;
  boost?: ImagePlane;
  boostValue?: BoostText;
}

export default class BoostAdvertScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: BoostAdvertStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {
    balls: [],
    messages: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: BoostAdvertStateScene,
    settings: ISettings,
  ) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: {
              textLines,
              balls,
              extra,
              boost,
              bottomText,
              nextDrawTime,
              lastDrawText,
              nextDrawText,
              id,
              boostImage,
            },
          },
        },
      } = this;

      const currentResults = getSortData(balls ?? [], (a, b) => a - b);

      const ballLayout = LayoutService.ballLineLayout();
      const isFeatureFlag2024 = getIsFeatureFlag2024(
        extractBallsFeatureFlag(settings.featureFlags),
      );
      const bannerBallColor = getBannerColor(isFeatureFlag2024);

      scene.add((meshes.foreground = new GradientPlane()));
      scene.add((meshes.nextDraw = new Text()));
      scene.add((meshes.nextDrawTimer = new Timer()));
      scene.add((meshes.lastDraw = new Text()));
      scene.add((meshes.boostLogo = new ImagePlane()));
      scene.add((meshes.boostLogoText = new Text()));
      scene.add((meshes.battery = new BatteryPlane()));
      scene.add((meshes.boost = new ImagePlane()));
      scene.add((meshes.boostValue = new BoostText()));

      return await Promise.all<any>([
        ...textLines
          ?.map((x) => [x])
          .map(async (text, i, arr) => {
            meshes.messages[i] = new TypewriterText();

            return await meshes.messages[i]
              .configure({
                state: {
                  position: [0, 0.4 * -i + arr.length * 0.2 + 0.5, -3.5],
                  scale: [0, 0.4, 0.75],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
                text,
                textAlign: 'center',
                glyphWidth: 40,
              })
              .then(() => scene.add(meshes.messages[i]));
          }),
        ...createArrayOfLength(20).map(async (n, i) => {
          if (currentResults[i] && !meshes.balls[i]) {
            scene.add((meshes.balls[i] = new BallFlat()));

            return await meshes.balls[i].configure({
              value: currentResults[i].value,
              state: {
                position: adjustArrayValues(
                  ballLayout.positions[currentResults[i].sortedIndex],
                  [0, -0.75, 0],
                ),
                rotation: [0, 0, 0],
                scale: ballLayout.scale,
                opacity: 0,
              },
              isExtraBall: currentResults[i].value === extra,
              dimensions: {
                height: 64,
                width: 64,
              },
              featureFlag: extractBallsFeatureFlag(settings.featureFlags),
            });
          } else {
            return await Promise.resolve();
          }
        }),
        meshes.boostLogo.configure({
          dimensions: { height: 256, width: 512 },
          urls: [boostImage],
          state: {
            position: [0, 1.75, -3.5],
            scale: [3.5, 1.15, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.boostLogoText.configure({
          text: bottomText,
          state: {
            position: [0, -1.75, -3.5],
            scale: [0, 0.75, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'center',
        }),
        meshes.foreground.configure({
          stops: [
            [0, 'rgba(0, 0, 0, 0)'],
            [0.5, 'rgba(0, 0, 0, 0.3)'],
            [0.5, bannerBallColor],
            [1.0, bannerBallColor],
          ],
          dimensions: {
            height: 64,
            width: 8,
          },
          state: {
            position: [0, -1.85, -3.51],
            rotation: [0, 0, 0],
            scale: [8.92, 1.225, 1],
            opacity: 0,
          },
        }),
        meshes.lastDraw.configure({
          text: templateTexts(lastDrawText, { id }),
          state: {
            position: [-4.1, -1.6125, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.nextDraw.configure({
          text: nextDrawText,
          state: {
            position: [4.1, 2.2, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
        }),
        meshes.nextDrawTimer
          .configure({
            format: timeFormat,
            empty: timeEmpty,
            invalid: timeInvalid,
            state: {
              position: [4.1, 1.9, -3.5],
              scale: [0, 0.2, 1],
              rotation: [0, 0, 0],
              opacity: 0,
            },
            textAlign: 'right',
            // glyphWidth: 22 // NOTE: This can be enabled to force a non-jiggly timer
          })
          .then(
            async () =>
              await meshes.nextDrawTimer.setDate(new Date(nextDrawTime)),
          ),
        meshes.battery
          .configure({
            state: {
              position: [0, -1.5, -3.5],
              scale: [2.25, 0.75, 1],
              rotation: [0, 0, 0],
              opacity: 0,
            },
          })
          .then(async () => await meshes.battery.setValue(boost * 10)),
        meshes.boost.configure({
          dimensions: { height: 128, width: 256 },
          urls: [boostImage],
          state: {
            position: [3.1, -1.55, -3.5],
            scale: [1, 0.33, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.boostValue.configure({
          dimensions: { height: 64, width: 128 },
          text: [
            {
              text: 'x',
              font: FONTS.AristaProBold,
              size: 50,
              color: COLORS.white,
              // fillStyle: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '2',
            },
            {
              text: `${boost}`,
              font: FONTS.AristaProBold,
              size: 64,
              color: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '2',
            },
          ],
          state: {
            position: [4.1, -1.575, -3.5],
            scale: [0, 0.264, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
          textBaseline: 'alphabetic',
        }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const {
        _meshes: meshes,
        _state: {
          meta: {
            [this._language]: { balls },
          },
        },
      } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      const currentResults = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballLineLayout();

      return await Promise.all<any>([
        meshes.balls.map(
          async (mesh, i) =>
            await mesh.tweenTo(
              {
                position: ballLayout.positions[currentResults[i].sortedIndex],
                opacity: 1,
              },
              1000,
              currentResults[i].sortedIndex * 75,
              TWEEN.Easing.Quadratic.Out,
            ),
        ),
        meshes.foreground.tweenTo({ opacity: 1 }),
        meshes.lastDraw.tweenTo({ opacity: 1 }),
        meshes.nextDraw.tweenTo({ opacity: 1 }),
        meshes.nextDrawTimer
          .beginAutoRedraw()
          .then(async () => await meshes.nextDrawTimer.tweenTo({ opacity: 1 })),
        meshes.boostLogo.tweenTo(
          { opacity: 1, position: [0, 0.5, -3.5] },
          500,
          250,
          TWEEN.Easing.Back.Out,
        ),
        meshes.boostLogoText.tweenTo(
          { opacity: 1, position: [0, -0.5, -3.5] },
          500,
          500,
          TWEEN.Easing.Back.Out,
        ),
        meshes.boostValue.tweenTo({ opacity: 1 }),
        meshes.boost.tweenTo({ opacity: 1 }),
      ])
        .then(
          async () =>
            await Promise.all<any>([
              meshes.boostLogo.tweenOut(
                { opacity: 0, position: [-1, 0.5, -3.5] },
                500,
                1500,
                TWEEN.Easing.Quadratic.Out,
              ),
              meshes.boostLogoText.tweenOut(
                { opacity: 0, position: [1, -0.5, -3.5] },
                500,
                1500,
                TWEEN.Easing.Quadratic.Out,
              ),
            ]),
        )
        .then(
          async () =>
            await Promise.all<any>([
              meshes.boostLogo.changeTo({
                position: [-3.25, 2, -3.5],
                scale: [1.75, 0.575, 1],
              }),
            ]),
        )
        .then(
          async () =>
            await Promise.all<any>([
              meshes.boostLogo.tweenTo(
                { opacity: 1 },
                1000,
                0,
                TWEEN.Easing.Back.Out,
              ),
              meshes.battery.tweenTo(
                { opacity: 1, position: [0, -0.85, -3.5] },
                1000,
                0,
                TWEEN.Easing.Back.Out,
              ),
            ]),
        )
        .then(
          async () =>
            await meshes.messages.reduce(
              async (p, mesh) =>
                await p.then(async () => await mesh.animateInInterval(50)),
              Promise.resolve(),
            ),
        )
        .then(
          async () =>
            await Promise.all([
              meshes.messages
                .slice(-2)
                .map(
                  async (mesh) =>
                    await mesh.tweenTo({ opacity: 0 }, 2000, 500, (n) =>
                      clamp(Math.sin(n * (4 * Math.PI)), 0, 1),
                    ),
                ),
            ]),
        )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy());
      }

      await Promise.all<any>([
        ...meshes.balls.map(
          async (ball) => await ball?.tweenOut({ opacity: 0 }),
        ),
        ...meshes.messages.map(
          async (message) => await message.tweenOut({ opacity: 0 }),
        ),
        meshes.lastDraw.tweenOut({ opacity: 0 }),
        meshes.foreground.tweenOut({ opacity: 0 }),
        meshes.lastDraw.tweenOut({ opacity: 0 }),
        meshes.nextDraw.tweenOut({ opacity: 0 }),
        meshes.nextDrawTimer.tweenOut({ opacity: 0 }),
        meshes.boostLogo.tweenOut({ opacity: 0 }),
        meshes.battery.tweenOut({ opacity: 0 }),
        meshes.boost.tweenOut({ opacity: 0 }),
        meshes.boostValue.tweenOut({ opacity: 0 }),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: BoostAdvertStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
