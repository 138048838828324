import { DrawInProgress } from '../../../Common/DrawInProgress';
import { BoostSplash } from '../../../Common/BoostSplash';
import { IStateProps } from '../..';
import { BallGrid } from './BallGrid';
import { getIsChannelTV } from '../../../../helpers/getIsChannelTV';
import { AnimatedBallsMng } from './AnimatedBall';
import { createScene } from '../../../../helpers/createScene';
import { motion } from 'framer-motion-3d';

export type T3D = [number, number, number];

const DrawHTML = (props: IStateProps) => {
  return (
    <>
      <DrawInProgress scene={props.displayState} {...props} />
      <BoostSplash scene={props.displayState} {...props} />
    </>
  );
};

const Draw3D = (props: IStateProps) => {
  const isChannelTV = getIsChannelTV(props);
  return (
    isChannelTV && (
      <>
        <AnimatedBallsMng {...props} />
        <motion.group
          initial={{ z: -15, opacity: 0 }}
          animate={{ z: 0, opacity: 1 }}
          transition={{
            type: 'spring',
            duration: 1.1,
            bounce: 0.4,
          }}
        >
          <BallGrid />
        </motion.group>
      </>
    )
  );
};

export const DrawScene = createScene(DrawHTML, Draw3D);
