import * as THREE from 'three';
import { type Texture } from '../models/texture';

export class TextureService {
  private _textures: Record<string, Texture> = {};

  public getTexture = (name: string, factory: () => Texture): Texture => {
    if (this._textures[name] instanceof THREE.Texture) {
      return this._textures[name];
    } else {
      const texture = factory();

      if (texture.retain) {
        return (this._textures[name] = texture);
      } else {
        return texture;
      }
    }
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TextureService();
