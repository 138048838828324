import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { animate } from '../helpers/animate';
import { multiplyArrayValues } from '../helpers/arrays';
import { getCanvas } from '../helpers/canvas';
import ImageService from '../services/image';
import { CanvasTexture } from './canvasTexture';
import { Mesh } from './mesh';
import { type IState } from '../interfaces/scene';
import batteryBackgroundImage from './../assets/images/battery/battery-background.png';
import batteryBarImage from './../assets/images/battery/battery-bar.png';
import batteryForegroundImage from './../assets/images/battery/battery-foreground.png';

const resolution: number = 0.5;
const barBounds: [number, number, number, number] = multiplyArrayValues(
  [145, 45, 100, 45],
  [resolution, resolution, resolution, resolution],
);

export class BatteryPlane extends Mesh {
  private _canvas?: HTMLCanvasElement;
  private _canvasContext?: CanvasRenderingContext2D;

  private _images: {
    foreground: HTMLImageElement;
    bar: HTMLImageElement;
    background: HTMLImageElement;
  };

  public configure = async ({ state }: { state: IState }): Promise<void> => {
    this._previous = this._current = state;

    this.name = `BatteryPlane`;

    return await Promise.all([
      ImageService.getImage(batteryBackgroundImage),
      ImageService.getImage(batteryBarImage),
      ImageService.getImage(batteryForegroundImage),
    ])
      .then(([background, bar, foreground]) => {
        this._canvas = getCanvas(
          background.width * resolution,
          background.height * resolution,
        );
        this._canvasContext = this._canvas.getContext('2d')!;
        this._images = { foreground, bar, background };

        this.geometry = new THREE.PlaneGeometry(1, 1);
        this.material = new THREE.MeshBasicMaterial({
          color: 'white',
          map: new CanvasTexture(this._canvas),
          transparent: true,
        });
      })
      .then(async () => await this.updateTexture(0))
      .then(() => this.updateMesh(state));
  };

  public animateFromToValue = async (
    fromValue: number = 0,
    toValue: number = 100,
    duration?: number,
    delay: number = 0,
    easing: (k: number) => number = TWEEN.Easing.Linear.None,
  ) =>
    await new Promise<void>(async (resolve) => {
      return await this.updateTexture(fromValue).then(() =>
        animate([fromValue], [toValue], {
          complete: () => resolve(),
          delay,
          duration,
          easing,
          framerate: 25,
          update: async ([i]) => await this.updateTexture(i),
        }),
      );
    });

  public setValue = async (value: number) => await this.updateTexture(value);

  protected updateTexture = async (value: number) =>
    await new Promise<void>((resolve) => {
      const {
        _canvas: canvas,
        _canvasContext: context,
        _images: { background, bar, foreground },
      } = this;

      if (
        canvas instanceof HTMLCanvasElement &&
        context instanceof CanvasRenderingContext2D &&
        this.material instanceof THREE.MeshBasicMaterial &&
        this.material.map instanceof THREE.CanvasTexture
      ) {
        context.clearRect(0, 0, canvas.width, canvas.height);

        context.drawImage(background, 0, 0, canvas.width, canvas.height);
        context.drawImage(
          bar,
          barBounds[0],
          barBounds[1],
          ((canvas.width - barBounds[2] - barBounds[0]) / 100) * value,
          canvas.height - barBounds[3] - barBounds[1],
        );
        context.drawImage(foreground, 0, 0, canvas.width, canvas.height);

        this.material.map.needsUpdate = true;
        this.material.needsUpdate = true;
      }

      return resolve();
    });
}
