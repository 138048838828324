import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { ImagePlane } from '../../../models/imagePlane';
import { BoostText } from '../../../models/boostText';
import { TypewriterText } from '../../../models/typewriterText';
import { BatteryPlane } from '../../../models/battery';
import { FONTS } from '../../../enums/fonts';
import { COLORS } from '../../../constants/colours';
import { clamp } from '../../../helpers/maths';
import { type ILoExScene } from '../../../interfaces/scene';
import { type BoostStateScene } from '../../../interfaces/sceneState';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';

interface ISceneMeshes extends ISceneMeshesBase {
  boostLogo?: ImagePlane;
  boostValue?: BoostText;
  topText?: TypewriterText;
  bottomText?: TypewriterText;
  battery?: BatteryPlane;
}

export default class BoostScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: BoostStateScene;
  protected _meshes: ISceneMeshes = {};
  protected _language: string;

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (language: string, state: BoostStateScene) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: { topText, bottomText, boost, boostImage },
          },
        },
      } = this;

      scene.add((meshes.boostLogo = new ImagePlane()));
      scene.add((meshes.boostValue = new BoostText()));
      scene.add((meshes.topText = new TypewriterText()));
      scene.add((meshes.bottomText = new TypewriterText()));
      scene.add((meshes.battery = new BatteryPlane()));

      return await Promise.all([
        meshes.boostLogo.configure({
          dimensions: { height: 128, width: 256 },
          urls: [boostImage],
          state: {
            position: [-3.25, 2, -3.5],
            scale: [1.75, 0.575, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.boostValue.configure({
          dimensions: { height: 256, width: 512 },
          state: {
            position: [-10, 0, -3.5],
            scale: [0, 2.5, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          text: [
            {
              text: `x`,
              font: FONTS.AristaProBold,
              color: COLORS.white,
              size: 200,
              // fillStyle: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '6',
            },
            {
              text: `${boost}`,
              font: FONTS.AristaProBold,
              color: COLORS.white,
              size: 256,
              // fillStyle: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '6',
            },
          ],
          textAlign: 'center',
          textBaseline: 'alphabetic',
        }),
        meshes.topText.configure({
          state: {
            position: [0, -1, -3.5],
            scale: [0, 0.5, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          text: topText,
          textAlign: 'center',
        }),
        meshes.bottomText.configure({
          state: {
            position: [0, -1.55, -3.5],
            scale: [0, 0.5, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          text: bottomText,
          textAlign: 'center',
        }),
        meshes.battery.configure({
          state: {
            position: [0, 0.5, -3.5],
            scale: [5, 1.75, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const {
        _meshes: meshes,
        _state: {
          meta: {
            [this._language]: { boost },
          },
        },
      } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      return await Promise.all([
        meshes.boostLogo.tweenTo({ opacity: 1 }, 500),
        meshes.battery
          .tweenTo({ opacity: 1 }, 500)
          .then(
            async () =>
              await meshes.battery.animateFromToValue(
                0,
                100,
                1500,
                0,
                TWEEN.Easing.Quartic.Out,
              ),
          )
          .then(
            async () =>
              await meshes.battery.animateFromToValue(
                100,
                90,
                750,
                125,
                TWEEN.Easing.Quadratic.InOut,
              ),
          )
          .then(
            async () =>
              await meshes.battery.animateFromToValue(
                90,
                100,
                250,
                125,
                TWEEN.Easing.Quadratic.InOut,
              ),
          )
          .then(
            async () =>
              await meshes.battery.animateFromToValue(
                100,
                boost * 10,
                1500,
                250,
                TWEEN.Easing.Quadratic.InOut,
              ),
          ),
        meshes.topText
          .animateInInterval(75, 1000)
          .then(async () => await meshes.bottomText.animateInInterval(75))
          .then(
            async () =>
              await meshes.bottomText.tweenTo({ opacity: 0 }, 2000, 500, (n) =>
                clamp(Math.sin(n * (4 * Math.PI)), 0, 1),
              ),
          ),
      ])
        .then(
          async () =>
            await Promise.all([
              meshes.topText.tweenOut({ opacity: 0 }, 500),
              meshes.bottomText.tweenOut({ opacity: 0 }, 500),
              meshes.battery.tweenOut(
                { position: [8, 0.5, -3.5], opacity: 0 },
                750,
                0,
                TWEEN.Easing.Back.In,
              ),
            ]),
        )
        .then(
          async () =>
            await meshes.boostValue.tweenTo(
              { position: [0, 0, -3.5], opacity: 1 },
              2000,
            ),
        )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy());
      }

      Promise.all([
        meshes.boostLogo.tweenOut({ opacity: 0 }, 1000),
        meshes.boostValue.tweenOut(
          { position: [10, 0, -3.5], opacity: 0 },
          1000,
        ),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: BoostStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
