/**
 * Round a number to the specified number of places with an upward rounding bias
 *
 * @param value - The value to operate on
 * @param places - The number of places to round to
 */
export function toFixedCeil(value: number, places: number = 0): number {
  const big = Math.ceil(Number(`${value}e${places}`));

  return Number(`${big}e-${places}`);
}

/**
 * Round a number to the specified number of places with a normal rounding bias
 *
 * @param value - The value to operate on
 * @param places - The number of places to round to
 */
export function toFixed(value: number, places: number = 0): number {
  const big = Math.round(Number(`${value}e${places}`));

  return Number(`${big}e-${places}`);
}

/**
 * Round a number to the specified number of places with an downward rounding bias
 *
 * @param value - The value to operate on
 * @param places - The number of places to round to
 */
export function toFixedFloor(value: number, places: number = 0): number {
  const big = Math.floor(Number(`${value}e${places}`));

  return Number(`${big}e-${places}`);
}

/**
 * Add together an array of values
 *
 * @param values - The array to operate on
 */
export function addArray(values: number[]): number {
  return values.slice(1).reduce((out, value) => out + value, values[0]);
}

/**
 * Multiple an array of values
 *
 * @param values - The array to operate on
 */
export function multiplyArray(values: number[]): number {
  return values.slice(1).reduce((out, value) => out * value, values[0]);
}

/**
 *
 *
 * @param values - The array to operate on
 */
export function orArray(values: number[]): number {
  return values.slice(1).reduce((out, value) => out | value, values[0]);
}

/**
 * Check if the value is within the specified tolerance levels
 *
 * @param left - The left value
 * @param right - The right value
 * @param tolerance - The largest change allowed
 */
export function withinTolerance(
  left: number,
  right: number,
  tolerance: number,
): boolean {
  return Math.abs(left - right) <= tolerance;
}

/**
 * Returns a function that models the Gaussian function for x inputs
 *
 * @param height - the height of the curve
 * @param centre - the centre of the peak
 * @param width - the width of the curve
 */
export function gaussian(
  height: number,
  centre: number,
  width: number,
): (x: number) => number {
  return (x: number) =>
    height * Math.exp(-Math.pow(x - centre, 2) / (2 * Math.pow(width, 2)));
}

/**
 * Gets the number of decimal places in a number.
 *
 * @param num - The number to get decimal places from
 */
export function getDecimalPlaces(num: number): number {
  if (num.toString().includes('.')) {
    const value = String(num).match(/\.(\d+)$/);

    return value ? value[1].length : 0;
  }

  return 0;
}

export function toNumberOrDefault<T = undefined>(
  input: string | number,
  d?: T,
): number | T {
  return (isNaN(Number(input)) ? d : Number(input)) as T;
}

export function toNearest(input: number, values: number[]): number {
  return values.reduce(
    (prev, curr) =>
      Math.abs(curr - input) < Math.abs(prev - input) ? curr : prev,
    values[0],
  );
}

export const toFloorPower = (value: number, power: number = 2) =>
  Math.pow(power, Math.floor(Math.log(value) / Math.log(power)));

export const toNearestPower = (value: number, power: number = 2) =>
  Math.pow(power, Math.round(Math.log(value) / Math.log(power)));

export const toCeilPower = (value: number, power: number = 2) =>
  Math.pow(power, Math.ceil(Math.log(value) / Math.log(power)));

export const clamp = (value: number, min: number, max: number): number =>
  Math.max(Math.min(max, value), min);
