import moment from 'moment';
import 'moment/locale/de-ch';
import 'moment/locale/fr-ch';
import { toFixed } from './maths';

const momentLocale = 'en';

/**
 * Format a prize price using the specified parameters
 *
 * @param input - The value to format
 * @param decimalPlaces - Decimal places
 */
export function formatPrizeNumber(
  input: number,
  decimalPlaces: number,
): string {
  return formatNumber(
    input,
    decimalPlaces,
    undefined,
    undefined,
    undefined,
    true,
  );
}

/**
 * Format a number using the specified parameters
 *
 * @param input - The value to format
 * @param decimalPlaces - Decimal places
 * @param decimalDelimiter - Decimal delimiter
 * @param groupLength - Group length
 * @param groupDelimiter - Group delimiter
 * @param traillingZeroAfterDecimalPoint - Trailing Zero after deimal point
 */
export function formatNumber(
  input: number,
  decimalPlaces: number = 2,
  decimalDelimiter: string = '.',
  groupLength: number = 3,
  groupDelimiter: string = '’',
  traillingZeroAfterDecimalPoint = false,
): string {
  const re =
    '\\d(?=(\\d{' +
    groupLength +
    '})+' +
    (decimalPlaces > 0 ? '\\D' : '$') +
    ')';
  let num = toFixed(input || 0, ~~decimalPlaces).toFixed(
    Math.max(0, ~~decimalPlaces),
  );

  // Remove trailing zeros after the decimal point
  if (traillingZeroAfterDecimalPoint && decimalPlaces > 0) {
    num = `${+num}`;
  }

  return num
    .replace('.', decimalDelimiter)
    .replace(new RegExp(re, 'g'), '$&' + groupDelimiter);
}

/**
 * Format a number as a currency
 *
 * @param input - The value to format
 * @param currencySymbol - Currency symbol to use
 * @param decimalPlaces - Decimal places
 * @param decimalDelimiter - Decimal delimiter
 * @param groupLength - Group length
 * @param groupDelimiter - Group delimiter
 */
export function formatCurrency(
  input: number,
  currencySymbol: string = '',
  decimalPlaces: number = 2,
  decimalDelimiter: string = '.',
  groupLength: number = 3,
  groupDelimiter: string = '',
): string {
  if (input >= 0) {
    return `${currencySymbol}${formatNumber(input, decimalPlaces, decimalDelimiter, groupLength, groupDelimiter)}`;
  } else {
    return `-${currencySymbol}${formatNumber(input * -1, decimalPlaces, decimalDelimiter, groupLength, groupDelimiter)}`;
  }
}

export function formatToMoment(
  input: string,
  format: string,
  localeConfiguration?: moment.LocaleSpecification,
  locale: string = momentLocale,
): string {
  const m = moment(input);
  m.locale(locale);
  m.localeData().set(localeConfiguration);

  return m.format(format);
}

export function formatToMomentCalendar(
  input: string,
  localeConfiguration?: moment.LocaleSpecification,
  locale: string = momentLocale,
): string {
  const m = moment(input);
  m.locale(locale);
  m.localeData().set(localeConfiguration);

  return m.calendar();
}

/**
 * Format a date as a relative time string
 *
 * @param input Date to format
 */
export function formatDrawRelativeDate(language: string, input: Date): string {
  const localeCalendarSettings = localeCalendar[language.toLowerCase()];
  const drawDate = moment(input);
  return customMomentCalendarFormatUse(() =>
    drawDate.locale(language).calendar(localeCalendarSettings),
  );
}

const customMomentCalendarFormatUse = <T>(callback: () => T): T => {
  const defaultCalendarFormat = moment.calendarFormat;
  try {
    moment.calendarFormat = (date, now) =>
      customMomentCalendarFormat(defaultCalendarFormat)(date, moment());

    const result = callback();
    return result;
  } finally {
    moment.calendarFormat = defaultCalendarFormat;
  }
};

const customMomentCalendarFormat =
  (defaultCalendarFormat: (m: moment.Moment, now: moment.Moment) => string) =>
  (m: moment.Moment, now: moment.Moment) => {
    if (m.isBefore(now) || m.isSame(now)) {
      return 'beforeNow';
    }
    return defaultCalendarFormat(m.startOf('day'), now.startOf('day'));
  };

const localeCalendar: Record<string, moment.CalendarSpec> = {
  'fr-ch': {
    beforeNow: '[Tirage en cours]',
    sameDay: '[Ce soir]',
    nextDay: '[Demain]',
    nextWeek: '[Ce] dddd',
    sameElse: 'dddd DD MMMM',
  },

  'de-ch': {
    beforeNow: '[Ziehung Läuft]',
    sameDay: '[Diesen Aben]',
    nextDay: '[Morgen]',
    nextWeek: '[Diesen] dddd',
    sameElse: 'dddd DD MMMM',
  },
};
