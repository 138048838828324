/**
 * Generate a random string of the specified complexity and length
 *
 * @param complexity - The complexity to use
 * @param length - The length of the string to generate
 */
export function generateRandomString(
  complexity: number = 16,
  length: number = 8,
): string {
  return Math.random().toString(complexity).slice(-length);
}

/**
 * Generate a random integer within the specified range
 *
 * @param min - The minimum number to return
 * @param max - The maximum number to return
 */
export function generateRandomNumber(min: number, max: number): number {
  return Math.round(Math.random() * (max - min) + min);
}

/**
 * Generate a random float within the specified range
 *
 * @param min - The minimum number to return
 * @param max - The maximum number to return
 * @param precision - The precision of the float to return
 */
export function generateRandomFloat(
  min: number,
  max: number,
  precision: number = 20,
): number {
  // NOTE: iOS Safari has an issue with `toFixed` if the precision is over 20,
  // so it's capped to that here to prevent potential errors.
  return Number.parseFloat(
    (Math.random() * (max - min) + min).toFixed(Math.min(precision, 20)),
  );
}
