/**
 * A delayed timeout wrapped in a Promise.
 *
 * @param ms - how long the delay should occur by.
 */
export const wait = async (ms: number) =>
  await new Promise((resolve) => setTimeout(resolve, ms));

/**
 * A recursive reattempt that occurs at every given interval with a specified
 * amount of times this attempt should occur.
 *
 * @param operation - the method passed in that should be reattempted.
 * @param interval - the required time elapsed for when the reattempt should occur again.
 * @param retries - how many reattempts there should be.
 */
export const retryOperation = async <T = any>(
  operation: () => Promise<T>,
  interval: number,
  retries?: number,
): Promise<T> =>
  await new Promise(async (resolve, reject) => {
    return await operation()
      .then(resolve)
      .catch(async (reason: any) => {
        if (reason === false) {
          return reject(reason);
        } else if (!retries || retries - 1 > 0) {
          return await wait(interval)
            .then(
              retryOperation.bind(
                null,
                operation,
                interval,
                retries ? retries - 1 : undefined,
              ),
            )
            .then(resolve as () => T)
            .catch(reject);
        }

        return reject(reason);
      });
  });
