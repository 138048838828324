import { StyleSheet } from 'aphrodite/no-important';
import { COLORS } from '../../constants/colours';

export default StyleSheet.create({
  button: {
    display: 'inline-block',
    height: 88,
    width: 256,
    lineHeight: '88px',
    border: `2px solid ${COLORS.yellow}`,
    textAlign: 'center',
    marginRight: 10,
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    fontSize: 16,
    cursor: 'pointer',
    color: COLORS.yellow,
  },
  container: {
    marginTop: 30,
    padding: 10,
  },
  text: {
    margin: '0 10px 10px 0',
    fontFamily: 'sans-serif',
    letterSpacing: '2px',
    fontSize: 16,
    color: COLORS.black,

    ':not(:empty) > *': {
      margin: '0 1.5px',
    },
  },
  messages: {},
  message: {
    fontFamily: 'sans-serif',
    fontSize: 16,
    margin: '10px 0',
    color: COLORS.black,

    ':not(:last-child)': {
      borderBottom: '1px solid silver',
      marginBottom: '12px',
      paddingBottom: '12px',
    },
  },
  messageLine: {
    lineHeight: 1.25,
  },
  message_error: {
    color: '#ff4747',
  },
  message_warning: {
    color: '#ff9800',
  },
  message_log: {
    color: COLORS.black,
  },
  message_info: {
    color: COLORS.black,
  },
  title: {
    fontWeight: 'bold',

    ':after': {
      content: '" "',
    },
  },
  content: {},
  content_raw: {
    fontFamily: 'monospace',
    wordBreak: 'break-all',
  },
  raw: {
    color: 'silver',
  },
});

export const canvasAndDivContStyle: React.CSSProperties = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
};
export const htmlContStyle: React.CSSProperties = {
  position: 'absolute',
  transformOrigin: 'top left',
  top: '0px',
  left: '0px',
  width: '1280px',
  height: '720px',
  overflow: 'hidden',
};

export const canvasStyle = (backgroundUrl: string): React.CSSProperties => ({
  background: true
    ? '#00000000'
    : `url(${backgroundUrl}) no-repeat center center`,
  backgroundSize: '120%',
  width: '100%',
});
