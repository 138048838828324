import React from 'react';
import * as loexService from '../services/loexAnimation';
import * as loexAction from '../actions/featureFlag';
import { type IAppState, type IDispatchActionProps } from '../interfaces/app';
import { connect } from 'react-redux';
import { type Dispatch, bindActionCreators } from 'redux';
import { type FeatureFlag } from '../enums/featureFlag';

export const withConfigurationPreloader = (WrappedComponent) => {
  interface IStateProps {
    baseUrl: string;
    isFeatureFlagsReady: boolean;
  }

  type IDispatchProps = IDispatchActionProps<{
    saveFeatureFlags: typeof loexAction.saveFeatureFlags;
  }>;

  interface IProps extends IStateProps, IDispatchProps {}

  class ConfigurationPreloader extends React.PureComponent<IProps> {
    componentDidMount() {
      Promise.all([loexService.getFeatureFlags(this.props.baseUrl)]).then(
        ([featureFlags]) => {
          this.props.actions.saveFeatureFlags(featureFlags as FeatureFlag[]);
        },
      );
    }

    render() {
      const isReady = this.props.isFeatureFlagsReady;
      return !isReady ? <></> : <WrappedComponent {...this.props} />;
    }
  }

  return connect<IProps>(
    (state: IAppState) => ({
      baseUrl: state.settings.base,
      isFeatureFlagsReady: state.settings.isFeatureFlagsReady,
      actions: undefined,
    }),
    (dispatch: Dispatch): IDispatchProps => ({
      actions: bindActionCreators(
        {
          saveFeatureFlags: loexAction.saveFeatureFlags,
        },
        dispatch,
      ),
    }),
  )(ConfigurationPreloader);
};
