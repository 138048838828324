import { type IAppState } from '../interfaces/app';
import { type IAction } from '../types';
import apiReducers from './scene';
import languageReducers from './language';
import featureFlagReducers from './featureFlag';

const handlers = {
  ...apiReducers,
  ...languageReducers,
  ...featureFlagReducers,
};

export default function rootReducer(
  state: IAppState,
  action: IAction<any>,
): IAppState {
  return handlers[action.type]
    ? handlers[action.type](state, action.payload)
    : state;
}
