import PromiseService from './promise';

export class ImageService {
  private _images: Record<string, HTMLImageElement> = {};

  public prefetchImage = async (src: string) =>
    await PromiseService.getAvoidingRace<HTMLImageElement>(
      src,
      async () => await this.fetchImage(src),
    ).then((image) => (this._images[src] = image));

  public prefetchImages = async (images: Array<{ src: string }>) =>
    await Promise.all(
      images.map(async ({ src }) => await this.prefetchImage(src)),
    );

  public getImage = async (src: string): Promise<HTMLImageElement> =>
    await new Promise(async (resolve, reject) => {
      if (this._images[src]) {
        return resolve(this._images[src]);
      } else {
        return await this.prefetchImage(src).then(resolve).catch(reject);
      }
    });

  private readonly fetchImage = async (
    src: string,
  ): Promise<HTMLImageElement> =>
    await new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';

      const onLoaded = () => {
        image.removeEventListener('load', onLoaded);
        image.removeEventListener('error', onError);

        resolve(image);
      };

      const onError = (e: Event) => {
        image.removeEventListener('load', onLoaded);
        image.removeEventListener('error', onError);

        reject(e);
      };

      image.addEventListener('error', onError);
      image.addEventListener('load', onLoaded);

      image.src = src;
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ImageService();
