export interface SceneState<T = {}> {
  meta: Record<string, T>;
  scene: SceneType;
  duration: number;
  startTime: number;
  endTime: number;
  progress: number;
  backgroundImage?: string;
  backgroundVideo?: string;
}

export enum SceneType {
  Unknown = 'Unknown',
  Error = 'ErrorScene',
  Loading = 'LoadingScene',
  Attention = 'AttentionScene',
  Bango = 'BangoScene',
  Countdown = 'CountdownScene',
  BoostAdvert = 'BoostAdvertScene',
  Boost = 'BoostScene',
  Draw = 'DrawScene',
  Extra = 'ExtraScene',
  Lottery = 'LotteryScene',
  Logo = 'LogoScene',
  HappyWinners = 'HappyWinnersScene',
  Responsible = 'ResponsibleScene',
  Reorder = 'ReorderScene',
  Results = 'ResultsScene',
  StaticContent = 'StaticContentScene',
  Weather = 'WeatherScene',
  Night = 'NightModeScene',
  DidYouKnowScene = 'DidYouKnowScene',
}

export const SCENES_WITH_RESULTS_BANNER = [
  SceneType.StaticContent,
  SceneType.Lottery,
  SceneType.DidYouKnowScene,
  SceneType.HappyWinners,
  SceneType.Bango,
];

export type Texts = Text[];

export interface Text {
  text: string;
  font: string;
  size: number;
  color: string;
  bold?: boolean;
}

export interface LoadingStateScene extends SceneState<{}> {}

export interface AttentionStateScene
  extends SceneState<{
    topText: Texts;
    bottomText: Texts;
    logoImage: string;
  }> {}

export interface BangoItem {
  id: number;
  amount: number;
  fixedShareAmount: number;
}

interface INextDraw {
  nextDrawText?: Texts;
  nextDrawTime?: string;
}

interface ILastDraw {
  lastDrawText?: Texts;
  id?: number;
}

interface IBoost {
  boost?: number;
  boostImage?: string;
  bottomText?: Texts;
}

export interface IBaseMeta extends INextDraw, ILastDraw, IBoost {}

export interface IBangoMeta extends IBaseMeta {
  bangoFixedText?: Texts;
  bangoImage?: string;
  bangoJackpotText?: Texts;
  currencyText?: Texts;
  balls?: number[];
  extra?: number;
  bango?: BangoItem[];
}
export interface BangoStateScene extends SceneState<IBangoMeta> {}

export interface IExtraMeta extends IBaseMeta {
  extraImage: string;
  bottomText: Texts;
  id: number;
  balls: number[];
  extra: number;
  boost: number;
}
export interface ExtraStateScene extends SceneState<IExtraMeta> {}

export interface ErrorStateScene
  extends SceneState<{
    textLines: Texts;
  }> {}

export interface CountdownStateScene
  extends SceneState<{
    countdownImage: string;
  }> {}

export interface BoostAdvertStateScene
  extends SceneState<{
    lastDrawText: Texts;
    nextDrawText: Texts;
    boostImage: string;
    bottomText: Texts;
    textLines: Texts;
    id: number;
    balls: number[];
    extra: number;
    boost: number;
    nextDrawTime: string;
  }> {}

export interface BoostStateScene
  extends SceneState<{
    topText: Texts;
    bottomText: Texts;
    logoImage: string;
    boostImage: string;
    boost: number;
  }> {}

export interface DrawStateScene
  extends SceneState<{
    paddingLeft: number;
    paddingRight: number;
    boostImage: string;
    bottomText: Texts;
    logoImage: string;
    id: number;
    balls: number[];
    boost: number;
  }> {}

export interface LotteryStateScene
  extends SceneState<{
    lastDrawText: Texts;
    nextDrawText: Texts;
    backgroundImage: string;
    amountMultiplierText: Texts;
    id: number;
    balls: number[];
    extra: number;
    boost: number;
    nextDrawTime: string;
    jackpot: number;
    nextLotteryDrawTime: string;
    boostImage: string;
    bangoImage: string;
    backgroundImages: string[];
    decimalPlaces: number;
    backgroundVideo: string;
  }> {}

export interface LogoStateScene
  extends SceneState<{
    topText: Texts;
    bottomText: Texts;
    logoImage: string;
  }> {}

export interface HappyWinnersStateScene
  extends SceneState<{
    lastDrawText: Texts;
    nextDrawText: Texts;
    topText: Texts;
    middleText: Texts;
    bottomText: Texts;
    id: number;
    balls: number[];
    extra: number;
    boost: number;
    nextDrawTime: string;
    happyWinners: number;
    logoImage: string;
    boostImage: string;
  }> {}
export interface ResponsibleStateScene
  extends SceneState<{
    lastDrawText: Texts;
    nextDrawText: Texts;
    textLines: Texts;
    id: number;
    balls: number[];
    extra: number;
    boost: number;
    nextDrawTime: string;
    responsibleCircleImage: string;
    responsibleLogoImages: string[];
  }> {}

export interface ResultsStateScene
  extends SceneState<{
    lastDrawText: Texts;
    nextDrawText: Texts;
    id: number;
    balls: number[];
    extra: number;
    boost: number;
    nextDrawTime: string;
    logoImage: string;
    boostImage: string;
  }> {}

export interface StaticContentStateScene
  extends SceneState<{
    lastDrawText: Texts;
    nextDrawText: Texts;
    id: number;
    balls: number[];
    extra: number;
    boost: number;
    nextDrawTime: string;
    boostImage: string;
    backgroundImages: string[];
    backgroundVideo: string;
  }> {}

export interface WeatherStateScene
  extends SceneState<{
    lastDrawText: Texts;
    nextDrawText: Texts;
    weatherText: Texts;
    id: number;
    balls: number[];
    extra: number;
    boost: number;
    nextDrawTime: string;
    weather?: WeatherLocations;
    weatherMapImage: string;
    logoImage: string;
    boostImage: string;
    dayIconsImage: string;
    nightIconsImage: string;
  }> {}

interface WeatherLocations {
  forecastDateTime: string;
  locations: Record<string, LocationWeatherData | undefined>;
}

export interface LocationWeatherData {
  temperature: number;
  weatherCode: number;
}

export interface WeatherDisplayData {
  temperature: number;
  night: boolean;
  iconIndex: number;
}

export interface NightModeStateScene
  extends SceneState<{
    textLines: Texts;
    logoImage: string;
    backgroundImages: string[];
  }> {}
