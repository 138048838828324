import * as THREE from 'three';
import { calculateTextOffsets, drawMultiPartText } from '../helpers/canvas';
import { type Texts } from '../interfaces/sceneState';
import ImageService from '../services/image';
import { Text } from './text';
import linesImage from './../assets/images/lines.png';

export class BoostText extends Text {
  protected drawText = async (
    instructions: Texts = this.getText(),
    range: [number, number] = [0, instructions.length],
    glyphWidth: number | undefined = this._glyphWidth,
  ) =>
    await new Promise<void>((resolve) => {
      if (this.material instanceof THREE.MeshBasicMaterial) {
        ImageService.getImage(linesImage)
          .then((image) => {
            if (
              this.material instanceof THREE.MeshBasicMaterial &&
              this.material.map instanceof THREE.Texture
            ) {
              this._canvasContext.clearRect(
                0,
                0,
                this._canvas.width,
                this._canvas.height,
              );

              // DEBUG
              // this._canvasContext.strokeStyle = 'lime';
              // this._canvasContext.strokeRect(0, 0, this._canvas.width, this._canvas.height);
              // DEBUG
              const width = calculateTextOffsets(
                this._canvasContext,
                instructions,
                'right',
                'alphabetic',
              ).reduce((out, i) => out - i, this._canvas.width);

              this._canvasContext.drawImage(
                image,
                width - this._canvas.width / 3 - 10,
                this._canvas.height / 2.75,
                this._canvas.width / 3,
                this._canvas.height / 2,
              );

              drawMultiPartText(
                this._canvasContext,
                instructions,
                'right',
                'alphabetic',
                range,
                [0, -(this._canvas.height / 5)],
              );

              this.material.map.needsUpdate = true;
              this.material.needsUpdate = true;
            }
          })
          .then(() => resolve());
      }
    });
}
