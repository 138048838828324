import React from 'react';
import { FONTS } from '../../../../enums/fonts';
import { COLORS } from '../../../../constants/colours';

export const HappyWinnersStyle = (withWinAmount: boolean) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '0 6em',
    flexDirection: withWinAmount ? 'column' : 'row',
  } as React.CSSProperties;
};
export const HappyWinnersValueStyle = (withWinAmount: boolean) => {
  return {
    fontFamily: FONTS.BarlowCondensedBold,
    fontWeight: 'semibold',
    color: COLORS.yellow2024,
    fontSize: withWinAmount ? 200 : 260,
  } as React.CSSProperties;
};
export const HappyWinnersContentWinAmountStyle = (bottom: number) => {
  return {
    position: 'relative',
    bottom: `${bottom}px`,
  } as React.CSSProperties;
};
export const HappyWinnersContentStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textTransform: 'uppercase',
  paddingLeft: '0.5em',
  lineHeight: '1.1',
} as React.CSSProperties;
