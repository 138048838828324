import { useState, useEffect } from 'react';
import { APP_WIDTH } from '../Loex2024Canvas';

export const useHandleResize = () => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const newScale = window.visualViewport?.width / APP_WIDTH;
      setScale(newScale);
    };

    window.addEventListener('resize', handleResize);

    // Initial calculation
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return scale;
};
