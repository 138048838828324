import React from 'react';
import { COLORS } from '../../../../constants/colours';
import { FONTS } from '../../../../enums/fonts';

export const JackpotDisplayContainerStyle: React.CSSProperties = {
  position: 'absolute',
  top: '30px',
  left: '50px',
  width: '1000px',
  display: 'flex',
  justifyContent: 'space-between',
  height: '63%',
  flexDirection: 'column',
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontWeight: 'semibold',
  fontSize: '30px',
  lineHeight: '37px',
  letterSpacing: '0px',
  color: COLORS.yellow2024,
};
export const bangoHeaderDisplayStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'right',
  color: COLORS.white,
  paddingTop: '45px',
};
export const spanPotStyle: React.CSSProperties = {
  position: 'absolute',
  right: 'calc(480px + 2ch)',
};
export const spanLotoExpressStyle: React.CSSProperties = {
  position: 'absolute',
  right: 'calc(40px + 2ch)',
};
export const bangoLineContainerStyle = {
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontWeight: 'semibold',
  fontSize: '59px',
  lineHeight: '72px',
  letterSpacing: '1.48px',
  display: 'flex',
  alignItems: 'center',
};
export const BangoLineStyle: React.CSSProperties = {
  position: 'absolute',
  display: 'flex',
  left: '0px',
  justifyContent: 'center',
  width: '200px',
};
export const spanIDStyle = {
  color: 'white',
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontWeight: 'semibold',
  fontSize: '44px',
  lineHeight: '52px',
};
export const spanShuffleStyle: React.CSSProperties = {
  position: 'absolute',
  right: '480px',
};
export const spanPlusStyle: React.CSSProperties = {
  position: 'absolute',
  right: '391px',
};
export const spanJackpotStyle: React.CSSProperties = {
  position: 'absolute',
  right: '40px',
};
