import * as THREE from 'three';
import { animate } from '../helpers/animate';
import { destroyMesh } from '../helpers/canvas';
import { type IState } from '../interfaces/scene';
import { type DeepPartial } from '../types';

export class Mesh extends THREE.Mesh {
  protected _previous: IState;
  protected _current: IState;

  constructor(
    geometry?: THREE.BufferGeometry,
    material?: THREE.Material | THREE.Material[],
  ) {
    super(geometry, material);
    // Initialize _current with default values
    this._current = {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [1, 1, 1],
      opacity: 1,
    };
    this._previous = { ...this._current };
  }

  public changeTo = async (
    state: Partial<IState>,
    duration: number = 0,
    delay: number = 0,
    easing: (k: number) => number = (k: number) => 0,
    force: boolean = false,
  ) =>
    await new Promise<void>((resolve) => {
      if (
        force ||
        !this.hasChanged(this._current, { ...this._current, ...state })
      ) {
        return resolve();
      }

      this._previous = { ...this._current };
      this._current = { ...this._current, ...state };

      this.updateMesh(this._current);

      return resolve();
    });

  public forceTo = async (
    state: Partial<IState>,
    duration: number = 0,
    delay: number = 0,
    easing: (k: number) => number = (k: number) => 0,
  ) => await this.changeTo(state, duration, delay, easing, true);

  public changeBy = async (
    state: DeepPartial<IState>,
    duration?: number,
    delay: number = 0,
    easing?: (k: number) => number,
    force?: boolean,
  ) => {
    const {
      position: [pX1, pY1, pZ1],
      rotation: [rX1, rY1, rZ1],
      scale: [sX1, sY1, sZ1],
      opacity: o1,
    } = this._current;
    const {
      position: [pX2 = 0, pY2 = 0, pZ2 = 0] = [0, 0, 0],
      rotation: [rX2 = 0, rY2 = 0, rZ2 = 0] = [0, 0, 0],
      scale: [sX2 = 0, sY2 = 0, sZ2 = 0] = [0, 0, 0],
      opacity: o2 = 0,
    } = state;

    return await this.changeTo(
      {
        position: [pX1 + pX2, pY1 + pY2, pZ1 + pZ2],
        rotation: [rX1 + rX2, rY1 + rY2, rZ1 + rZ2],
        scale: [sX1 + sX2, sY1 + sY2, sZ1 + sZ2],
        opacity: o1 + o2,
      },
      duration,
      delay,
      easing,
      force,
    );
  };

  public tweenTo = async (
    state: Partial<IState>,
    duration?: number,
    delay: number = 0,
    easing?: (k: number) => number,
  ) =>
    await new Promise<void>((resolve, reject) => {
      try {
        if (!this._current) {
          console.warn('No current state to tween from');
          return resolve();
        }

        this._previous = { ...this._current };
        const {
          position: [pX1, pY1, pZ1],
          rotation: [rX1, rY1, rZ1],
          scale: [sX1, sY1, sZ1],
          opacity: o1,
        } = this._previous;

        const {
          position: [pX2, pY2, pZ2],
          rotation: [rX2, rY2, rZ2],
          scale: [sX2, sY2, sZ2],
          opacity: o2,
        } = { ...this._current, ...state };

        animate(
          [pX1, pY1, pZ1, rX1, rY1, rZ1, sX1, sY1, sZ1, o1],
          [pX2, pY2, pZ2, rX2, rY2, rZ2, sX2, sY2, sZ2, o2],
          {
            complete: () => resolve(),
            delay,
            duration,
            easing,
            update: ([pX, pY, pZ, rX, rY, rZ, sX, sY, sZ, o]) =>
              this.updateMesh({
                position: [pX, pY, pZ],
                rotation: [rX, rY, rZ],
                scale: [sX, sY, sZ],
                opacity: o,
              }),
          },
        );
      } catch (error) {
        console.error('Error in tweenTo:', error);
        reject(error);
      }
    });

  public zoomIn = async (
    multiply: number,
    duration?: number,
    delay: number = 0,
    easing?: (k: number) => number,
  ) => {
    const {
      position: [pX1, pY1, pZ1],
      scale: [sX1, sY1, sZ1],
    } = this._current;

    return await this.tweenTo(
      {
        ...this._current,
        position: [pX1 * multiply, pY1 * multiply, pZ1],
        scale: [sX1 * multiply, sY1 * multiply, sZ1],
      },
      duration,
      delay,
      easing,
    );
  };

  public tweenBy = async (
    state: DeepPartial<IState>,
    duration?: number,
    delay: number = 0,
    easing?: (k: number) => number,
  ): Promise<void> => {
    const {
      position: [pX1, pY1, pZ1],
      rotation: [rX1, rY1, rZ1],
      scale: [sX1, sY1, sZ1],
      opacity: o1,
    } = this._current;
    const {
      position: [pX2 = 0, pY2 = 0, pZ2 = 0] = [0, 0, 0],
      rotation: [rX2 = 0, rY2 = 0, rZ2 = 0] = [0, 0, 0],
      scale: [sX2 = 0, sY2 = 0, sZ2 = 0] = [0, 0, 0],
      opacity: o2 = 0,
    } = state;

    return await this.tweenTo(
      {
        position: [pX1 + pX2, pY1 + pY2, pZ1 + pZ2],
        rotation: [rX1 + rX2, rY1 + rY2, rZ1 + rZ2],
        scale: [sX1 + sX2, sY1 + sY2, sZ1 + sZ2],
        opacity: o1 + o2,
      },
      duration,
      delay,
      easing,
    );
  };

  public tweenOut = async (
    state: Partial<IState> = {},
    duration?: number,
    delay: number = 0,
    easing?: (k: number) => number,
  ): Promise<void> => {
    try {
      if (!this._current) {
        console.warn('No current state to tween from in tweenOut');
        return;
      }
      await this.tweenTo(
        {
          ...this._current,
          ...state,
          opacity: 0,
        },
        duration,
        delay,
        easing,
      );
    } catch (error) {
      console.error('Error in tweenOut:', error);
      throw error;
    }
  };

  public show = async () => await this.changeTo({ opacity: 1 });

  public hide = async () => await this.changeTo({ opacity: 0 });

  public destroy = async () => await destroyMesh(this);

  public getMaterials = (): THREE.Material[] => {
    if (Array.isArray(this.material)) {
      return this.material;
    } else if (this.material instanceof THREE.Material) {
      return [this.material];
    } else {
      return [];
    }
  };

  protected updateMesh = (state: IState) => {
    this._current = state;

    const {
      position: [pX, pY, pZ],
      rotation: [rX, rY, rZ],
      scale: [sX, sY, sZ],
      opacity: o,
    } = state;

    this.getMaterials().forEach((m) => (m.opacity = o));
    this.position.set(pX, pY, pZ);
    this.rotation.set(rX, rY, rZ);
    this.scale.set(sX, sY, sZ);
  };

  protected hasChanged = (previous: IState, current: IState) => {
    return (
      previous.position[0] !== current.position[0] ||
      previous.position[1] !== current.position[1] ||
      previous.position[2] !== current.position[2] ||
      previous.scale[0] !== current.scale[0] ||
      previous.scale[1] !== current.scale[1] ||
      previous.scale[2] !== current.scale[2] ||
      previous.rotation[0] !== current.rotation[0] ||
      previous.rotation[1] !== current.rotation[1] ||
      previous.rotation[2] !== current.rotation[2] ||
      previous.opacity !== current.opacity
    );
  };
}
