import React from 'react';

export const sizeContainer: React.CSSProperties = {
  width: 555,
  height: 555,
  position: 'relative',
};
export const countDownContainer: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};
