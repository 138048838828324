import { SCENE_ACTIONS } from '../enums/actions';
import { ISettings, IUIState } from '../interfaces/app';
import { SceneState } from '../interfaces/sceneState';
import { IAction } from '../types';
// import MOCK_SCENE from './mock/mock_logoscene.json';
export const saveCurrentState = (
  currentState?: SceneState<{}>,
  uiState?: Partial<IUIState>,
  settings?: Partial<ISettings>,
): IAction<{
  currentState?: SceneState<{}>;
  uiState?: Partial<IUIState>;
  settings?: Partial<ISettings>;
}> => ({
  type: SCENE_ACTIONS.SAVE_CURRENT_STATE,
  payload: {
    currentState, //: MOCK_SCENE['arguments'][0] as unknown as SceneState<{}>,
    uiState,
    settings,
  },
});
