import { applyMiddleware, createStore, type Store } from 'redux';
import { thunk } from 'redux-thunk';
import { INITIAL_STATE } from '../constants/state';
import { type IAppState } from '../interfaces/app';
import rootReducer from '../reducers';

export function configureStore(
  initialState: IAppState = INITIAL_STATE,
): Store<IAppState> {
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;
  const devToolsExtensionCompose = (window as any)
    .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  const middleware = applyMiddleware(thunk);

  const store: Store<IAppState> = createStore(
    rootReducer,
    initialState,
    devToolsExtension && devToolsExtensionCompose
      ? devToolsExtensionCompose(middleware)
      : middleware,
  );

  return store;
}

export default configureStore();
