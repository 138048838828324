import { type saveCurrentState } from '../actions/scene';
import { SCENE_ACTIONS } from '../enums/actions';
import { type IAppState } from '../interfaces/app';
import { type ActionOutput } from '../types';

const saveCurrState = {
  [SCENE_ACTIONS.SAVE_CURRENT_STATE]: saveCurrentSceneReducer,
};
export default saveCurrState;

function saveCurrentSceneReducer(
  state: IAppState,
  {
    currentState,
    uiState,
    settings = {},
  }: ActionOutput<typeof saveCurrentState>,
): IAppState {
  return {
    ...state,
    currentState: {
      ...currentState,
    },
    uiState: {
      ...state.uiState,
      ...uiState,
    },
    settings: {
      ...state.settings,
      ...settings,
    },
  };
}
