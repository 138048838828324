import React from 'react';
import { IStateProps } from '../../App';

export interface IContent extends IStateProps {
  backgroundVideo?: string;
  backgroundImages?: string[];
}

export const MediaComponent: React.FC<IContent> = React.memo(
  ({ backgroundVideo, backgroundImages }) => {
    if (!backgroundVideo && !backgroundImages) return null;

    if (backgroundVideo) {
      return (
        <video
          src={backgroundVideo}
          style={{
            zIndex: -1,
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          muted
          loop
          playsInline
          autoPlay
          preload="auto"
        />
      );
    }

    if (backgroundImages && backgroundImages.length > 0) {
      return (
        <img
          style={{
            zIndex: -1,
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          src={backgroundImages[0]}
          alt="background"
        />
      );
    }
  },
);
