export const sendMessage = (type: string, data: any) => {
  if (!isRunningInIframe()) {
    return;
  }

  if (process.env.REACT_APP_POSTMESSAGE_TARGET_ORIGIN) {
    window.parent.postMessage(
      {
        type,
        data,
      },
      process.env.REACT_APP_POSTMESSAGE_TARGET_ORIGIN,
    );
  }
};

const isRunningInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
