import boost from '../../../assets/boost.svg';
import redSplash from '../../../assets/redSplash.svg';
import {
  IBaseMeta,
  SCENES_WITH_RESULTS_BANNER,
  SceneType,
} from '../../../interfaces/sceneState';
import { useRescale } from '../../App/hooks/useRescale';
import boost1 from '../../../assets/images/boosts/boost_1.svg';
import boost1dot5 from '../../../assets/images/boosts/boost_1dot5.svg';
import boost2 from '../../../assets/images/boosts/boost_2.svg';
import boost3 from '../../../assets/images/boosts/boost_3.svg';
import boost4 from '../../../assets/images/boosts/boost_4.svg';
import boost5 from '../../../assets/images/boosts/boost_5.svg';
import boost10 from '../../../assets/images/boosts/boost_10.svg';
import { getIsChannelTV } from '../../../helpers/getIsChannelTV';
import { IStateProps } from '../../App';
import { getMeta } from '../../../helpers/getMeta';

const boostContainerStyle = (bottomOffset: boolean) => {
  return {
    position: 'absolute',
    bottom: bottomOffset ? '75px' : '0px',
    right: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties;
};
const ImgSplashStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: '0px',
  right: '0px',
};
const ImgBoostStyle: React.CSSProperties = {
  position: 'relative',
  bottom: '15px',
  right: '25px',
  zIndex: 1,
};

interface IBContent extends IBaseMeta, IStateProps {
  scene?: SceneType;
  withSplash?: boolean;
}

export const BoostSplash = ({
  withSplash = false,
  scene,
  ...props
}: IBContent) => {
  const scaleS = useRescale();
  const meta = getMeta(props);
  const isChannelTV = getIsChannelTV(props);
  const bottomOffset =
    isChannelTV && SCENES_WITH_RESULTS_BANNER.includes(scene);
  return (
    <div style={{ ...boostContainerStyle(bottomOffset), ...scaleS }}>
      {withSplash && (
        <img src={redSplash} width={'252px'} style={ImgSplashStyle} alt="" />
      )}
      <img
        src={retrieveBoostMultiplierImage(meta?.boost ?? 0)}
        width={'175px'}
        style={ImgBoostStyle}
        alt={`x ${meta?.boost ?? 0}`}
      />
    </div>
  );
};

const retrieveBoostMultiplierImage = (boostValue: number) => {
  switch (boostValue) {
    case 1:
      return boost1;
    case 1.5:
      return boost1dot5;
    case 2:
      return boost2;
    case 3:
      return boost3;
    case 4:
      return boost4;
    case 5:
      return boost5;
    case 10:
      return boost10;
    default:
      return boost;
  }
};
