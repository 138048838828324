import * as TWEEN from '@tweenjs/tween.js';

export interface IAnimateOptions<T> {
  delay?: number;
  duration?: number;
  framerate?: number;
  easing?: (k: number) => number;
  update?: (u: T) => void;
  complete?: (done: boolean) => void;
}

export const animate = <T extends number[] | Record<string, number> = number[]>(
  from: T,
  to: T,
  {
    delay = 0,
    duration = 1000,
    easing = TWEEN.Easing.Quadratic.InOut,
    framerate,
    update = () => {
      /* NO-OP */
    },
    complete = () => {
      /* NO-OP */
    },
  }: IAnimateOptions<T>,
) => {
  let animationFrameRequest: number | void;

  const onTick = (time: number) => {
    if (tween instanceof TWEEN.Tween && tween.update(time)) {
      if (framerate) {
        setTimeout(
          () => (animationFrameRequest = requestAnimationFrame(onTick)),
          1000 / framerate,
        );
      } else {
        animationFrameRequest = requestAnimationFrame(onTick);
      }
    } else {
      animationFrameRequest = cancelAnimationFrame(
        animationFrameRequest as number,
      );
    }
  };

  const tween = new TWEEN.Tween(from)
    .delay(delay)
    .to(to, duration)
    .easing(easing)
    .onUpdate((u: T) => update(u))
    .onComplete((a) => {
      animationFrameRequest = cancelAnimationFrame(
        animationFrameRequest as number,
      );
      TWEEN.remove(tween);
      complete(true);
    })
    .onStop(() => {
      animationFrameRequest = cancelAnimationFrame(
        animationFrameRequest as number,
      );
      TWEEN.remove(tween);
      complete(false);
    })
    .start();

  animationFrameRequest = requestAnimationFrame(onTick);

  return tween;
};
