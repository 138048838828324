import { StyleSheet } from 'aphrodite/no-important';
import { COLORS } from '../../constants/colours';
import * as Fonts from '../../helpers/fonts';
import { aspectRatio } from '../../helpers/styles';
import { FONTS } from '../../enums/fonts';

export default StyleSheet.create({
  container: {
    ...aspectRatio(16, 9),
  },

  canvas: {
    background: `linear-gradient(to bottom, ${COLORS.bgGradientTop}, ${COLORS.bgGradientBottom});`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  fonts: {
    position: 'absolute',
    top: '-100%',
  },
  ...Object.keys(FONTS).reduce(
    (out, font) => ({
      ...out,
      [`fonts_${font}`]: {
        ...Fonts[font],
        fontSize: '32px',
        fontFamily: [Fonts[font]],
        color: 'rgba(0, 0, 0, 0)',
      },
    }),
    {},
  ),
});
