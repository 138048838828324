import { FeatureFlag } from '../enums/featureFlag';

export const extractBallsFeatureFlag = (
  featureFlags: FeatureFlag[] | undefined,
): FeatureFlag => {
  return featureFlags?.includes(FeatureFlag.Loex2024)
    ? FeatureFlag.Loex2024
    : FeatureFlag.Default;
};

export const extractSceneFeatureFlag = (
  featureFlags: FeatureFlag[] | undefined,
): FeatureFlag => {
  return featureFlags?.includes(FeatureFlag.Loex2024)
    ? FeatureFlag.Loex2024
    : FeatureFlag.Default;
};

export const getIsFeatureFlag2024 = (
  featureFlag: FeatureFlag | undefined,
): boolean => {
  return featureFlag === FeatureFlag.Loex2024;
};
