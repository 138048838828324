import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { COLORS } from '../../../constants/colours';
import { timeEmpty, timeFormat, timeInvalid } from '../../../constants/texts';
import { FONTS } from '../../../enums/fonts';
import {
  adjustArrayValues,
  createArrayOfLength,
  getSortData,
} from '../../../helpers/arrays';
import {
  extractBallsFeatureFlag,
  getIsFeatureFlag2024,
} from '../../../helpers/featureFlag';
import { templateTexts } from '../../../helpers/strings';
import { ISettings } from '../../../interfaces/app';
import { type ILoExScene } from '../../../interfaces/scene';
import { type StaticContentStateScene } from '../../../interfaces/sceneState';
import { BallFlat } from '../../../models/ballFlat';
import { BoostText } from '../../../models/boostText';
import { GradientPlane } from '../../../models/gradientPlane';
import { ImagePlane } from '../../../models/imagePlane';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { Text } from '../../../models/text';
import { Timer } from '../../../models/timer';
import LayoutService from '../../../services/layout';
import { getBannerColor } from '../../../helpers/bannerColor';

interface ISceneMeshes extends ISceneMeshesBase {
  background?: ImagePlane;
  balls: BallFlat[];
  foreground?: GradientPlane;
  lastDraw?: Text;
  nextDraw?: Text;
  nextDrawTimer?: Timer;
  boost?: ImagePlane;
  boostValue?: BoostText;
}

export default class StaticContentScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: StaticContentStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {
    balls: [],
    messages: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: StaticContentStateScene,
    settings: ISettings,
  ) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: {
              balls = [],
              extra,
              boost,
              nextDrawTime,
              lastDrawText,
              nextDrawText,
              id,
              boostImage,
              backgroundImages,
            },
          },
        },
      } = this;

      const currentResults = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballLineLayout();
      const isFeatureFlag2024 = getIsFeatureFlag2024(
        extractBallsFeatureFlag(settings.featureFlags),
      );
      const bannerBallColor = getBannerColor(isFeatureFlag2024);

      scene.add((meshes.background = new ImagePlane()));
      scene.add((meshes.foreground = new GradientPlane()));
      scene.add((meshes.nextDraw = new Text()));
      scene.add((meshes.nextDrawTimer = new Timer()));
      scene.add((meshes.lastDraw = new Text()));
      scene.add((meshes.boost = new ImagePlane()));
      scene.add((meshes.boostValue = new BoostText()));

      return await Promise.all<any>([
        ...createArrayOfLength(20).map(async (n, i) => {
          if (currentResults[i] && !meshes.balls[i]) {
            scene.add((meshes.balls[i] = new BallFlat()));

            return await meshes.balls[i].configure({
              value: currentResults[i].value,
              state: {
                position: adjustArrayValues(
                  ballLayout.positions[currentResults[i].sortedIndex],
                  [0, -0.75, 0],
                ),
                rotation: [0, 0, 0],
                scale: ballLayout.scale,
                opacity: 0,
              },
              isExtraBall: currentResults[i].value === extra,
              dimensions: {
                height: 64,
                width: 64,
              },
              featureFlag: extractBallsFeatureFlag(settings.featureFlags),
            });
          } else {
            return await Promise.resolve();
          }
        }),
        meshes.background.configure({
          urls: backgroundImages,
          state: {
            position: [0, 0, -3.511],
            rotation: [0, 0, 0],
            scale: [8.92, 5.0175, 1],
            opacity: 0,
          },
        }),
        meshes.foreground.configure({
          stops: [
            [0, 'rgba(0, 0, 0, 0)'],
            [0.5, 'rgba(0, 0, 0, 0.6)'],
            [0.5, bannerBallColor],
            [1.0, bannerBallColor],
          ],
          dimensions: {
            height: 64,
            width: 8,
          },
          state: {
            position: [0, -1.85, -3.51],
            rotation: [0, 0, 0],
            scale: [8.92, 1.225, 1],
            opacity: 0,
          },
        }),
        meshes.lastDraw.configure({
          text: templateTexts(lastDrawText, { id }),
          state: {
            position: [-4.1, -1.6125, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.nextDraw.configure({
          text: nextDrawText,
          state: {
            position: [4.1, 2.2, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
        }),
        meshes.nextDrawTimer
          .configure({
            format: timeFormat,
            empty: timeEmpty,
            invalid: timeInvalid,
            state: {
              position: [4.1, 1.9, -3.5],
              scale: [0, 0.2, 1],
              rotation: [0, 0, 0],
              opacity: 0,
            },
            textAlign: 'right',
            // glyphWidth: 22 // NOTE: This can be enabled to force a non-jiggly timer
          })
          .then(
            async () =>
              await meshes.nextDrawTimer.setDate(new Date(nextDrawTime)),
          ),
        meshes.boost.configure({
          dimensions: { height: 128, width: 256 },
          urls: [boostImage],
          state: {
            position: [3.1, -1.55, -3.5],
            scale: [1, 0.33, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.boostValue.configure({
          dimensions: { height: 64, width: 128 },
          text: [
            {
              text: 'x',
              font: FONTS.AristaProBold,
              size: 50,
              color: COLORS.white,
              // fillStyle: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '2',
            },
            {
              text: `${boost}`,
              font: FONTS.AristaProBold,
              size: 64,
              color: COLORS.white,
              // fillStyle: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '2',
            },
          ],
          state: {
            position: [4.1, -1.575, -3.5],
            scale: [0, 0.264, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
          textBaseline: 'alphabetic',
        }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const {
        _meshes: meshes,
        _state: {
          meta: {
            [this._language]: { balls = [] },
          },
        },
      } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      const currentResults = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballLineLayout();

      return await Promise.all<any>([
        ...meshes.balls.map(
          async (mesh, i) =>
            await mesh.tweenTo(
              {
                position: ballLayout.positions[currentResults[i].sortedIndex],
                opacity: 1,
              },
              1000,
              currentResults[i].sortedIndex * 75,
              TWEEN.Easing.Quadratic.Out,
            ),
        ),
        meshes.background.tweenTo({ opacity: 1 }),
        meshes.foreground.tweenTo({ opacity: 1 }),
        meshes.lastDraw.tweenTo({ opacity: 1 }),
        meshes.nextDraw.tweenTo({ opacity: 1 }),
        meshes.nextDrawTimer
          .beginAutoRedraw()
          .then(async () => await meshes.nextDrawTimer.tweenTo({ opacity: 1 })),
        meshes.boostValue.tweenTo({ opacity: 1 }),
        meshes.boost.tweenTo({ opacity: 1 }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy())
          .catch(reject);
      }

      await Promise.all<any>([
        ...meshes.balls.map(
          async (ball) => await ball?.tweenOut({ opacity: 0 }),
        ),
        meshes.lastDraw.tweenOut({ opacity: 0 }),
        meshes.background.tweenTo({ opacity: 0 }),
        meshes.foreground.tweenOut({ opacity: 0 }),
        meshes.lastDraw.tweenOut({ opacity: 0 }),
        meshes.nextDraw.tweenOut({ opacity: 0 }),
        meshes.nextDrawTimer.tweenOut({ opacity: 0 }),
        meshes.boost.tweenOut({ opacity: 0 }),
        meshes.boostValue.tweenOut({ opacity: 0 }),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (newState: StaticContentStateScene) =>
    await new Promise<void>((resolve, reject) => {
      // TODO: [AKO]: how can we solve the issues with 2 static contents in a row.
      // const changed = this._state.metaHash !== newState.metaHash;
      const changed = this._state.startTime !== newState.startTime;
      this._state = newState;

      const {
        _meshes: meshes,
        _state: {
          meta: {
            [this._language]: { backgroundImages },
          },
        },
      } = this;

      if (changed) {
        meshes.background
          .tweenTo({ opacity: 0 })
          .then(
            async () =>
              await meshes.background.changeImages({ urls: backgroundImages }),
          )
          .then(async () => await meshes.background.tweenTo({ opacity: 1 }))
          .catch(reject);
      }

      resolve();
    });
}
