import TWEEN from '@tweenjs/tween.js';
import { animate } from '../helpers/animate';
import { wait } from '../helpers/functions';
import { Text } from './text';
import { type Texts } from '../interfaces/sceneState';

export class TypewriterText extends Text {
  public animateInDuration = async (
    duration: number = 1000,
    delay: number = 0,
  ) => {
    return await this.drawText(undefined, [0, 0])
      .then(async () => await wait(delay))
      .then(async () => await this.changeTo({ opacity: 1 }))
      .then(async () => await this.animate(this.getText(), duration));
  };

  public animateInInterval = async (
    duration: number = 85,
    delay: number = 0,
  ) => {
    const text = this.getText();

    return await this.drawText(undefined, [0, 0])
      .then(async () => await wait(delay))
      .then(async () => await this.changeTo({ opacity: 1 }))
      .then(async () => await this.animate(text, duration * text.length));
  };

  private readonly animate = async (
    text: Texts = this.getText(),
    duration: number = 1000,
  ) =>
    await new Promise<void>((resolve) => {
      animate([0], [text.length], {
        complete: () => resolve(),
        duration,
        easing: TWEEN.Easing.Linear.None,
        framerate: 20,
        update: async ([to]) => await this.drawText(text, [0, to]),
      });
    });
}
