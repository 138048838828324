import { FeatureFlag } from '../enums/featureFlag';
import { type LoExScene } from '../interfaces/scene';
import { type ErrorStateScene, SceneType } from '../interfaces/sceneState';
import { COLORS } from './colours';

import AttentionScene from '../components/WebGL/scenes/attention';
import LogoScene from '../components/WebGL/scenes/logo';
import ErrorScene from '../components/WebGL/scenes/error';
import BangoScene from '../components/WebGL/scenes/bango';
import BoostScene from '../components/WebGL/scenes/boost';
import BoostAdvertScene from '../components/WebGL/scenes/boostAdvert';
import CountdownScene from '../components/WebGL/scenes/countdown';
import DrawScene from '../components/WebGL/scenes/draw';
import ExtraScene from '../components/WebGL/scenes/extra';
import HappyWinnersScene from '../components/WebGL/scenes/happy-winners';
import LoadingScene from '../components/WebGL/scenes/loading';
import LotteryScene from '../components/WebGL/scenes/lottery';
import NightModeScene from '../components/WebGL/scenes/night';
import ResponsibleScene from '../components/WebGL/scenes/responsible';
import ResultsScene from '../components/WebGL/scenes/results';
import StaticContentScene from '../components/WebGL/scenes/static-content';
import WeatherScene from '../components/WebGL/scenes/weather';
import DrawScene2024 from '../components/WebGL/scenes/2024/draw';
import ResultsScene2024 from '../components/WebGL/scenes/2024/results';
import EmptyScene from '../components/WebGL/scenes/2024/emptyScene';

export const networkErrorScene = {
  scene: SceneType.Error,
  duration: 0,
  startTime: 0,
  endTime: 0,
  progress: 0,
  meta: {
    'fr-ch': {
      textLines: [
        {
          text: 'Erreur de connexion',
          color: COLORS.white,
          font: 'GothamRoundedBold',
          size: 32,
        },
      ],
    },
    'de-ch': {
      textLines: [
        {
          text: 'Verbindungsfehler',
          color: COLORS.white,
          font: 'GothamRoundedBold',
          size: 32,
        },
      ],
    },
  },
} as ErrorStateScene;

export const scenesCollection: Record<
  FeatureFlag,
  Record<SceneType, LoExScene>
> = {
  [FeatureFlag.Default]: {
    [SceneType.Loading]: LoadingScene,
    [SceneType.Attention]: AttentionScene,
    [SceneType.Bango]: BangoScene,
    [SceneType.Countdown]: CountdownScene,
    [SceneType.BoostAdvert]: BoostAdvertScene,
    [SceneType.Boost]: BoostScene,
    [SceneType.Draw]: DrawScene,
    [SceneType.Reorder]: DrawScene,
    [SceneType.Extra]: ExtraScene,
    [SceneType.Lottery]: LotteryScene,
    [SceneType.Logo]: LogoScene,
    [SceneType.HappyWinners]: HappyWinnersScene,
    [SceneType.Responsible]: ResponsibleScene,
    [SceneType.Results]: ResultsScene,
    [SceneType.StaticContent]: StaticContentScene,
    [SceneType.Weather]: WeatherScene,
    [SceneType.Night]: NightModeScene,
    [SceneType.Error]: ErrorScene,
    [SceneType.Unknown]: ErrorScene,
    [SceneType.DidYouKnowScene]: ErrorScene,
  },
  [FeatureFlag.Loex2024]: {
    [SceneType.Loading]: LoadingScene,
    [SceneType.Attention]: EmptyScene,
    [SceneType.Bango]: EmptyScene,
    [SceneType.Countdown]: EmptyScene,
    [SceneType.BoostAdvert]: BoostAdvertScene,
    [SceneType.Boost]: EmptyScene,
    [SceneType.Draw]: DrawScene2024,
    [SceneType.Reorder]: DrawScene2024,
    [SceneType.Extra]: EmptyScene,
    [SceneType.Lottery]: EmptyScene,
    [SceneType.Logo]: EmptyScene,
    [SceneType.HappyWinners]: EmptyScene,
    [SceneType.Responsible]: EmptyScene,
    [SceneType.Results]: ResultsScene2024,
    [SceneType.StaticContent]: EmptyScene,
    [SceneType.Weather]: WeatherScene,
    [SceneType.Night]: EmptyScene,
    [SceneType.Error]: ErrorScene,
    [SceneType.Unknown]: ErrorScene,
    [SceneType.DidYouKnowScene]: EmptyScene,
  },
};
