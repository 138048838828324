import * as THREE from 'three';
import { COLORS } from '../constants/colours';
import { availableTextureSizes, drawMultiPartText } from '../helpers/canvas';
import { toNearest } from '../helpers/maths';
import { type IDimensions, type IState } from '../interfaces/scene';
import ImageService from '../services/image';
import TextureService from '../services/texture';
import { CanvasTexture } from './canvasTexture';
import { Mesh } from './mesh';
import { type Texture } from './texture';
import { ballImageUri } from '../constants/imageUri';
import { FeatureFlag } from '../enums/featureFlag';
import { FONTS } from '../enums/fonts';
import { createBillboardMaterial } from '../components/App/Scenes/Draw/shaders/billboardMaterial';

export class BallFlat extends Mesh {
  public configure = async ({
    dimensions: { height, width } = { height: 512, width: 512 },
    isExtraBall = false,
    state,
    value,
    featureFlag = FeatureFlag.Default,
  }: {
    dimensions?: IDimensions;
    height?: number;
    isExtraBall?: boolean;
    state: IState;
    value?: number;
    width?: number;
    featureFlag: FeatureFlag;
  }): Promise<void> => {
    this._previous = this._current = state;

    this.name = `BallFlat_${value || 0}`;

    return await this.getBallTexture(
      {
        ...this.getConfiguration(featureFlag, value, isExtraBall),
        height,
        width,
      },
      value,
      featureFlag,
    )
      .then((texture) => {
        this.geometry = new THREE.PlaneGeometry(1, 1);
        this.material = createBillboardMaterial(
          new THREE.MeshBasicMaterial({
            color: 'white',
            map: texture,
            transparent: true,
          }),
        );
      })
      .then(() => this.updateMesh(state));
  };

  private readonly getConfiguration = (
    featureFlag: FeatureFlag,
    value?: number,
    isExtraBall?: boolean,
  ) => {
    if (isExtraBall) {
      return {
        background: ballImageUri[featureFlag].flatExtra,
        foreground:
          featureFlag === FeatureFlag.Default ? COLORS.white : COLORS.black,
      };
    } else {
      return {
        background: ballImageUri[featureFlag].flatNormal,
        foreground: COLORS.black,
      };
    }
  };

  private readonly getBallTexture = async (
    {
      background = '',
      foreground = COLORS.black,
      height,
      width,
    }: {
      background: string;
      foreground: string;
      height?: number;
      width?: number;
    },
    value?: number,
    featureFlag?: FeatureFlag,
  ): Promise<Texture> =>
    await ImageService.getImage(background).then((image) => {
      return TextureService.getTexture(
        `${value}_${foreground}_${background}`,
        () => {
          const canvas = document.createElement('canvas');
          canvas.height =
            height || toNearest(image.height, availableTextureSizes);
          canvas.width = width || toNearest(image.width, availableTextureSizes);
          const context = canvas.getContext('2d')!;

          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          const isLoex2024 = featureFlag === FeatureFlag.Loex2024;
          if (typeof value !== 'undefined') {
            drawMultiPartText(
              context,

              [
                {
                  text: `${value}`,
                  font:
                    featureFlag === FeatureFlag.Loex2024
                      ? FONTS.BarlowCondensedSemiBold
                      : FONTS.HelveticaRoundedBoldCondensed,
                  color: foreground,
                  size:
                    featureFlag === FeatureFlag.Loex2024
                      ? canvas.height / 2
                      : canvas.height / 1.6,

                  bold: featureFlag === FeatureFlag.Loex2024,
                },
              ],

              'center',
              'middle',
              undefined,
              isLoex2024
                ? [-1, canvas.height / 18 - 2]
                : [0, canvas.height / 18],
            );
          }

          return new CanvasTexture(canvas);
        },
      );
    });
}
