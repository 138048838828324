import * as THREE from 'three';
import { getCanvasTextureFromUrl } from '../helpers/canvas';
import { type IState } from '../interfaces/scene';
import { Mesh } from './mesh';

export class WeatherIconPlane extends Mesh {
  public configure = async ({
    iconIndex,
    iconPack,
    state,
  }: {
    iconIndex: number;
    iconPack: string;
    state: IState;
  }): Promise<void> => {
    this._previous = this._current = { ...state, opacity: 0 };

    this.name = `AnimationPlane`;

    return await getCanvasTextureFromUrl(iconPack)
      .then((texture) => {
        texture.repeat.set(1 / 8, 1 / 8);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;

        return texture;
      })
      .then((texture) => {
        this.geometry = new THREE.PlaneGeometry(1, 1);
        this.material = new THREE.MeshBasicMaterial({
          color: 'white',
          map: texture,
          transparent: true,
        });
      })
      .then(() => this.updateMesh(this._current))
      .then(async () => await this.setFrame(iconIndex));
  };

  public setFrame = async (frame: number = -1): Promise<void> =>
    frame >= 0 ? await this.updateOffset(frame) : await Promise.resolve();

  protected updateOffset = async (frame: number) =>
    await new Promise<void>((resolve) => {
      if (
        this.material instanceof THREE.MeshBasicMaterial &&
        this.material.map instanceof THREE.Texture
      ) {
        this.material.map.offset.set(...this.getOffset(frame));
      }

      return resolve();
    });

  private readonly getOffset = (frame: number): [number, number] => {
    return [(frame % 8) / 8, 1 - 1 / 8 - Math.floor(frame / 8) / 8];
  };
}
