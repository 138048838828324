import * as THREE from 'three';
import { generateGradientTexture } from '../helpers/canvas';
import { type IDimensions, type IState } from '../interfaces/scene';
import { Mesh } from './mesh';

export class GradientPlane extends Mesh {
  public configure = async ({
    dimensions: { height, width },
    stops = [],
    state,
  }: {
    stops: Array<[number, string]>;
    dimensions: IDimensions;
    state: IState;
  }): Promise<void> =>
    await new Promise((resolve) => {
      this._previous = this._current = state;

      this.name = `GradientPlane`;

      this.geometry = new THREE.PlaneGeometry(1, 1);
      this.material = new THREE.MeshBasicMaterial({
        color: 'white',
        map: generateGradientTexture(width, height, stops),
        transparent: true,
      });

      this.updateMesh(state);

      return resolve();
    });
}
