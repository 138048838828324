import { FEATURES_FLAG_ACTIONS } from '../enums/actions';
import { type FeatureFlag } from '../enums/featureFlag';
import { type IAction } from '../types';

export const saveFeatureFlags = (
  featureFlags: FeatureFlag[],
): IAction<{ featureFlags: FeatureFlag[] }> => ({
  type: FEATURES_FLAG_ACTIONS.SAVE_FEATURE_FLAGS,
  payload: { featureFlags },
});
