import { MeshBasicMaterial } from 'three';
import { createDerivedMaterial } from 'troika-three-utils';

// This material allows to make plane that are 2d and face the screen,
// it allow big optimization for object that don't need to rotate in depth
export function createBillboardMaterial(baseMaterial: MeshBasicMaterial) {
  return createDerivedMaterial(
    baseMaterial,
    Object.assign({
      vertexMainOutro: `
        vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );
        vec3 scale = vec3(
        length(modelViewMatrix[0].xyz),
        length(modelViewMatrix[1].xyz),
        length(modelViewMatrix[2].xyz)
        );
        mvPosition.xyz += position * scale;
        gl_Position = projectionMatrix * mvPosition;
        `,
    }),
  );
}
