/**
 * Get the bits of a formatted query string into a dictionary object
 *
 * @param search - The string to process
 */
export function getQueryStringParameter(
  search: string = document.location.search,
): Record<string, string> {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');

  return hashes.reduce((acc, hash) => {
    const [key, val] = hash.split('=');

    return { ...acc, [key]: decodeURIComponent(val) };
  }, {});
}

/**
 * Get the position of the specified element in relation to the viewport
 *
 * @param element - The element to search for
 */
export function getDOMElementViewportPosition(
  element: HTMLElement,
): ClientRect {
  return element.getBoundingClientRect();
}

/**
 * Get the position of the specified element in relation to the container
 *
 * @param element - The element to search for
 */
export function getDOMElementContainerPosition(element: HTMLElement): {
  offsetTop: number;
  offsetLeft: number;
} {
  const { offsetTop, offsetLeft } = element;

  return {
    offsetTop,
    offsetLeft,
  };
}

/**
 * Get the center of the specified element in relation to the viewport
 *
 * @param element - The element to search for
 */
export function getDOMElementViewportCenter(element: HTMLElement): {
  x: number;
  y: number;
} {
  const { top, height, left, width } = getDOMElementViewportPosition(element);

  return {
    x: left + width / 2,
    y: top + height / 2,
  };
}

/**
 * Get the center of the specified element in relation to the document
 *
 * @param element - The element to search for
 */
export function getDomElementDocumentCenter(element: HTMLElement): {
  x: number;
  y: number;
} {
  const { top, height, left, width } = getDOMElementViewportPosition(element);

  return {
    x: left + width / 2 + window.scrollX,
    y: top + height / 2 + window.scrollY,
  };
}

/**
 * Clone the specified element and add the clone to the document body
 *
 * @param element - The element to clone
 * @param className - An optional class name to append to the clone of the element
 */
export function cloneElementToDOM(
  element: HTMLElement,
  className?: string,
): HTMLElement {
  const clone = element.cloneNode(true) as HTMLElement;

  if (className) {
    clone.setAttribute('class', className);
  }

  return document.body.appendChild(clone);
}
