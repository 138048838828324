export class VideoService {
  private _videos: Record<string, HTMLVideoElement> = {};

  public getVideo = async (
    url: string,
    {
      autoPlay = true,
      muted = true,
      loop = false,
      restart = true,
    }: {
      autoPlay?: boolean;
      muted?: boolean;
      loop?: boolean;
      restart?: boolean;
    } = {},
  ): Promise<HTMLVideoElement> =>
    await new Promise((resolve) => {
      const {
        _videos: { [url]: existingVideo },
      } = this;

      if (existingVideo instanceof HTMLVideoElement) {
        if (restart) {
          this.restartVideo(existingVideo);

          return resolve(existingVideo);
        } else {
          return resolve(existingVideo);
        }
      } else {
        const video = (this._videos[url] = document.createElement('video'));

        video.addEventListener('playing', () => console.log('playing'));
        video.addEventListener('error', () =>
          console.log('error', video.error?.code),
        );

        video.crossOrigin = 'anonymous';
        video.autoplay = autoPlay;
        video.muted = muted;
        video.loop = loop;
        video.src = url;
        video.preload = 'auto';

        try {
          document.body.appendChild(video);
        } catch (ex) {
          console.log(ex);
        }

        if (autoPlay && restart) {
          video.play();

          return resolve(video);
        } else {
          return resolve(video);
        }
      }
    });

  public stopVideo = (url: string) => {
    const {
      _videos: { [url]: video },
    } = this;

    if (video) {
      video.pause();
      video.setAttribute('src', '');
      video.removeAttribute('src');
      video.load();
    }
  };

  private readonly restartVideo = (video: HTMLVideoElement) => {
    if (video.currentTime > 0 && video.readyState >= 2) {
      video.pause();
      video.currentTime = 0;
      video.load();

      video.play();
    } else {
      video.play();
    }
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new VideoService();
