import * as THREE from 'three';
import { screenRatio } from '../components/App/Loex2024Canvas';

export class Renderer {
  private _renderer?: THREE.WebGLRenderer;
  private _canvas?: HTMLCanvasElement;

  constructor() {
    (window as any)._renderer = this;
  }

  public getRenderer = (): THREE.WebGLRenderer | undefined => {
    return this._renderer;
  };

  public getContext = (): WebGLRenderingContext => {
    return this._renderer?.getContext() as WebGLRenderingContext;
  };

  public info = () => ({
    ...(this._renderer ? this._renderer.info.memory : {}),
  });

  public configure = async (canvas: HTMLCanvasElement) =>
    await new Promise<void>((resolve) => {
      this._canvas = canvas;
      this._renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: false,
        canvas: this._canvas,
        powerPreference: 'low-power',
        precision: 'lowp',
      });
      this._renderer.outputColorSpace = THREE.LinearSRGBColorSpace;

      this._renderer.setPixelRatio(Math.max(window.devicePixelRatio, 1));
      const newWidth = window.visualViewport?.width;
      this._renderer.setSize(newWidth, screenRatio * newWidth);
      resolve();
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Renderer();
