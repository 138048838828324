import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { Text } from '../../../models/text';
import { ImagePlane } from '../../../models/imagePlane';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { type ILoExScene } from '../../../interfaces/scene';
import { type LogoStateScene } from '../../../interfaces/sceneState';

interface ISceneMeshes extends ISceneMeshesBase {
  logo?: ImagePlane;
  topText?: Text;
  bottomText?: Text;
}

export default class LogoScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: LogoStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {};

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (language: string, state: LogoStateScene) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _state: {
          meta: {
            [language]: { logoImage, topText, bottomText },
          },
        },
      } = this;

      this._scene.add((meshes.logo = new ImagePlane()));
      this._scene.add((meshes.topText = new Text()));
      this._scene.add((meshes.bottomText = new Text()));

      return await Promise.all([
        meshes.logo.configure({
          dimensions: { height: 256, width: 512 },
          urls: [logoImage],
          state: {
            position: [-4, 0, -3.5],
            scale: [4.5, 1.25, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.topText.configure({
          state: {
            position: [0, 1.2, -3.5],
            scale: [0, 0.45, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          text: topText,
          textAlign: 'center',
        }),
        meshes.bottomText.configure({
          state: {
            position: [0, -1.2, -3.5],
            scale: [0, 0.45, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          text: bottomText,
          textAlign: 'center',
        }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      return await Promise.all([
        meshes.topText.tweenTo(
          {
            opacity: 1,
          },
          1000,
          0,
          TWEEN.Easing.Quadratic.Out,
        ),
        meshes.logo.tweenTo(
          {
            position: [0, 0, -3.5],
            opacity: 1,
          },
          1000,
          250,
          TWEEN.Easing.Quadratic.Out,
        ),
        meshes.bottomText.tweenTo(
          {
            opacity: 1,
          },
          1000,
          500,
          TWEEN.Easing.Quadratic.Out,
        ),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy());
      }

      Promise.all([
        meshes.topText.tweenOut({ opacity: 0 }, 250),
        meshes.bottomText.tweenOut({ opacity: 0 }, 250),
        meshes.logo.tweenOut({ opacity: 0 }, 250),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: LogoStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
