import * as THREE from 'three';

export class CanvasTexture extends THREE.CanvasTexture {
  public readonly retain: boolean = false;

  constructor(
    canvas: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement,
    retain: boolean = false,
  ) {
    super(
      canvas,
      undefined,
      undefined,
      undefined,
      THREE.LinearFilter,
      THREE.LinearFilter,
    );

    this.retain = retain;
  }
}
