import { SceneType } from '../../interfaces/sceneState';
import { createScene } from '../../helpers/createScene';
import { AttentionScene } from './Scenes/Attention';
import { BangoScene } from './Scenes/Bango';
import { BoostScene } from './Scenes/Boost';
import { CountdownScene } from './Scenes/Countdown';
import { DidYouKnowScene } from './Scenes/DidYouKnow';
import { DrawScene } from './Scenes/Draw';
import { ExtraScene } from './Scenes/Extra';
import { HappyWinnersScene } from './Scenes/HappyWinners';
import { LogoScene } from './Scenes/Logo';
import { LotteryScene } from './Scenes/Lottery';
import { NightTimeModeScene } from './Scenes/NightTimeMode';
import { ReorderScene } from './Scenes/Reorder';
import { ResponsibleScene } from './Scenes/Responsible';
import { ResultsScene } from './Scenes/Results';
import { StaticContentScene } from './Scenes/StaticContent';

export const EmptyScene = createScene(null, null);

export const SCENE_COMPONENTS = {
  [SceneType.Loading]: EmptyScene,
  [SceneType.Attention]: AttentionScene,
  [SceneType.Bango]: BangoScene,
  [SceneType.Countdown]: CountdownScene,
  [SceneType.BoostAdvert]: EmptyScene,
  [SceneType.Boost]: BoostScene,
  [SceneType.Draw]: DrawScene,
  [SceneType.Reorder]: ReorderScene,
  [SceneType.Extra]: ExtraScene,
  [SceneType.Lottery]: LotteryScene,
  [SceneType.Logo]: LogoScene,
  [SceneType.HappyWinners]: HappyWinnersScene,
  [SceneType.Responsible]: ResponsibleScene,
  [SceneType.Results]: ResultsScene,
  [SceneType.StaticContent]: StaticContentScene,
  [SceneType.Weather]: EmptyScene,
  [SceneType.Night]: NightTimeModeScene,
  [SceneType.DidYouKnowScene]: DidYouKnowScene,
  [SceneType.Error]: EmptyScene,
  [SceneType.Unknown]: EmptyScene,
};
