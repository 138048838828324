import { NextDraw } from '../../../Common/NextDraw';
import { DrawInProgress } from '../../../Common/DrawInProgress';
import { LotoExpressLogo } from '../../../Common/LotoExpressLogo';
import { BoostSplash } from '../../../Common/BoostSplash';
import { IStateProps } from '../..';
import { getMeta } from '../../../../helpers/getMeta';
import { createScene } from '../../../../helpers/createScene';

const ResultsHTML = (props: IStateProps) => {
  const meta = getMeta(props);
  return (
    <>
      <DrawInProgress scene={props.displayState} {...props} />
      <NextDraw {...meta} />
      <LotoExpressLogo {...props} />
      <BoostSplash scene={props.displayState} {...props} />
    </>
  );
};

export const ResultsScene = createScene(ResultsHTML, null);
