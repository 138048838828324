import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { COLORS } from '../../../constants/colours';
import { timeEmpty, timeFormat, timeInvalid } from '../../../constants/texts';
import { FONTS } from '../../../enums/fonts';
import {
  adjustArrayValues,
  createArrayOfLength,
  getSortData,
} from '../../../helpers/arrays';
import {
  extractBallsFeatureFlag,
  getIsFeatureFlag2024,
} from '../../../helpers/featureFlag';
import { formatNumber } from '../../../helpers/formatters';
import { clamp } from '../../../helpers/maths';
import { templateTexts } from '../../../helpers/strings';
import { ISettings } from '../../../interfaces/app';
import { type ILoExScene } from '../../../interfaces/scene';
import {
  BangoItem,
  type BangoStateScene,
} from '../../../interfaces/sceneState';
import { BallFlat } from '../../../models/ballFlat';
import { BoostText } from '../../../models/boostText';
import { GradientPlane } from '../../../models/gradientPlane';
import { ImagePlane } from '../../../models/imagePlane';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { ShuffleText } from '../../../models/shuffleText';
import { Text } from '../../../models/text';
import { Timer } from '../../../models/timer';
import LayoutService from '../../../services/layout';
import { getBannerColor } from '../../../helpers/bannerColor';

interface ISceneMeshes extends ISceneMeshesBase {
  balls: BallFlat[];
  bangoWinnings: Text[];
  bangoJackpot?: Text;
  bangoWinning?: Text;
  bangoNumbers: Text[];
  bangoLogo?: ImagePlane;
  bangoPluses: Text[];
  bangoJackpots: ShuffleText[];
  foreground?: GradientPlane;
  lastDraw?: Text;
  nextDraw?: Text;
  nextDrawTimer?: Timer;
  boost?: ImagePlane;
  boostValue?: BoostText;
}

export default class BangoScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: BangoStateScene;
  protected _language: string;
  protected _settings: ISettings;

  protected _meshes: ISceneMeshes = {
    balls: [],
    bangoWinnings: [],
    bangoNumbers: [],
    bangoLines: [],
    bangoJackpots: [],
    bangoPluses: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: BangoStateScene,
    settings: ISettings,
  ) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;
      this._settings = settings;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: {
              bangoFixedText,
              bangoJackpotText,
              lastDrawText,
              nextDrawText,
              nextDrawTime,
              bangoImage,
              extra,
              balls,
              id,
              bango,
              boostImage,
              boost,
            },
          },
        },
      } = this;

      const currentResults: any[] = getSortData(balls, (a, b) => a - b);
      const ballLayout = LayoutService.ballLineLayout();
      const isFeatureFlag2024 = getIsFeatureFlag2024(
        extractBallsFeatureFlag(settings.featureFlags),
      );
      const bannerBallColor = getBannerColor(isFeatureFlag2024);
      scene.add((meshes.bangoLogo = new ImagePlane()));
      scene.add((meshes.bangoJackpot = new Text()));
      scene.add((meshes.bangoWinning = new Text()));
      scene.add((meshes.foreground = new GradientPlane()));
      scene.add((meshes.nextDraw = new Text()));
      scene.add((meshes.nextDrawTimer = new Timer()));
      scene.add((meshes.lastDraw = new Text()));
      scene.add((meshes.boost = new ImagePlane()));
      scene.add((meshes.boostValue = new BoostText()));

      const additionalTVFormat = [
        ...createArrayOfLength(20).map(async (n, i) => {
          if (currentResults[i] && !meshes.balls[i]) {
            scene.add((meshes.balls[i] = new BallFlat()));

            return await meshes.balls[i].configure({
              value: currentResults[i].value,
              state: {
                position: adjustArrayValues(
                  ballLayout.positions[currentResults[i].sortedIndex],
                  [0, -0.75, 0],
                ),
                rotation: [0, 0, 0],
                scale: ballLayout.scale,
                opacity: 0,
              },
              isExtraBall: currentResults[i].value === extra,
              dimensions: {
                height: 64,
                width: 64,
              },
              featureFlag: extractBallsFeatureFlag(settings.featureFlags),
            });
          } else {
            return await Promise.resolve();
          }
        }),
        meshes.foreground.configure({
          stops: [
            [0, 'rgba(0, 0, 0, 0)'],
            [0.5, 'rgba(0, 0, 0, 0.3)'],
            [0.5, bannerBallColor],
            [1.0, bannerBallColor],
          ],
          dimensions: {
            height: 64,
            width: 8,
          },
          state: {
            position: [0, -1.85, -3.51],
            rotation: [0, 0, 0],
            scale: [8.92, 1.225, 1],
            opacity: 0,
          },
        }),
      ];

      // whole object
      const objectPositions = [
        ...additionalTVFormat,
        ...bango
          ?.filter((jackpot: BangoItem) => {
            if (!isFeatureFlag2024) {
              return jackpot.id !== 10;
            }
            return true;
          })
          ?.map(async ({ id, fixedShareAmount, amount }, i) => {
            scene.add((meshes.bangoJackpots[i] = new ShuffleText()));
            scene.add((meshes.bangoWinnings[i] = new Text()));
            scene.add((meshes.bangoPluses[i] = new Text()));
            scene.add((meshes.bangoNumbers[i] = new Text()));

            return await Promise.all([
              meshes.bangoNumbers?.[i]?.configure({
                state: {
                  position: [-3.2, i * -0.55 + 0.9, -3.5],
                  rotation: [0, 0, 0],
                  scale: [0, 0.3, 0.5],
                  opacity: 0,
                },
                text: [
                  {
                    text: `${id}/${id}`,
                    font: FONTS.HelveticaRoundedBoldCondensed,
                    size: 30,
                    color: COLORS.white,
                  },
                ],
                textAlign: 'center',
              }),
              meshes.bangoPluses?.[i]?.configure({
                state: {
                  position: [0.55, i * -0.55 + 0.9, -3.5],
                  rotation: [0, 0, 0],
                  scale: [0, 0.3, 0.5],
                  opacity: 0,
                },
                text: [
                  {
                    text: '+',
                    font: FONTS.HelveticaRoundedBoldCondensed,
                    size: 60,
                    color: COLORS.yellow,
                  },
                ],
                textAlign: 'center',
              }),
              meshes.bangoJackpots?.[i]?.configure({
                state: {
                  position: [0.2, i * -0.55 + 0.9, -3.5],
                  rotation: [0, 0, 0],
                  scale: [0, 0.5, 0.5],
                  opacity: 0,
                },
                text: [
                  {
                    text: `${formatNumber(amount / 100, 0, '.', 3, '’')}.-`,
                    font: FONTS.HelveticaRoundedBoldCondensed,
                    size: 60,
                    color: COLORS.yellow,
                  },
                ],
                textAlign: 'right',
                glyphWidth: 37,
              }),
              meshes.bangoWinnings?.[i]?.configure({
                state: {
                  position: [3.6, i * -0.55 + 0.9, -3.5],
                  rotation: [0, 0, 0],
                  scale: [0, 0.5, 0.5],
                  opacity: 0,
                },
                text: [
                  {
                    text: `${formatNumber(
                      fixedShareAmount / 100,
                      0,
                      '.',
                      3,
                      '’',
                    )}.-`,
                    font: FONTS.HelveticaRoundedBoldCondensed,
                    size: 60,
                    color: COLORS.yellow,
                  },
                ],
                textAlign: 'right',
                glyphWidth: 37,
              }),
            ]);
          }),
        meshes.bangoJackpot?.configure({
          text: bangoJackpotText,
          textAlign: 'center',
          state: {
            position: [-1.3, 1.4, -3.5],
            rotation: [0, 0, 0],
            scale: [0, 0.27, 1],
            opacity: 0,
          },
        }),
        meshes.bangoLogo?.configure({
          dimensions: { width: 512, height: 256 },
          urls: [bangoImage],
          state: {
            position: [-3.25, 2, -3.5],
            rotation: [0, 0, 0],
            scale: [1.85, 0.575, 1],
            opacity: 0,
          },
        }),

        meshes.bangoWinning?.configure({
          text: bangoFixedText,
          textAlign: 'center',
          state: {
            position: [2.05, 1.4, -3.5],
            rotation: [0, 0, 0],
            scale: [0, 0.27, 1],
            opacity: 0,
          },
        }),

        meshes.lastDraw?.configure({
          text: templateTexts(lastDrawText, { id }),
          state: {
            position: [-4.1, -1.6125, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.nextDraw?.configure({
          text: nextDrawText,
          state: {
            position: [4.1, 2.2, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
        }),
        meshes.nextDrawTimer
          ?.configure({
            empty: timeEmpty,
            format: timeFormat,
            invalid: timeInvalid,
            state: {
              position: [4.1, 1.9, -3.5],
              scale: [0, 0.2, 1],
              rotation: [0, 0, 0],
              opacity: 0,
            },
            textAlign: 'right',
            // glyphWidth: 22 // NOTE: This can be enabled to force a non-jiggly timer
          })
          .then(
            async () =>
              await meshes.nextDrawTimer.setDate(new Date(nextDrawTime)),
          ),
        meshes.boost?.configure({
          dimensions: { height: 128, width: 256 },
          urls: [boostImage],
          state: {
            position: [3.1, -1.55, -3.5],
            scale: [1, 0.33, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),

        meshes.boostValue?.configure({
          dimensions: { height: 64, width: 128 },
          text: [
            {
              text: 'x',
              font: FONTS.AristaProBold,
              size: 50,
              color: COLORS.white,
            },
            {
              text: `${boost}`,
              font: FONTS.AristaProBold,
              size: 64,
              color: COLORS.white,
            },
          ],
          state: {
            position: [4.1, -1.575, -3.5],
            scale: [0, 0.264, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
          textBaseline: 'alphabetic',
        }),
      ];
      return await Promise.all<any>(objectPositions)
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const {
        _meshes: meshes,
        _state: {
          meta: {
            [this._language]: { balls },
          },
        },
      } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      const currentResults: any[] = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballLineLayout();
      const TVAdditionalAnim = [
        meshes.foreground.tweenTo({ opacity: 1 }),
        ...meshes.balls.map(
          async (mesh, i) =>
            await mesh.tweenTo(
              {
                position: ballLayout.positions[currentResults[i].sortedIndex],
                opacity: 1,
              },
              1000,
              currentResults[i].sortedIndex * 75,
              TWEEN.Easing.Quadratic.Out,
            ),
        ),
      ];
      const animationArray = [
        ...TVAdditionalAnim,
        ...meshes.bangoWinnings.map(
          async (mesh) => await mesh.tweenTo({ opacity: 1 }),
        ),
        ...meshes.bangoNumbers.map(
          async (mesh) => await mesh.tweenTo({ opacity: 1 }),
        ),
        ...meshes.bangoPluses.map(
          async (mesh) => await mesh.tweenTo({ opacity: 1 }),
        ),
        meshes.bangoJackpot.tweenTo({ opacity: 1 }),
        meshes.bangoWinning.tweenTo({ opacity: 1 }),
        meshes.bangoLogo.tweenTo({ opacity: 1 }, 500),
        meshes.lastDraw.tweenTo({ opacity: 1 }),
        meshes.nextDraw.tweenTo({ opacity: 1 }),
        meshes.nextDrawTimer
          .beginAutoRedraw()
          .then(async () => await meshes.nextDrawTimer.tweenTo({ opacity: 1 })),
        meshes.boostValue.tweenTo({ opacity: 1 }),
        meshes.boost.tweenTo({ opacity: 1 }),
      ];

      return await Promise.all<any>(animationArray)
        .then(
          async () =>
            await Promise.all(
              meshes.bangoJackpots.map(
                async (mesh, i) => await mesh.animateIn(2000, i * 200 + 500),
              ),
            ),
        )
        .then(
          async () =>
            await Promise.all(
              meshes.bangoJackpots.map(
                async (mesh) =>
                  await mesh.tweenTo({ opacity: 0 }, 3000, 1500, (n) =>
                    clamp(Math.sin(n * (6 * Math.PI)), 0, 1),
                  ),
              ),
            ),
        )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy())
          .catch(reject);
      }

      Promise.all<any>([
        ...meshes.balls?.map(
          async (ball) => await ball?.tweenOut({ opacity: 0 }),
        ),
        ...meshes.bangoNumbers?.map(
          async (jackpot) => await jackpot?.tweenOut({ opacity: 0 }),
        ),
        ...meshes.bangoWinnings?.map(
          async (jackpot) => await jackpot.tweenOut({ opacity: 0 }),
        ),
        ...meshes.bangoJackpots?.map(
          async (jackpot) => await jackpot?.tweenOut({ opacity: 0 }),
        ),
        ...meshes.bangoPluses?.map(
          async (jackpot) => await jackpot?.tweenOut({ opacity: 0 }),
        ),
        meshes.lastDraw?.tweenOut({ opacity: 0 }),
        meshes.bangoLogo?.tweenOut({ opacity: 0 }),
        meshes.foreground?.tweenOut({ opacity: 0 }),
        meshes.lastDraw?.tweenOut({ opacity: 0 }),
        meshes.nextDraw?.tweenOut({ opacity: 0 }),
        meshes.nextDrawTimer?.tweenOut({ opacity: 0 }),
        meshes.bangoJackpot?.tweenOut({ opacity: 0 }),
        meshes.bangoWinning?.tweenOut({ opacity: 0 }),
        meshes.boost?.tweenOut({ opacity: 0 }),
        meshes.boostValue?.tweenOut({ opacity: 0 }),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: BangoStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
