import * as THREE from 'three';
import { Mesh } from './mesh';
import { COLORS } from '../constants/colours';
import { type IDimensions, type IState } from '../interfaces/scene';
import { type Texture } from './texture';
import TextureService from '../services/texture';
import ImageService from '../services/image';
import { toNearest } from '../helpers/maths';
import { availableTextureSizes, drawMultiPartText } from '../helpers/canvas';
import { CanvasTexture } from './canvasTexture';
import { FONTS } from '../enums/fonts';
import { ballImageUri } from '../constants/imageUri';
import { FeatureFlag } from '../enums/featureFlag';

export class BallShaded extends Mesh {
  [x: string]: any;
  public configure = async ({
    featureFlag,
    dimensions: { height, width } = { height: 512, width: 512 },
    isExtraBall = false,
    state,
    value,
  }: {
    featureFlag?: FeatureFlag;
    dimensions?: IDimensions;
    height?: number;
    isExtraBall?: boolean;
    state: IState;
    value?: number;
    width?: number;
  }): Promise<void> => {
    this._previous = this._current = state;
    this.name = `BallShaded_${value || 0}`;
    const is2024 = featureFlag === FeatureFlag.Loex2024;
    return await (
      value
        ? this.getBallTexture(
            {
              ...this.getConfiguration(featureFlag, value, isExtraBall),
              height,
              width,
              is2024,
            },
            value,
          )
        : this.getBallTexture({
            ...this.getConfiguration(featureFlag, 0, isExtraBall),
            height,
            width,
            is2024,
          })
    )
      .then((numberTexture) => {
        this.material = new THREE.MeshBasicMaterial({
          color: COLORS.white,
          map: numberTexture,
          transparent: true,
        });

        this.geometry = new THREE.PlaneGeometry(1, 1);
      })
      .then(() => this.updateMesh(state));
  };

  private readonly getConfiguration = (
    featureFlag: FeatureFlag,
    value?: number,
    isExtraBall?: boolean,
  ) => {
    if (value) {
      if (isExtraBall) {
        return {
          background: ballImageUri[featureFlag].shadedExtra,
          foreground: COLORS.black,
        };
      } else {
        return {
          background: ballImageUri[featureFlag].shadedNormal,
          foreground: COLORS.black,
        };
      }
    } else {
      return {
        background: ballImageUri[featureFlag].shadedBackground,
        foreground: COLORS.black,
      };
    }
  };

  private readonly getBallTexture = async (
    {
      background = '',
      foreground = COLORS.black,
      height,
      width,
      is2024 = false,
    }: {
      background: string;
      foreground: string;
      height?: number;
      width?: number;
      is2024?: boolean;
    },
    value: number = 0,
  ): Promise<Texture> =>
    await ImageService.getImage(background).then((image) => {
      return TextureService.getTexture(
        `${value}_${foreground}_${background}`,
        () => {
          const canvas = document.createElement('canvas');
          canvas.height =
            height || toNearest(image.height, availableTextureSizes);
          canvas.width = width || toNearest(image.width, availableTextureSizes);
          const context = canvas.getContext('2d')!;

          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          if (value > 0) {
            drawMultiPartText(
              context,
              [
                {
                  text: `${value}`,
                  size: canvas.height / 2.2,
                  color: foreground,
                  font: is2024
                    ? FONTS.BarlowCondensedSemiBold
                    : FONTS.HelveticaRoundedBoldCondensed,
                  bold: is2024,
                },
              ],
              'center',
              'middle',
              undefined,
              is2024 ? [-3, canvas.height / 20 - 10] : [0, canvas.height / 20],
            );
          }

          return new CanvasTexture(canvas, value === 0);
        },
      );
    });
}
