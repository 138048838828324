import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { animate } from '../helpers/animate';
import { destroyMesh, getCanvasTextureFromUrl } from '../helpers/canvas';
import { wait } from '../helpers/functions';
import { type IState } from '../interfaces/scene';
import { Mesh } from './mesh';
import { type Texture } from './texture';

interface IAnimationImage {
  url: string;
  arrangement: [number, number];
  totalFrames: number;
}

export class AnimationPlane extends Mesh {
  private _interval: any;

  private _currentFrame: number = 0;
  private _images: IAnimationImage[];
  private _textures: Texture[];

  private _totalFrames: number = 0;

  public configure = async ({
    images,
    state,
  }: {
    images: IAnimationImage[];
    state: Omit<IState, 'opacity'>;
  }): Promise<void> => {
    this._previous = this._current = { ...state, opacity: 0 };
    this._images = images;

    this.name = `AnimationPlane`;

    return await Promise.all(
      this._images.map(
        async ({ url, arrangement: [c, r] }) =>
          await getCanvasTextureFromUrl(url).then((texture) => {
            texture.repeat.set(1 / c, 1 / r);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;

            return texture;
          }),
      ),
    )
      .then((textures) => {
        this._textures = textures;

        this.geometry = new THREE.PlaneGeometry(1, 1);
        this.material = new THREE.MeshBasicMaterial({
          color: 'white',
          map: textures[0],
          transparent: true,
        });
      })
      .then(() => {
        this._totalFrames = this._images.reduce(
          (out: number, curr: IAnimationImage) => out + curr.totalFrames,
          0,
        );

        return this.updateMesh(this._current);
      });
  };

  public getTotalFrames = () => this._totalFrames;

  public animateFromToFrame = async (
    fromFrame: number = 0,
    toFrame: number = this.getTotalFrames(),
    duration?: number,
    delay: number = 0,
    easing: (k: number) => number = TWEEN.Easing.Linear.None,
  ) =>
    await new Promise<void>((resolve) => {
      this.setFrame(fromFrame).then(() =>
        animate([fromFrame], [toFrame], {
          complete: () => resolve(),
          delay,
          duration,
          easing,
          framerate: 30,
          update: async ([i]) => await this.updateOffset(Math.floor(i)),
        }),
      );
    });

  public animateOnce = async (
    duration?: number,
    delay: number = 0,
    easing: (k: number) => number = TWEEN.Easing.Linear.None,
    blank: boolean = true,
  ) =>
    await this.changeTo({ opacity: 1 })
      .then(
        async () =>
          await this.animateFromToFrame(
            0,
            this._totalFrames - 1,
            duration,
            delay,
            easing,
          ),
      )
      .then(async () =>
        blank ? await this.changeTo({ opacity: 0 }) : await Promise.resolve(),
      );

  public beginAnimation = async (
    duration: number = 1000,
    delay: number = 0,
  ) => {
    this._interval = clearInterval(this._interval);

    return await wait(delay)
      .then(async () => await this.changeTo({ opacity: 1 }))
      .then(async () => await this.updateOffset(0))
      .then(
        () =>
          (this._interval = setInterval(
            this.nextFrame,
            duration / this._totalFrames,
          )),
      )
      .catch(() => (this._interval = clearInterval(this._interval)));
  };

  public stopAnimation = async (): Promise<void> => {
    this._interval = clearInterval(this._interval);

    return await this.changeTo({ opacity: 0 });
  };

  public nextFrame = async () =>
    await this.setFrame(
      (this._currentFrame = (this._currentFrame + 1) % this._totalFrames),
    );

  public setFrame = async (frame: number) => await this.updateOffset(frame);

  public destroy = () => {
    this._textures.forEach((texture, i) => {
      if (texture instanceof THREE.Texture && !texture.retain) {
        if (
          texture instanceof THREE.CanvasTexture &&
          texture.image instanceof HTMLCanvasElement
        ) {
          texture.image.remove();
          texture.image = null;
        }

        texture.dispose();
        delete this._textures[i];
      }
    });

    this._textures = [];

    return destroyMesh(this);
  };

  protected updateOffset = async (frame: number) =>
    await new Promise<void>((resolve) => {
      if (
        this.material instanceof THREE.MeshBasicMaterial &&
        this.material.map instanceof THREE.Texture
      ) {
        const [texture, x, y] = this.getOffset(frame);

        if (this.material.map !== this._textures[texture]) {
          this.material.map = this._textures[texture];
        }

        this.material.map.offset.set(x, y);
      } else {
        this._interval = clearInterval(this._interval);
      }

      return resolve();
    });

  private readonly getImageOffset = (frame: number): [number, number] =>
    this._images.reduce(
      ([texture, cumulative], curr, i) => [
        frame >= cumulative && frame < cumulative + curr.totalFrames
          ? i
          : texture,
        cumulative + curr.totalFrames,
      ],
      [0, 0],
    );

  private readonly getOffset = (frame: number): [number, number, number] => {
    const [texture] = this.getImageOffset(frame);

    const {
      arrangement: [h, v],
    } = this._images[texture];

    return [
      texture,
      (frame % h) / h,
      texture + 1 - 1 / v - Math.floor(frame / h) / v,
    ];
  };
}
