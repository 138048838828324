import * as React from 'react';
import { connect } from 'react-redux';
import * as THREE from 'three';
import { scenesCollection } from '../../constants/scene';
import { FONTS } from '../../enums/fonts';
import { extractSceneFeatureFlag } from '../../helpers/featureFlag';
import { getClassNames } from '../../helpers/styles';
import { ISettings, type IAppState } from '../../interfaces/app';
import { type ILoExScene, type LoExScene } from '../../interfaces/scene';
import { SceneType, type SceneState } from '../../interfaces/sceneState';
import LayoutService from '../../services/layout';
import renderer from '../../services/renderer';
import ComparingComponent from '../Common/ComparingComponent';
import ErrorScene from './scenes/error';
import LoadingScene from './scenes/loading';
import styles from './styles';
import { FeatureFlag } from '../../enums/featureFlag';
import backgroundRedForBoost10 from './../../assets/images/backgrounds/background_boost_ten.svg';
import backgroundDefault from './../../assets/images/backgrounds/background_blue_halo.svg';
import { screenRatio } from '../App/Loex2024Canvas';
import { useEffect } from 'react';

export const APP_WIDTH_LEGACY = 1280;
export const APP_HEIGHT_LEGACY = 720;

interface IStateProps {
  currentState?: SceneState<{}>;
  language: string;
  settings: ISettings;
}

const isBoostTenSupported = (props: IStateProps): boolean => {
  const { scene = SceneType.Loading } = props?.currentState || {};
  const isBoostTen = Object.values(props.currentState?.meta || {}).some(
    (m: any) => m?.['boost'] === 10,
  );
  const supportedScenes = [SceneType.Draw, SceneType.Reorder, SceneType.Extra];
  return isBoostTen && supportedScenes.includes(scene);
};

export class WebGLView extends ComparingComponent<IStateProps> {
  private _canvasRef: HTMLCanvasElement | null = null;
  private _camera: THREE.Camera | null = null;
  private _scene: ILoExScene | null = null;

  private static readonly getCamera = (
    orthographic: boolean = false,
  ): THREE.Camera => {
    const display = LayoutService.display();
    if (orthographic) {
      return new THREE.OrthographicCamera(
        (5 * (display.width / display.height)) / -2,
        (5 * (display.width / display.height)) / 2,
        2.5,
        -2.5,
        0.1,
        1000,
      );
    } else {
      return new THREE.PerspectiveCamera(
        70,
        display.width / display.height,
        0.01,
        100,
      );
    }
  };

  async componentDidMount(): Promise<void> {
    LayoutService.setDimensions(APP_WIDTH_LEGACY, APP_HEIGHT_LEGACY);
    this._camera = WebGLView.getCamera();
    await this.setup();
  }

  async componentDidUpdate(): Promise<void> {
    try {
      await this.manageScene();
    } catch (error) {
      console.error('Error in componentDidUpdate:', error);
    }
  }

  render(): React.ReactNode {
    const classNames = getClassNames(styles);
    const sceneFlag = extractSceneFeatureFlag(this.props.settings.featureFlags);
    const loex2024Scene = sceneFlag === FeatureFlag.Loex2024;
    let backgroundUrl = this.props.currentState?.backgroundImage;
    if (loex2024Scene && isBoostTenSupported(this.props)) {
      backgroundUrl = backgroundRedForBoost10;
    } else if (loex2024Scene) {
      if (!backgroundUrl) backgroundUrl = backgroundDefault;
    }
    const style = backgroundUrl
      ? { backgroundImage: `url(${backgroundUrl})` }
      : {};

    return (
      <>
        <div id="canvas-container-legacy">
          <canvas
            className={classNames.canvas}
            style={style}
            ref={(ref) => (this._canvasRef = ref)}
          />
        </div>
        <FontPreloader />
      </>
    );
  }

  private readonly setup = async (): Promise<void> => {
    if (this._canvasRef) {
      const display = LayoutService.display();
      this._canvasRef.width = display.width;
      this._canvasRef.height = display.height;
      window.addEventListener('resize', this.onWindowResize, false);

      try {
        await renderer.configure(this._canvasRef);
        this._scene = new LoadingScene();
        await this._scene.setup(
          this.props.language,
          this.props.currentState as SceneState<any>,
          this.props.settings,
        );
        await this._scene.play();
        this.paint();
        this.onWindowResize();
      } catch (error) {
        console.error('Error in setup:', error);
      }
    }
  };
  onWindowResize() {
    const newWidth = window.visualViewport?.width;
    renderer.getRenderer()?.setSize(newWidth, screenRatio * newWidth);
  }

  private readonly paint = (): void => {
    const { _scene, _camera } = this;
    if (_scene && _camera && renderer.getRenderer()) {
      try {
        renderer.getRenderer()!.render(_scene.getScene(), _camera);
      } catch (error) {
        console.error('Error in paint:', error);
      }
      requestAnimationFrame(this.paint);
    }
  };

  private readonly manageScene = async (
    displayState: SceneState<{}>['scene'] = this.props.currentState?.scene ??
      SceneType.Loading,
  ): Promise<void> => {
    try {
      const RequiredScene = this.getScene(displayState);
      if (this._scene instanceof RequiredScene) {
        await this._scene.onChange(this.props.currentState as SceneState<any>);
      } else if (this._scene) {
        await this._scene.teardown();
        this._scene = new RequiredScene();
        await this._scene?.setup(
          this.props.language,
          this.props.currentState as SceneState<any>,
          this.props.settings,
        );
        await this._scene?.play();
      } else {
        this._scene = new RequiredScene();
        await this._scene?.setup(
          this.props.language,
          this.props.currentState as SceneState<any>,
          this.props.settings,
        );
        await this._scene?.play();
      }
    } catch (error) {
      console.error('Error in manageScene:', error);
      this._scene = new ErrorScene();
      await this._scene.setup(
        this.props.language,
        this.props.currentState as SceneState<any>,
        this.props.settings,
      );
    }
  };

  private readonly getScene = (
    displayState: SceneState<{}>['scene'] = this.props.currentState
      ? this.props.currentState.scene
      : SceneType.Loading,
  ): LoExScene => {
    const sceneFlag = extractSceneFeatureFlag(this.props.settings.featureFlags);
    const scene = scenesCollection[sceneFlag][displayState];
    if (!scene) {
      console.warn(`Scene not found for state: ${displayState}`);
      const errorScene = scenesCollection[sceneFlag][SceneType.Error];
      if (!errorScene) {
        console.error('Error scene not found');
        return ErrorScene;
      }
      return errorScene;
    }
    return scene;
  };
}
export default connect<IStateProps, {}, {}, IAppState>(
  (state: IAppState): IStateProps => ({
    currentState: state.currentState,
    language: state.language,
    settings: state.settings,
  }),
)(WebGLView);

const FontPreloader = () => {
  // this methods of font preloading is mandatory due to THREE.js Canvas texture creation
  // it will not work to use link load in html or other methods
  // This new method is lighter and remove the div when fonts are loaded
  useEffect(() => {
    const preloadFonts = async () => {
      await document.fonts.ready;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const preloader = document.querySelector('.font-preloader');
      if (preloader && preloader.remove) preloader.remove();
    };
    preloadFonts();
  }, []);

  const classNames = getClassNames(styles);

  return (
    <span className="font-preloader" aria-hidden="true">
      {Object.keys(FONTS).map((font) => (
        <span
          key={font}
          className={classNames[`fonts_${font}` as keyof typeof classNames]}
        >
          _
        </span>
      ))}
    </span>
  );
};
