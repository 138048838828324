import TWEEN from '@tweenjs/tween.js';
import moment from 'moment';
import * as THREE from 'three';
import { COLORS } from '../../../constants/colours';
import { timeEmpty, timeFormat, timeInvalid } from '../../../constants/texts';
import { FONTS } from '../../../enums/fonts';
import {
  adjustArrayValues,
  createArrayOfLength,
  getSortData,
} from '../../../helpers/arrays';
import {
  extractBallsFeatureFlag,
  getIsFeatureFlag2024,
} from '../../../helpers/featureFlag';
import { templateTexts } from '../../../helpers/strings';
import { parseWeather } from '../../../helpers/weather';
import { ISettings } from '../../../interfaces/app';
import { type ILoExScene } from '../../../interfaces/scene';
import {
  type Texts,
  type WeatherStateScene,
} from '../../../interfaces/sceneState';
import { BallFlat } from '../../../models/ballFlat';
import { BoostText } from '../../../models/boostText';
import { GradientPlane } from '../../../models/gradientPlane';
import { ImagePlane } from '../../../models/imagePlane';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { Text } from '../../../models/text';
import { Timer } from '../../../models/timer';
import { WeatherIconPlane } from '../../../models/weatherIcon';
import LayoutService from '../../../services/layout';
import { getBannerColor } from '../../../helpers/bannerColor';

// lausanne: 0
// geneva: 1
// fribourg: 2
// sion: 3
// neuchatel: 4
// delemont: 5

interface ISceneMeshes extends ISceneMeshesBase {
  balls: BallFlat[];
  foreground?: GradientPlane;
  lastDraw?: Text;
  logo?: ImagePlane;
  nextDraw?: Text;
  nextDrawTimer?: Timer;
  map?: ImagePlane;
  weatherForTime?: Text;
  icons: WeatherIconPlane[];
  temperatures: Text[];
  boost?: ImagePlane;
  boostValue?: BoostText;
}

export default class WeatherScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: WeatherStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {
    balls: [],
    icons: [],
    temperatures: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: WeatherStateScene,
    settings: ISettings,
  ) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: {
              balls = [],
              extra,
              boost,
              nextDrawTime,
              lastDrawText,
              nextDrawText,
              id,
              weatherText,
              weather: { forecastDateTime, locations } = {
                locations: {},
                forecastDateTime: undefined,
              },
              weatherMapImage,
              logoImage,
              boostImage,
              dayIconsImage,
              nightIconsImage,
            },
          },
        },
      } = this;

      const lausanne = parseWeather(locations.lausanne);
      const geneva = parseWeather(locations.geneva);
      const fribourg = parseWeather(locations.fribourg);
      const sion = parseWeather(locations.sion);
      const neuchatel = parseWeather(locations.neuchatel);
      const delemont = parseWeather(locations.delemont);
      const isFeatureFlag2024 = getIsFeatureFlag2024(
        extractBallsFeatureFlag(settings.featureFlags),
      );
      const bannerBallColor = getBannerColor(isFeatureFlag2024);

      scene.add((meshes.foreground = new GradientPlane()));
      scene.add((meshes.lastDraw = new Text()));
      scene.add((meshes.logo = new ImagePlane()));
      scene.add((meshes.map = new ImagePlane()));
      scene.add((meshes.nextDraw = new Text()));
      scene.add((meshes.nextDrawTimer = new Timer()));
      scene.add((meshes.weatherForTime = new Text()));
      scene.add((meshes.boost = new ImagePlane()));
      scene.add((meshes.boostValue = new BoostText()));

      scene.add(
        ...(meshes.icons = [
          new WeatherIconPlane(),
          new WeatherIconPlane(),
          new WeatherIconPlane(),
          new WeatherIconPlane(),
          new WeatherIconPlane(),
          new WeatherIconPlane(),
        ]),
      );

      scene.add(
        ...(meshes.temperatures = [
          new Text(),
          new Text(),
          new Text(),
          new Text(),
          new Text(),
          new Text(),
        ]),
      );

      const currentResults = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballLineLayout();

      // TODO: Needs adding into response
      const temperatureText: Texts = [
        {
          text: `[temperature]°`,
          font: FONTS.HelveticaRoundedBoldCondensed,
          size: 30,
          color: COLORS.black,
          // fillStyle: COLORS.black,
          // strokeStyle: COLORS.transparent,
          // strokeWidth: '0',
        },
      ];

      return await Promise.all([
        ...(forecastDateTime
          ? [
              meshes.weatherForTime.configure({
                text: templateTexts(weatherText, {
                  time: moment(forecastDateTime).format('HH[h]mm'),
                }),
                state: {
                  position: [-4.1, 0.55, -3.5],
                  scale: [0, 0.2, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
              }),
            ]
          : []),
        ...createArrayOfLength(20).map(async (n, i) => {
          if (currentResults[i] && !meshes.balls[i]) {
            scene.add((meshes.balls[i] = new BallFlat()));

            return await meshes.balls[i].configure({
              value: currentResults[i].value,
              state: {
                position: adjustArrayValues(
                  ballLayout.positions[currentResults[i].sortedIndex],
                  [0, -0.75, 0],
                ),
                rotation: [0, 0, 0],
                scale: ballLayout.scale,
                opacity: 0,
              },
              isExtraBall: currentResults[i].value === extra,
              dimensions: {
                height: 64,
                width: 64,
              },
              featureFlag: extractBallsFeatureFlag(settings.featureFlags),
            });
          } else {
            return await Promise.resolve();
          }
        }),
        meshes.map.configure({
          urls: [weatherMapImage],
          state: {
            position: [0, 0.3, -3.5],
            scale: [4, 4, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        ...(lausanne
          ? [
              meshes.temperatures[0].configure({
                text: templateTexts(temperatureText, {
                  temperature: lausanne.temperature,
                }),
                state: {
                  position: [-0.8, -0.075, -3.4],
                  scale: [0, 0.18, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
              }),
              meshes.icons[0].configure({
                iconIndex: lausanne.iconIndex,
                iconPack: lausanne.night ? nightIconsImage : dayIconsImage,
                state: {
                  position: [-1.225, -0.07, -3.4],
                  scale: [0.5, 0.5, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
              }),
            ]
          : []),
        ...(geneva
          ? [
              meshes.temperatures[1].configure({
                text: templateTexts(temperatureText, {
                  temperature: geneva.temperature,
                }),
                state: {
                  position: [-1.75, -0.8, -3.4],
                  scale: [0, 0.18, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
                textAlign: 'right',
              }),
              meshes.icons[1].configure({
                iconIndex: geneva.iconIndex,
                iconPack: geneva.night ? nightIconsImage : dayIconsImage,
                state: {
                  position: [-1.35, -0.785, -3.4],
                  scale: [0.5, 0.5, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
              }),
            ]
          : []),
        ...(fribourg
          ? [
              meshes.temperatures[2].configure({
                text: templateTexts(temperatureText, {
                  temperature: fribourg.temperature,
                }),
                state: {
                  position: [-0.15, 0.55, -3.4],
                  scale: [0, 0.18, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
                textAlign: 'right',
              }),
              meshes.icons[2].configure({
                iconIndex: fribourg.iconIndex,
                iconPack: fribourg.night ? nightIconsImage : dayIconsImage,
                state: {
                  position: [0.25, 0.575, -3.4],
                  scale: [0.5, 0.5, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
              }),
            ]
          : []),
        ...(sion
          ? [
              meshes.temperatures[3].configure({
                text: templateTexts(temperatureText, {
                  temperature: sion.temperature,
                }),
                state: {
                  position: [0.3, -0.75, -3.4],
                  scale: [0, 0.18, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
              }),
              meshes.icons[3].configure({
                iconIndex: sion.iconIndex,
                iconPack: sion.night ? nightIconsImage : dayIconsImage,
                state: {
                  position: [-0.1, -0.735, -3.4],
                  scale: [0.5, 0.5, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
              }),
            ]
          : []),
        ...(neuchatel
          ? [
              meshes.temperatures[4].configure({
                text: templateTexts(temperatureText, {
                  temperature: neuchatel.temperature,
                }),
                state: {
                  position: [-0.475, 0.95, -3.4],
                  scale: [0, 0.18, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
                textAlign: 'right',
              }),
              meshes.icons[4].configure({
                iconIndex: neuchatel.iconIndex,
                iconPack: neuchatel.night ? nightIconsImage : dayIconsImage,
                state: {
                  position: [-0.1, 0.975, -3.4],
                  scale: [0.5, 0.5, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
              }),
            ]
          : []),
        ...(delemont
          ? [
              meshes.temperatures[5].configure({
                text: templateTexts(temperatureText, {
                  temperature: delemont.temperature,
                }),
                state: {
                  position: [0.15, 1.775, -3.4],
                  scale: [0, 0.18, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
                textAlign: 'right',
              }),
              meshes.icons[5].configure({
                iconIndex: delemont.iconIndex,
                iconPack: delemont.night ? nightIconsImage : dayIconsImage,
                state: {
                  position: [0.55, 1.8, -3.4],
                  scale: [0.5, 0.5, 1],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
              }),
            ]
          : []),
        meshes.foreground.configure({
          stops: [
            [0, 'rgba(0, 0, 0, 0)'],
            [0.5, 'rgba(0, 0, 0, 0.3)'],
            [0.5, bannerBallColor],
            [1.0, bannerBallColor],
          ],
          dimensions: {
            height: 64,
            width: 8,
          },
          state: {
            position: [0, -1.85, -3.51],
            rotation: [0, 0, 0],
            scale: [8.92, 1.225, 1],
            opacity: 0,
          },
        }),
        meshes.lastDraw.configure({
          text: templateTexts(lastDrawText, { id }),
          state: {
            position: [-4.1, -1.6125, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.logo.configure({
          dimensions: { height: 256, width: 512 },
          urls: [logoImage],
          state: {
            position: [-3.25, 2, -3.5],
            scale: [2, 0.575, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.nextDraw.configure({
          text: nextDrawText,
          state: {
            position: [4.1, 2.2, -3.5],
            scale: [0, 0.2, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
        }),
        meshes.nextDrawTimer
          .configure({
            format: timeFormat,
            empty: timeEmpty,
            invalid: timeInvalid,
            state: {
              position: [4.1, 1.9, -3.5],
              scale: [0, 0.2, 1],
              rotation: [0, 0, 0],
              opacity: 0,
            },
            textAlign: 'right',
            // glyphWidth: 22 // NOTE: This can be enabled to force a non-jiggly timer
          })
          .then(
            async () =>
              await meshes.nextDrawTimer.setDate(new Date(nextDrawTime)),
          ),
        meshes.boost.configure({
          dimensions: { height: 128, width: 256 },
          urls: [boostImage],
          state: {
            position: [3.1, -1.55, -3.5],
            scale: [1, 0.33, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.boostValue.configure({
          dimensions: { height: 64, width: 128 },
          text: [
            {
              text: 'x',
              font: FONTS.AristaProBold,
              size: 50,
              color: COLORS.white,
              // fillStyle: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '2',
            },
            {
              text: `${boost}`,
              font: FONTS.AristaProBold,
              size: 64,
              color: COLORS.white,
              // fillStyle: COLORS.white,
              // strokeStyle: COLORS.brightRed,
              // strokeWidth: '2',
            },
          ],
          state: {
            position: [4.1, -1.575, -3.5],
            scale: [0, 0.264, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          textAlign: 'right',
          textBaseline: 'alphabetic',
        }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>((resolve, reject) => {
      const {
        _meshes: meshes,
        _state: {
          meta: {
            [this._language]: { balls = [] },
          },
        },
      } = this;

      const currentResults = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballLineLayout();

      Promise.all([
        ...Object.keys(meshes.icons).map(
          async (t) => await (meshes.icons[t] as Text).tweenTo({ opacity: 1 }),
        ),
        ...Object.keys(meshes.temperatures).map(
          async (t) =>
            await (meshes.temperatures[t] as Text).tweenTo({ opacity: 1 }),
        ),
        ...meshes.balls.map(
          async (mesh, i) =>
            await mesh.tweenTo(
              {
                position: ballLayout.positions[currentResults[i].sortedIndex],
                opacity: 1,
              },
              1000,
              currentResults[i].sortedIndex * 75,
              TWEEN.Easing.Quadratic.Out,
            ),
        ),
        meshes.foreground.tweenTo({ opacity: 1 }),
        meshes.lastDraw.tweenTo({ opacity: 1 }),
        meshes.logo.tweenTo({ opacity: 1 }),
        meshes.map.tweenTo({ opacity: 1 }),
        meshes.nextDraw.tweenTo({ opacity: 1 }),
        meshes.nextDrawTimer
          .beginAutoRedraw()
          .then(async () => await meshes.nextDrawTimer.tweenTo({ opacity: 1 })),
        meshes.weatherForTime.tweenTo({ opacity: 1 }),
        meshes.boostValue.tweenTo({ opacity: 1 }),
        meshes.boost.tweenTo({ opacity: 1 }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      return await Promise.all([
        ...Object.keys(meshes.icons).map(
          async (t) => await (meshes.icons[t] as Text).tweenOut({ opacity: 0 }),
        ),
        ...Object.keys(meshes.temperatures).map(
          async (t) =>
            await (meshes.temperatures[t] as Text).tweenOut({ opacity: 0 }),
        ),
        ...meshes.balls.map(
          async (ball) => await ball?.tweenOut({ opacity: 0 }),
        ),
        meshes.foreground.tweenOut({ opacity: 0 }),
        meshes.lastDraw.tweenOut({ opacity: 0 }),
        meshes.logo.tweenOut({ opacity: 0 }),
        meshes.map.tweenOut({ opacity: 0 }),
        meshes.nextDraw.tweenOut({ opacity: 0 }),
        meshes.nextDrawTimer.tweenOut({ opacity: 0 }),
        meshes.weatherForTime.tweenTo({ opacity: 0 }),
        meshes.boost.tweenOut({ opacity: 0 }),
        meshes.boostValue.tweenOut({ opacity: 0 }),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: WeatherStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
