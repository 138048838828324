import { NextDraw } from '../../../Common/NextDraw';
import { DrawInProgress } from '../../../Common/DrawInProgress';
import { IStateProps } from '../..';
import { formatNumber } from '../../../../helpers/formatters';
import { ShuffleText } from '../../../../helpers/textShuffle';
import {
  bangoHeaderDisplayStyle,
  bangoLineContainerStyle,
  BangoLineStyle,
  JackpotDisplayContainerStyle,
  spanIDStyle,
  spanJackpotStyle,
  spanLotoExpressStyle,
  spanPlusStyle,
  spanPotStyle,
  spanShuffleStyle,
} from './styles';
import { BangoItem, IBangoMeta } from '../../../../interfaces/sceneState';
import { getMeta } from '../../../../helpers/getMeta';
import { createScene } from '../../../../helpers/createScene';
import ResultBanner from '../../../Common/ResultBanner';
import { BoostSplash } from '../../../Common/BoostSplash';
import { MetaText } from '../../../Common/MetaText';
import { FONTS } from '../../../../enums/fonts';
import { getIsChannelTV } from '../../../../helpers/getIsChannelTV';

const BangoHTML = (props: IStateProps) => {
  const meta = getMeta(props) as IBangoMeta;
  const isTVChannel = getIsChannelTV(props);
  return (
    <>
      <DrawInProgress scene={props?.displayState} {...props} />
      <BoostSplash scene={props.displayState} {...props} />
      <NextDraw {...meta} />
      <JackpotDisplay meta={meta} isTVChannel={isTVChannel} />
      <ResultBanner
        ballValues={meta.balls}
        extraValue={meta.extra}
        {...props}
      />
    </>
  );
};

export const BangoScene = createScene(BangoHTML, null);

const JackpotDisplay = ({
  meta,
  isTVChannel,
}: {
  meta: IBangoMeta;
  isTVChannel: boolean;
}) => {
  return (
    <div style={JackpotDisplayContainerStyle}>
      <BangoHeader {...meta} />
      {meta?.bango?.map((jackpot: BangoItem) => (
        <BangoLine
          key={jackpot.id}
          jackpot={jackpot}
          isTVChannel={isTVChannel}
        />
      ))}
    </div>
  );
};

const BangoHeader = (meta: IBangoMeta) => {
  const { bangoFixedText, bangoJackpotText } = meta;

  return (
    <div style={bangoHeaderDisplayStyle}>
      <MetaText
        customStyle={spanPotStyle}
        customFont={FONTS.BarlowCondensedBold}
        content={bangoJackpotText?.[0]}
      />
      <MetaText
        customStyle={spanLotoExpressStyle}
        customFont={FONTS.BarlowCondensedBold}
        content={bangoFixedText?.[0]}
      />
    </div>
  );
};

const BangoLine = ({
  jackpot,
  isTVChannel,
}: {
  jackpot: BangoItem;
  isTVChannel: boolean;
}) => {
  const { id = 0, amount = 0, fixedShareAmount = 0 } = jackpot;
  return (
    <div style={bangoLineContainerStyle}>
      <div style={BangoLineStyle}>
        <span style={spanIDStyle}>
          {id}/{id}
        </span>
      </div>
      <span style={spanShuffleStyle}>
        <ShuffleText text={amount / 100} isTVChannel={isTVChannel} />
      </span>
      <span style={spanPlusStyle}>+</span>
      <span style={spanJackpotStyle}>
        {`${formatNumber(fixedShareAmount / 100, 0, '.', 3, '’')}.-`}
      </span>
    </div>
  );
};
