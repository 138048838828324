import { FONTS } from '../../../../enums/fonts';
import backgroundImage from './../../../../assets/images/backgrounds/background_responsible_gaming.svg';

export const backgroundContainer: React.CSSProperties = {
  position: 'absolute',
  backgroundImage: `url("${backgroundImage}")`,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 50,
};

export const textsContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontWeight: 'semibold',
};

export const logosContainer: React.CSSProperties = {
  position: 'relative',
  width: 300,
  height: 300,
};

export const logo: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: '100%',
};
