import * as THREE from 'three';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { Text } from '../../../models/text';
import { type ILoExScene } from '../../../interfaces/scene';
import { type ErrorStateScene } from '../../../interfaces/sceneState';

interface ISceneMeshes extends ISceneMeshesBase {
  messages: Text[];
}

export default class ErrorScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: ErrorStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {
    messages: [],
  };

  public setup = async (language: string, state: ErrorStateScene) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _state: {
          meta: {
            [language]: { textLines },
          },
        },
      } = this;

      return await Promise.all([
        Promise.all(
          textLines
            ?.map((x) => [x])
            .map(async (text, i, arr) => {
              meshes.messages[i] = new Text();

              return await meshes.messages[i]
                .configure({
                  state: {
                    position: [0, 0.4 * -i + arr.length * 0.2 - 0.4, -3.5],
                    scale: [0, 0.264, 1],
                    rotation: [0, 0, 0],
                    opacity: 0,
                  },
                  text,
                  textAlign: 'center',
                })
                .then(() => this._scene.add(meshes.messages[i]));
            }),
        ),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      return await Promise.all(
        meshes.messages.map(
          async (mesh, i) => await mesh.tweenTo({ opacity: 1 }, 1000, i * 125),
        ),
      )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy())
          .catch(reject);
      }

      Promise.all(
        meshes.messages.map(
          async (mesh) =>
            await mesh.tweenOut(
              {
                opacity: 0,
              },
              1000,
            ),
        ),
      )
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: ErrorStateScene) =>
    await new Promise<void>(async (resolve, reject) => {
      const oldText = [(this._state.meta[this._language] || {}).textLines]
        .map((a) => a.map((b) => b.text).join(''))
        .join('');
      const newText = [(state.meta[this._language] || {}).textLines]
        .map((a) => a.map((b) => b.text).join(''))
        .join('');

      if (newText !== oldText) {
        return await Promise.all(
          this._meshes.messages.map(
            async (mesh) =>
              await mesh
                .tweenOut(
                  {
                    opacity: 0,
                  },
                  1000,
                )
                .then(() => {
                  this._scene.remove(mesh);
                  mesh.destroy();
                }),
          ),
        )
          .then(async () => await this.setup(this._language, state))
          .then(async () => await this.play())
          .then(resolve)
          .catch(reject);
      } else {
        this._state = state;

        return resolve();
      }
    });
}
