import { EventEmitter } from 'events';

export default class PromiseService {
  private static _requests: Record<string, EventEmitter | undefined> = {};

  public static getAvoidingRace = async <T>(
    key: string,
    generator: () => Promise<T>,
  ) =>
    await new Promise<T>((resolve, reject) => {
      // Get the existing request for this key
      let request = PromiseService._requests[key];

      // If there is no existing request, start the generator
      if (!request) {
        request = PromiseService._requests[key] = new EventEmitter();
        request.setMaxListeners(0);

        generator()
          .then((result) => {
            request?.emit('result', result);
            PromiseService._requests[key] = undefined;
          })
          .catch((err) => {
            request?.emit('error', err);
            PromiseService._requests[key] = undefined;
          });
      }

      // Once we have a result, resolve the promise
      request.once('result', (result) => resolve(result));

      // If there's an error, reject it
      request.once('error', (err) => reject(err));
    });
}
