import lotoExpressLogo from '../../../assets/lotoExpress.svg';
import { getIsChannelTV } from '../../../helpers/getIsChannelTV';
import { IStateProps } from '../../App';
import { useRescale } from '../../App/hooks/useRescale';

const logoContainerStyle: React.CSSProperties = {
  position: 'absolute',
  left: '30px',
  top: '45px',
};
export const LotoExpressLogo = (props: IStateProps) => {
  const isChannelTV = getIsChannelTV(props);
  const scaleS = useRescale();

  if (!isChannelTV) {
    return null;
  }

  return (
    <div style={{ ...logoContainerStyle, ...scaleS }}>
      <img src={lotoExpressLogo} width={'250px'} alt="loto express logo" />
    </div>
  );
};
