import { useEffect, useRef } from 'react';
import { getRandom } from '../helpers/arrays';
import { formatNumber } from './formatters';
import { motion } from 'framer-motion';

const pool = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const duration = 1000;
const marginTime = 1.5;
const characterStopDelay = 100;

export const ShuffleText = ({
  text,
  isTVChannel,
}: {
  text: number;
  isTVChannel: boolean;
}) => {
  const frameInterval = isTVChannel ? -1 : 2;

  const textAsString = `${formatNumber(text, 0, '.', 3, '’')}.-`;
  const characters = Array.from(textAsString);
  const stopTimes = characters.map((_, index) => index * characterStopDelay);

  const shuffledTextRef = useRef('');
  const startTimeRef = useRef(0);
  const lastAniatedTimeRef = useRef(0);
  const animationRef = useRef(null);

  const spanRef = useRef(null);

  useEffect(() => {
    startTimeRef.current = Date.now();
    const animate = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTimeRef.current;
      const deltaTime = currentTime - lastAniatedTimeRef.current;
      if (deltaTime > 50) {
        const newText = characters
          .map((char, index) => {
            if (elapsedTime - duration < stopTimes[index]) {
              return getRandom(pool, 1)[0];
            } else {
              return char;
            }
          })
          .join('');

        shuffledTextRef.current = newText;
        if (spanRef.current) {
          spanRef.current.textContent = newText;
        }
        lastAniatedTimeRef.current = Date.now();
      }
      if (
        elapsedTime <
        duration * marginTime + stopTimes[stopTimes.length - 1]
      ) {
        animationRef.current = requestAnimationFrame(animate);
      } else {
        shuffledTextRef.current = textAsString;
        if (spanRef.current) {
          spanRef.current.textContent = textAsString;
        }
      }
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [text, characters, textAsString, stopTimes, frameInterval]);

  return (
    <motion.div
      ref={spanRef}
      initial={{ opacity: 1 }}
      animate={{ opacity: [1, 0, 1, 0, 1] }}
      transition={{
        delay: 3,
        duration: 4,
        times: [0, 0.25, 0.5, 0.75, 1],
      }}
    >
      {textAsString}
    </motion.div>
  );
};
