export enum SCENE_ACTIONS {
  SAVE_CURRENT_STATE = 'SAVE_CURRENT_STATE',
}

export enum LANGUAGE_ACTIONS {
  SET_LANGUAGE = 'SET_LANGUAGE',
}

export enum LOGGING_ACTIONS {
  SAVE_LOG_MESSAGES = 'SAVE_LOG_MESSAGSE',
  UPDATE_LOG_MESSAGES = 'UPDATE_LOG_MESSAGES',
}

export enum FEATURES_FLAG_ACTIONS{
  SAVE_FEATURE_FLAGS = 'SAVE_FEATURE_FLAGS'
}
