import * as THREE from 'three';
import { generateColorTexture } from '../helpers/canvas';
import { type IDimensions, type IState } from '../interfaces/scene';
import { Mesh } from './mesh';

export class ColorPlane extends Mesh {
  public configure = async ({
    dimensions: { height = 1, width = 1 } = { height: 1, width: 1 },
    color,
    state,
  }: {
    color: string;
    dimensions?: IDimensions;
    state: IState;
  }): Promise<void> =>
    await new Promise((resolve) => {
      this._previous = this._current = state;

      this.name = `ColorPlane_${color}`;

      this.geometry = new THREE.PlaneGeometry(1, 1);
      this.material = new THREE.MeshBasicMaterial({
        color: 'white',
        map: generateColorTexture(width, height, color),
        transparent: true,
      });

      this.updateMesh(state);

      return resolve();
    });
}
