import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { type ILoExScene } from '../interfaces/scene';
import { type SceneState } from '../interfaces/sceneState';
import { type Mesh } from './mesh';
import { ISettings } from '../interfaces/app';

export type ISceneMeshesBase = Record<string, Mesh | undefined | Mesh[]>;

interface ISceneMeshes extends ISceneMeshesBase {}

export class Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: SceneState<{}>;

  protected _meshes: ISceneMeshes = {};

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public destroy = async () =>
    await new Promise(async (resolve, reject) => {
      const {
        _meshes: meshes,
        _scene: { children },
      } = this;

      TWEEN.getAll().map((t) => TWEEN.remove(t.stop()));

      children.forEach((c: Mesh) => this._scene.remove(c));

      return await Promise.all(
        Object.keys(meshes)
          .reduce<Mesh[]>(
            (out, curr) => [
              ...out,
              ...((Array.isArray(meshes[curr])
                ? meshes[curr]
                : [meshes[curr]]) as Mesh[]),
            ],
            [],
          )
          .map(async (mesh) => await mesh.destroy()),
      )
        // .then(() => this._scene.dispose())
        .then(() => resolve(undefined)) // TODO CHEF void promise ?
        .catch(reject);
    });

  public setup = async (
    language: string,
    state: SceneState<{}>,
    settings: ISettings,
  ) =>
    await new Promise<void>((resolve) => {
      resolve();
    });

  public play = async () =>
    await new Promise<void>((resolve) => {
      resolve();
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      return await Promise.resolve()
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: SceneState<{}>) =>
    await new Promise<void>((resolve) => {
      resolve();
    });
}
