import { type setLanguage } from '../actions/language';
import { LANGUAGE_ACTIONS } from '../enums/actions';
import { type IAppState } from '../interfaces/app';
import { type ActionOutput } from '../types';

const setLang = { [LANGUAGE_ACTIONS.SET_LANGUAGE]: setLanguageReducer };
export default setLang;

export function setLanguageReducer(
  state: IAppState,
  { language }: ActionOutput<typeof setLanguage>,
): IAppState {
  return {
    ...state,
    language: language.toLowerCase(),
  };
}
