import { FONTS } from '../enums/fonts';
import AristaProRegularFont from './../assets/fonts/AristaPro/AristaPro-Regular.ttf';
import AristaProBoldFont from './../assets/fonts/AristaPro/AristaPro-Bold.ttf';
import GothamRoundedLightFont from './../assets/fonts/GothamRounded/GothamRounded-Light.otf';
import GothamRoundedMediumFont from './../assets/fonts/GothamRounded/GothamRounded-Medium.otf';
import GothamRoundedBoldFont from './../assets/fonts/GothamRounded/GothamRounded-Bold.otf';
import HelveticaRoundedBoldCondensedFont from './../assets/fonts/Helvetica/HelveticaRoundedLTStd-BoldCondensed.otf';
import HelveticaNeueLTStdBdItFont from './../assets/fonts/NeueHelvetica/HelveticaNeueLTStd-BdIt.otf';
import FuturaBookItalicFont from './../assets/fonts/Futura/Futura-BookItalic.ttf';
import BarlowCondensedFont from './../assets/fonts/BarlowCondensed/BarlowCondensed-Regular.ttf';
import BarlowCondensedBoldFont from './../assets/fonts/BarlowCondensed/BarlowCondensed-Bold.ttf';
import BarlowCondensedItalicFont from './../assets/fonts/BarlowCondensed/BarlowCondensed-Italic.ttf';
import BarlowCondensedBoldItalicFont from './../assets/fonts/BarlowCondensed/BarlowCondensed-BoldItalic.ttf';
import NotoSansFont from './../assets/fonts/NotoSans/NotoSans-Regular.ttf';
import NotoSansBoldFont from './../assets/fonts/NotoSans/NotoSans-Bold.ttf';
import NotoSansItalicFont from './../assets/fonts/NotoSans/NotoSans-Italic.ttf';
import NotoSansBoldItalicFont from './../assets/fonts/NotoSans/NotoSans-BoldItalic.ttf';
import NunitoFont from './../assets/fonts/Nunito/Nunito-Regular.ttf';
import NunitoBoldFont from './../assets/fonts/Nunito/Nunito-Bold.ttf';
import NunitoExtraBoldFont from './../assets/fonts/Nunito/Nunito-ExtraBold.ttf';
import NunitoItalicFont from './../assets/fonts/Nunito/Nunito-Italic.ttf';
import NunitoBoldItalicFont from './../assets/fonts/Nunito/Nunito-BoldItalic.ttf';
import NunitoExtraBoldItalicFont from './../assets/fonts/Nunito/Nunito-ExtraBoldItalic.ttf';
import BarlowCondensedSemiBoldFont from './../assets/fonts/BarlowCondensed/BarlowCondensed-SemiBold.ttf';

export const AristaProRegular = {
  fontFamily: FONTS.AristaProRegular,
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url("${AristaProRegularFont}") format("truetype")`,
};

export const AristaProBold = {
  fontFamily: FONTS.AristaProBold,
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url("${AristaProBoldFont}") format("truetype")`,
};

export const GothamRoundedLight = {
  fontFamily: FONTS.GothamRoundedLight,
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url("${GothamRoundedLightFont}") format("opentype")`,
};

export const GothamRoundedMedium = {
  fontFamily: FONTS.GothamRoundedMedium,
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url("${GothamRoundedMediumFont}") format("opentype")`,
};

export const GothamRoundedBold = {
  fontFamily: FONTS.GothamRoundedBold,
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url("${GothamRoundedBoldFont}")  format("opentype")`,
};

export const HelveticaRoundedBoldCondensed = {
  fontFamily: FONTS.HelveticaRoundedBoldCondensed,
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url("${HelveticaRoundedBoldCondensedFont}")  format("opentype")`,
};

export const HelveticaNeueLTStdBdIt = {
  fontFamily: FONTS.HelveticaNeueLTStdBdIt,
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url("${HelveticaNeueLTStdBdItFont}")  format("opentype")`,
};

export const HelveticaNeue = {
  fontFamily: FONTS.HelveticaNeue,
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url("${HelveticaNeueLTStdBdItFont}")  format("opentype")`,
};

export const FuturaBookItalic = {
  fontFamily: FONTS.FuturaBookItalic,
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url("${FuturaBookItalicFont}")  format("truetype")`,
};

export const BarlowCondensed = {
  fontFamily: FONTS.BarlowCondensed,
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url("${BarlowCondensedFont}") format("truetype")`,
};

export const BarlowCondensedBold = {
  fontFamily: FONTS.BarlowCondensedBold,
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url("${BarlowCondensedBoldFont}") format("truetype")`,
};

export const BarlowCondensedSemiBold = {
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontStyle: 'normal',
  fontWeight: 'semibold',
  src: `url("${BarlowCondensedSemiBoldFont}") format("truetype")`,
};

export const BarlowCondensedItalic = {
  fontFamily: FONTS.BarlowCondensedItalic,
  fontStyle: 'italic',
  fontWeight: 'normal',
  src: `url("${BarlowCondensedItalicFont}") format("truetype")`,
};

export const BarlowCondensedBoldItalic = {
  fontFamily: FONTS.BarlowCondensedBoldItalic,
  fontStyle: 'italic',
  fontWeight: 'bold',
  src: `url("${BarlowCondensedBoldItalicFont}") format("truetype")`,
};

export const NotoSans = {
  fontFamily: FONTS.NotoSans,
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url("${NotoSansFont}") format("truetype")`,
};

export const NotoSansBold = {
  fontFamily: FONTS.NotoSansBold,
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url("${NotoSansBoldFont}") format("truetype")`,
};

export const NotoSansItalic = {
  fontFamily: FONTS.NotoSansItalic,
  fontStyle: 'italic',
  fontWeight: 'normal',
  src: `url("${NotoSansItalicFont}") format("truetype")`,
};

export const NotoSansBoldItalic = {
  fontFamily: FONTS.NotoSansBoldItalic,
  fontStyle: 'italic',
  fontWeight: 'bold',
  src: `url("${NotoSansBoldItalicFont}") format("truetype")`,
};

export const Nunito = {
  fontFamily: FONTS.Nunito,
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url("${NunitoFont}") format("truetype")`,
};

export const NunitoBold = {
  fontFamily: FONTS.NunitoBold,
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url("${NunitoBoldFont}") format("truetype")`,
};

export const NunitoExtraBold = {
  fontFamily: FONTS.NunitoExtraBold,
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `url("${NunitoExtraBoldFont}") format("truetype")`,
};

export const NunitoItalic = {
  fontFamily: FONTS.NunitoItalic,
  fontStyle: 'italic',
  fontWeight: 'normal',
  src: `url("${NunitoItalicFont}") format("truetype")`,
};

export const NunitoBoldItalic = {
  fontFamily: FONTS.NunitoBoldItalic,
  fontStyle: 'italic',
  fontWeight: 'bold',
  src: `url("${NunitoBoldItalicFont}") format("truetype")`,
};

export const NunitoExtraBoldItalic = {
  fontFamily: FONTS.NunitoExtraBoldItalic,
  fontStyle: 'italic',
  fontWeight: 'bold',
  src: `url("${NunitoExtraBoldItalicFont}") format("truetype")`,
};
