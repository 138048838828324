import { generateRandomNumber } from './random';

export enum SortDirection {
  Ascending = 'ascending',
  Descending = 'descending',
}

export interface ISortable<T = any> {
  value: T;
  originalIndex: number;
  sortedIndex: number;
}

/**
 * Group an array using the specified function
 *
 * @param arr - The array of objects to group
 * @param comparator - The function to use for grouping
 */
export function group<T = any>(
  arr: T[],
  comparator: (item: T) => any,
): Record<string, T[]> {
  if (!arr || arr.length === 0) return {} as Record<string, T[]>;

  const map = {};

  arr
    .map((e) => ({ k: comparator(e), d: e }))
    .forEach((e) => {
      map[e.k] = map[e.k] || [];
      map[e.k].push(e.d);
    });

  return map;
}

/**
 * Group an array by the specified key
 *
 * @param arr - The array of objects to group
 * @param by - The key of the object to group by
 */
export function groupBy<T = any>(arr: T[], by: keyof T): Record<string, T[]> {
  if (!arr || arr.length === 0) return {} as Record<string, T[]>;

  return group(arr, (item: T) => item[by]);
}

/**
 * Copy an array and sort it using the specified comparator
 *
 * @param arr - The array to sort
 * @param comparator - The comparator to use
 */
export function sort<T = any>(
  arr: T[],
  comparator: (leftItem: T, rightItem: T) => number,
): T[] {
  if (!arr || arr.length === 0) return [] as T[];

  return arr.concat().sort(comparator);
}

/**
 * Copy an array and sort it by the specified key
 *
 * @param arr - The array to sort
 * @param by - The key of the object to sort by
 * @param direction - The sorting direction
 */
export function sortBy<T = any>(
  arr: T[],
  by?: keyof T,
  direction: SortDirection = SortDirection.Ascending,
): T[] {
  if (!arr || arr.length === 0) return [] as T[];

  return arr.concat().sort((left, right) => {
    if (by ? left[by] < right[by] : left < right) {
      return direction === SortDirection.Ascending ? -1 : 1;
    } else if (by ? left[by] > right[by] : left > right) {
      return direction === SortDirection.Ascending ? 1 : -1;
    } else {
      return 0;
    }
  });
}

/**
 * Create an array of the specified length
 *
 * @param length - Length of the array to create
 */
export function createArrayOfLength(length: number): undefined[] {
  if (!length) return [] as undefined[];

  return Array.apply(null, Array(length));
}

/**
 * Create an array of the specified length and fill it with the specified object
 *
 * @param length - Length of the array to create
 * @param fillWith - The item to fill the array with
 */
export function fillArrayOfLength<T = any>(length: number, fillWith: T): T[] {
  if (!length) return [] as T[];

  return createArrayOfLength(length).map((x) => fillWith);
}

/**
 * Split an array into chunks of the specified size
 *
 * @param array - The array to split
 * @param chunkSize - The size of each chunk
 */
export function splitArray<T = any>(array: T[], chunkSize: number): T[][] {
  if (!array || array.length === 0) return [] as T[][];

  return array.reduce(
    (acc, curr, i, self) =>
      !(i % chunkSize) ? [...acc, self.slice(i, i + chunkSize)] : acc,
    [],
  );
}

/**
 * Filter out non-unique items
 *
 * @param array - The array to process
 */
export function unique<T = any>(array: T[]): T[] {
  if (!array || array.length === 0) return [] as T[];

  return Array.from(new Set(array));
}

/**
 * Filter out non-unique items based on key of occupant
 *
 * @param array - The array to process
 * @param key - The key to use to determine uniqueness
 */
export function uniqueBy<T>(array: T[], key: keyof T): T[] {
  if (!array || array.length === 0) return [] as T[];

  return array.filter((item, i, arr) => {
    return arr.map((_item) => _item[key]).indexOf(item[key]) === i;
  });
}

/**
 * Take all the elements in the specified array and flatten them into a single array
 *
 * @param {Array<Array<T>>} array - The array to flatten
 */
export function shallowFlatten<T = any>(array: T[][]): T[] {
  if (!array || array.length === 0) return [] as T[];

  return array.reduce((prev, curr) => [...prev, ...curr], []);
}

/**
 * Take all the elements in an array of `n` levels in depth and flatten the output into a single
 * depth array.
 *
 * @param array - The array to flatten
 */
export function deepFlatten<T = any>(array: any): T[] {
  if (!array || array.length === 0) return [] as T[];

  return (
    array?.reduce((flat: string | any[], toFlatten: any) => {
      return flat.concat(
        Array.isArray(toFlatten) ? deepFlatten(toFlatten) : toFlatten,
      );
    }, []) ?? []
  );
}

/**
 * Convert an array into an associative array
 *
 * @param array - The array to convert
 * @param key - The key in the object to use
 */
export function toAssociativeArray<T = any>(
  array: T[],
  key: keyof T,
): Record<string, T> {
  return (
    array?.reduce((out, item) => ({ ...out, [item[key] as any]: item }), {}) ??
    {}
  );
}

/**
 * Take an array and split it into a number of tracks.
 *
 * [A, B, C, D, E, F] when passed with 3 tracks, will be turned into [[A, D], [B, E], [C, F]]
 *
 * @param arr - The array to unzip
 * @param tracks - The number of arrays to unzip the initial array into
 */
export function unzip<T = any>(arr: T[], tracks: number = 2): T[][] {
  if (!arr || arr.length === 0) return [] as T[][];

  return arr.reduce(
    (out: T[][], curr, index) => {
      out[index % tracks].push(curr);

      return out;
    },
    Array.apply(null, Array(tracks)).map((x) => []),
  );
}

/**
 * Take a random number of items from the specified array
 *
 * @param arr - The array to take elements from
 * @param count - The number of elements to take
 */
export function takeRandom<T = any>(arr: T[], count: number): T[] {
  if (!arr || arr.length === 0) return [] as T[];

  const clonedArray = [...arr];

  return createArrayOfLength(count).map(
    () =>
      clonedArray.splice(generateRandomNumber(0, clonedArray.length - 1), 1)[0],
  );
}

/**
 * Select a random number of items from the specified array
 *
 * @param arr - The array to take elements from
 * @param count - The number of elements to take
 */
export function getRandom<T = any>(arr: T[], count: number): T[] {
  if (!arr || arr.length === 0) return [] as T[];

  const clonedArray = [...arr];

  return createArrayOfLength(count).map(() => {
    const random = generateRandomNumber(0, clonedArray.length - 1);

    return clonedArray.slice(random, random + 1)[0];
  });
}

export function getSortData<T = any>(
  arr: T[],
  func: (leftItem: T, rightItem: T) => number,
): Array<ISortable<T>> {
  if (!arr || arr.length === 0) return [];

  const sortedArray = [...arr].sort(func);
  return arr.map((value, i) => ({
    value,
    originalIndex: i,
    sortedIndex: sortedArray.indexOf(value),
  }));
}

export function adjustArrayValues<T extends number[]>(
  arr: T,
  adjustments: T,
): T {
  if (!arr || arr.length === 0) return [] as T;
  return arr.map((v, i) => v + (adjustments[i] || 0)) as T;
}

export function multiplyArrayValues<T extends number[]>(
  arr: T,
  adjustments: T,
): T {
  if (!arr || arr.length === 0) return [] as T;
  return arr.map((v, i) => v * (adjustments[i] || 0)) as T;
}

export const fromAssociativeArray = <T>(object: Record<string, T>): T[] =>
  Object.keys(object).map((key) => object[key]);
