import React, { useRef, useMemo, useEffect } from 'react';
import { ballNumberToGridPosition } from './helpers/ballNumberToGridPosition';
import { NumberText } from './NumberText';
import { motion } from 'framer-motion-3d';
import { getSharedMaterials } from './getSharedMaterials';
import * as THREE from 'three';

const BALL_COUNT = 80;

export const BallGrid = () => {
  const meshRef = useRef<THREE.InstancedMesh>(null!);
  const geometry = useMemo(() => new THREE.PlaneGeometry(0.79, 0.79), []);
  const matrix = useMemo(() => new THREE.Matrix4(), []);
  const { instancedGridBillboardMaterial } = getSharedMaterials();

  const balls = useMemo(
    () =>
      Array.from({ length: BALL_COUNT }, (_, index) => ({
        position: ballNumberToGridPosition(index + 1),
        number: index + 1,
      })),
    [],
  );

  useEffect(() => {
    balls.forEach((ball, index) => {
      matrix.setPosition(ball.position[0], ball.position[1], ball.position[2]);
      meshRef.current.setMatrixAt(index, matrix);
    });

    meshRef.current.instanceMatrix.needsUpdate = true;
  }, [balls, matrix]);

  return (
    <>
      <motion.group
        initial={{ scale: 0, z: -35 }}
        animate={{ scale: 1, z: 0 }}
        transition={{
          type: 'spring',
          stiffness: 80,
          damping: 15,
        }}
      >
        <instancedMesh
          ref={meshRef}
          args={[geometry, instancedGridBillboardMaterial, BALL_COUNT]}
          matrixAutoUpdate={false}
        />
        {balls.map((ball) => (
          <NumberText
            key={ball.number}
            position={ball.position}
            number={ball.number}
            transition={true}
          />
        ))}
      </motion.group>
    </>
  );
};

export default React.memo(BallGrid);
