import * as THREE from 'three';
import { Text } from '../../../models/text';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { ImagePlane } from '../../../models/imagePlane';
import { type ILoExScene } from '../../../interfaces/scene';
import { type NightModeStateScene } from '../../../interfaces/sceneState';

interface ISceneMeshes extends ISceneMeshesBase {
  logo?: ImagePlane;
  stars?: ImagePlane;
  messages: Text[];
}

export default class NightModeScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: NightModeStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {
    messages: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (language: string, state: NightModeStateScene) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _state: {
          meta: {
            [language]: { logoImage, textLines, backgroundImages },
          },
        },
      } = this;

      meshes.messages.length = 0;

      this._scene.add((meshes.logo = new ImagePlane()));
      this._scene.add((meshes.stars = new ImagePlane()));

      return await Promise.all([
        Promise.all(
          textLines
            ?.map((x) => [x])
            .map(async (text, i, arr) => {
              meshes.messages[i] = new Text();

              return await meshes.messages[i]
                .configure({
                  state: {
                    position: [0, 0.4 * -i + arr.length * 0.2 - 0.4, -3.5],
                    scale: [0, 0.264, 1],
                    rotation: [0, 0, 0],
                    opacity: 0,
                  },
                  text,
                  textAlign: 'center',
                })
                .then(() => this._scene.add(meshes.messages[i]));
            }),
        ),
        meshes.logo.configure({
          dimensions: { height: 128, width: 256 },
          urls: [logoImage],
          state: {
            position: [0, 1.75, -3.5],
            scale: [2.25, 0.625, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.stars.configure({
          urls: backgroundImages,
          state: {
            position: [0, 0, -3.6],
            rotation: [0, 0, 0],
            scale: [8.92, 5.0175, 1],
            opacity: 0,
          },
        }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      return await Promise.all([
        meshes.logo.tweenTo({ opacity: 1 }),
        meshes.stars.tweenTo({ opacity: 1 }),
      ])
        .then(
          async () =>
            await Promise.all(
              meshes.messages.map(
                async (mesh, i) =>
                  await mesh.tweenTo({ opacity: 1 }, 1000, i * 125),
              ),
            ),
        )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy());
      }

      return await Promise.all([
        Promise.all(
          meshes.messages.map(
            async (mesh) =>
              await mesh.tweenOut(
                {
                  opacity: 0,
                },
                1000,
              ),
          ),
        ),
        meshes.logo.tweenOut({ opacity: 0 }),
        meshes.stars.tweenOut({ opacity: 0 }),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: NightModeStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
