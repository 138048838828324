import * as THREE from 'three';
import { ImagePlane } from '../../../models/imagePlane';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { Text } from '../../../models/text';
import { type ILoExScene } from '../../../interfaces/scene';
import { type AttentionStateScene } from '../../../interfaces/sceneState';

interface ISceneMeshes extends ISceneMeshesBase {
  topText?: Text;
  bottomText?: Text;
  logo?: ImagePlane;
}

export default class AttentionScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: AttentionStateScene;

  protected _meshes: ISceneMeshes = {};

  public setup = async (language: string, state: AttentionStateScene) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: { topText, bottomText, logoImage },
          },
        },
      } = this;

      scene.add((meshes.topText = new Text()));
      scene.add((meshes.bottomText = new Text()));
      scene.add((meshes.logo = new ImagePlane()));

      return await Promise.all([
        meshes.topText.configure({
          state: {
            position: [-10, 0.25, -3.5],
            scale: [0, 0.525, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          text: topText,
          textAlign: 'center',
        }),
        meshes.bottomText.configure({
          state: {
            position: [10, -0.25, -3.5],
            scale: [0, 0.525, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          text: bottomText,
          textAlign: 'center',
        }),
        meshes.logo.configure({
          dimensions: { height: 256, width: 512 },
          urls: [logoImage],
          state: {
            position: [-3.25, 2, -3.5],
            scale: [2, 0.575, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      return await Promise.all([
        meshes.topText.tweenTo(
          {
            position: [0, 0.25, -3.5],
            opacity: 1,
          },
          1500,
        ),
        meshes.bottomText.tweenTo(
          {
            position: [0, -0.25, -3.5],
            opacity: 1,
          },
          1500,
        ),
        meshes.logo.tweenTo({ opacity: 1 }, 1000),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy());
      }

      Promise.all([
        meshes.topText.tweenOut({ opacity: 0 }, 250),
        meshes.bottomText.tweenOut({ opacity: 0 }, 250),
        meshes.logo.tweenOut({ opacity: 0 }, 250),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: AttentionStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
