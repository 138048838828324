import * as THREE from 'three';
import { Text } from '../../../models/text';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { COLORS } from '../../../constants/colours';
import { type ILoExScene } from '../../../interfaces/scene';
import { ColorPlane } from '../../../models/colorPlane';
import { ImagePlane } from '../../../models/imagePlane';
import { type ResponsibleStateScene } from '../../../interfaces/sceneState';

interface ISceneMeshes extends ISceneMeshesBase {
  cover?: ColorPlane;
  gamblingLogo?: ImagePlane;
  gamblingLogoCircle?: ImagePlane;
  messages: Text[];
}

export default class ResponsibleScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: ResponsibleStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {
    messages: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (language: string, state: ResponsibleStateScene) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _state: {
          meta: {
            [language]: {
              textLines,
              responsibleLogoImages,
              responsibleCircleImage,
            },
          },
        },
      } = this;

      this._scene.add((meshes.cover = new ColorPlane()));
      this._scene.add((meshes.gamblingLogoCircle = new ImagePlane()));
      this._scene.add((meshes.gamblingLogo = new ImagePlane()));

      meshes.messages.length = 0;

      return await Promise.all([
        ...textLines
          .map((x) => [x])
          .map(async (text, i, arr) => {
            meshes.messages[i] = new Text();

            return await meshes.messages[i]
              .configure({
                state: {
                  position: [8, 0.4 * -i + arr.length * 0.2 - 1.625, -3.5],
                  scale: [0, 0.4, 0.75],
                  rotation: [0, 0, 0],
                  opacity: 0,
                },
                text,
                textAlign: 'center',
              })
              .then(() => this._scene.add(meshes.messages[i]));
          }),
        meshes.cover.configure({
          state: {
            position: [0, 0, -10],
            scale: [28, 15, 1],
            rotation: [0, 0, 0],
            opacity: 0,
          },
          color: COLORS.white,
        }),
        meshes.gamblingLogoCircle.configure({
          urls: [responsibleCircleImage],
          state: {
            position: [0, 0.8, -3.5],
            scale: [2.5, 2.5, 0.001],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
        meshes.gamblingLogo.configure({
          urls: responsibleLogoImages,
          state: {
            position: [0, 0.8, -3.5],
            scale: [2.5, 2.5, 0.001],
            rotation: [0, 0, 0],
            opacity: 0,
          },
        }),
      ])
        .then(() => {
          // NOTE: These are necessary to prevent Z Fighting between objects
          (meshes.gamblingLogo.material as THREE.Material).depthWrite = false;
          (meshes.gamblingLogoCircle.material as THREE.Material).depthWrite =
            false;
        })
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      return await meshes.cover
        .tweenTo({ opacity: 1 }, 333)
        .then(
          async () =>
            await Promise.all([
              meshes.gamblingLogo.tweenTo({ opacity: 1 }, 333),
              meshes.gamblingLogoCircle.tweenTo({ opacity: 1 }, 333),
            ]),
        )
        .then(
          async () =>
            await Promise.all([
              meshes.gamblingLogo
                .tweenTo({ position: [0, 0.8, -4] }, 250)
                .then(
                  async () =>
                    await meshes.gamblingLogo.tweenTo(
                      { position: [0, 0.8, -3.5] },
                      250,
                      250,
                    ),
                )
                .then(
                  async () =>
                    await meshes.gamblingLogo.tweenTo(
                      { rotation: [0, Math.PI, 0] },
                      500,
                    ),
                ),
              meshes.gamblingLogoCircle.tweenTo(
                { rotation: [0, Math.PI, 0] },
                500,
                250,
              ),
              ...meshes.messages.map(
                async (mesh, i, arr) =>
                  await mesh.tweenTo(
                    {
                      position: [0, 0.4 * -i + arr.length * 0.2 - 1.625, -3.5],
                      opacity: 1,
                    },
                    1000,
                    i * 125,
                  ),
              ),
            ]),
        )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy())
          .catch(reject);
      }

      Promise.all<any>([
        ...meshes.messages.map(
          async (message) => await message.tweenOut({ opacity: 0 }, 500),
        ),
        meshes.gamblingLogo.tweenOut({ opacity: 0 }, 500),
        meshes.gamblingLogoCircle.tweenOut({ opacity: 0 }, 500),
        meshes.cover.tweenOut({ opacity: 0 }, 750),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: ResponsibleStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      return resolve();
    });
}
