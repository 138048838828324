import equal from 'fast-deep-equal';
import * as React from 'react';

export default class ComparingComponent<
  P extends {} = {},
  S extends {} = {},
  SS extends {} = {},
> extends React.Component<P, S, SS> {
  public shouldComponentUpdate(nextProps: P, nextState: S): boolean {
    return !equal(
      {
        props: this.props,
        state: this.state,
      },
      {
        props: nextProps,
        state: nextState,
      },
    );
  }

  protected componentPropsChanged(prevProps: P): boolean {
    return !equal(this.props, prevProps);
  }
}
